import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import EffectMeta from '../../components/effects/EffectMeta';
import Location from "../../hooks/Location"
import { useFirestore, useFirestoreGeneralArrayContains, useFirestoreID } from '../../firebase/useFirestore';
import { useEffect, useState, useContext } from 'react';
import plusButton from '../../images/icons/plus-icon.png'
import Tooltip from '../../components/common/Tooltip';
import deleteIcon from '../../images/icons/delete-icon.png'
import useSettings from "../../hooks/Settings";
import { db } from '../../firebase/config';
import { timestamp } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import DraggableTableItems from '../../components/common/DraggableTableItems';
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import questionMarkIcon from "../../images/icons/questionmark-icon.png"
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import firebase from 'firebase'
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from "../../StateManagment/Auth";
import { IndicatorsSystemMessage, IndicatorsToolCallContent,IndicatorsSaveMessage } from "../../hooks/impactAI/Prompts"

const AddIndicators = () => {
    // Context
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // States
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [showAllOutputs, setShowAllOutputs] = useState(false)
    const [showAddContainer, setShowAddContainer] = useState(false)
    const [effectTitle, setEffectTitle] = useState('')
    const [effectDocid, setEffectDocid] = useState('')

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    
    // Hooks
    const effectId = Location()[3]
    const history = useHistory()

    // Firestore
    const fields = useFirestoreGeneralArrayContains('QuestionnaireFields', 'EffectId', effectId ? effectId : '');
    const outputs = useFirestoreGeneralArrayContains('Outputs', 'EffectId', effectId ? effectId : '');
    const allOutputs = useFirestore('Outputs');
    const effects = useFirestoreID('OutputEffects', effectId ? effectId : '')

    //ImpactAI
    const pageSystemMessage = IndicatorsSystemMessage(effectTitle)
    const pageToolCallContent = IndicatorsToolCallContent()
    const pageSaveMessage = IndicatorsSaveMessage()
    const pageStartMessage = `Welkom bij de indicatoren, ${auth?.ForName}. 
    Hier gaan we indicatoren toevoegen ter onderbouwing van het effect: ${effectTitle}.
    Mag ik een voorstel doen?`
    const pageCollection = 'QuestionnaireFields'
    const pageField = 'EffectId'
    const pageParentId = effectId
    const pageType = 'indicator'
    const pageDocid = effectDocid

   useEffect(() => {
       setType(pageType)
       setSystemMessage(pageSystemMessage)
       setStartMessage(pageStartMessage)
       setToolCallContent(pageToolCallContent)
       setSaveMessage(pageSaveMessage)
       setCollection(pageCollection)
       setField(pageField)
       setParentId(pageParentId)
       setExampleMessages([])
       setDocid(pageDocid)
   }, [auth])

   // Get title and docid of effect
   useEffect(() => {
        effects && effects.map(effect => {
            setEffectTitle(effect.Effect)
            setEffectDocid(effect.docid)
        })
    }, [effects])

    // Add field to database
    const addQuestion= (e) => {

        db.collection('QuestionnaireFields')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Timestamp: timestamp,
            QuestionnaireID: '',
            Type: 'scale',
            Question: 'Vraag',
            Explainer: '',
            ReachStart: 0,
            ReachStartLable: '',
            ReachEnd: 5,
            ReachEndLabel: '',
            Multiple: [],
            SectionID: '',
            EffectId:  firebase.firestore.FieldValue.arrayUnion(effectId),
            Indicator: 'true'
        })
    }

    const titleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            Question: e.target.value
        })
    }

    const deleteQuestion = (e) => {

        const docid = e.target.dataset.docid

        // Remove effectId from field
        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            EffectId: firebase.firestore.FieldValue.arrayRemove(effectId)
        })
        // If the field has no more effects, set indicator to false
        .then(() => {
            db.collection('QuestionnaireFields')
            .doc(docid)
            .get()
            .then(doc => {
                if(doc.data().EffectId.length === 0) {
                    db.collection('QuestionnaireFields')
                    .doc(docid)
                    .update({
                        Indicator: 'false'
                    })
                }
            })
        })
    }

    const outputHandler = (e) => {

        const docid = e.target.options[e.target.selectedIndex].dataset.docid

        db.collection('Outputs')
        .doc(docid)
        .update({
            EffectId: firebase.firestore.FieldValue.arrayUnion(effectId),
            Type: 'output'
        })
        .then(() => {
            setShowAllOutputs(false)
        })
    }

    const deleteOutput = (e) => {
            
        const docid = e.target.dataset.docid

        db.collection('Outputs')
        .doc(docid)
        .update({
            EffectId: firebase.firestore.FieldValue.arrayRemove(effectId)
        })
    }


  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <HighlightAltOutlinedIcon/>
                <h1>Indicatoren toevoegen</h1>
                <EffectMeta item={effectId}/>
            </div>
            <div className="table-container dashboard-container">
                <div className='activity-meta-title-container'>
                    <img src={questionMarkIcon} alt="" />
                    <h2>Vragen</h2>
                </div>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>VRAAG</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {fields && fields.map((field, index) => (
                            <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={field}
                            input={
                                <Tooltip content={field.Question} width='100%' top='-40px'>
                                    <input placeholder='Schrijf hier de vraag' defaultValue={field.Question} data-docid={field.docid} onChange={titleHandler}/>
                                </Tooltip>
                                }
                            parentIndex={index}
                            collection={'Indicators'}
                            itemArray={fields}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={field.Question}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                            <td>
                                <Tooltip content={'Indicator verwijderen'} width='80%' top='-60px'>
                                    <img className='table-delete-icon' data-docid={field.docid} src={deleteIcon} alt="eye icon" onClick={deleteQuestion} />
                                </Tooltip>
                            </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
                <div className='add-row-container'>
                    <Tooltip content={'Vraag toevoegen'} width='80%' top='-40px'>
                        <img src={plusButton} alt="" onClick={() => setShowAddContainer(!showAddContainer)} />
                    </Tooltip>
                    <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
                        <div className="add-options-container-item-container" onClick={addQuestion}>
                            <img src={plusButton} alt="" />
                            <p>Nieuwe vraag toevoegen</p>
                        </div>
                        <div className="add-options-container-item-container" onClick={() => history.push(`/${client}/SelectKPIs/${effectId}`)}>
                            <img src={plusButton} alt="" />
                            <p>Bestaande vragen toevoegen</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-container dashboard-container">
                <div className='activity-meta-title-container'> 
                    <OutputOutlinedIcon/>
                    <h2>Outputs</h2>
                </div>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {outputs && outputs.map((output, index) => (
                            <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={output}
                            input={
                                <Tooltip content={output.Title} width='100%' top='-40px'>
                                    <p id='table-tooltip-p'>{output.Title}</p>
                                </Tooltip>
                                }
                            parentIndex={index}
                            collection={'Outputs'}
                            itemArray={outputs}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={output.Title}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                            <td>
                                <Tooltip content={'Indicator verwijderen'} width='80%' top='-60px'>
                                    <img className='table-delete-icon' data-docid={output.docid} src={deleteIcon} alt="eye icon" onClick={deleteOutput} />
                                </Tooltip>
                            </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
                <div className='add-row-container'>
                    <div style={{display: showAllOutputs ? 'none' : 'block'}}>
                        <Tooltip content={'Output toevoegen'} width='80%' top='-40px'>
                            <img src={plusButton} alt="" data-id={effectId} onClick={() => setShowAllOutputs(!showAllOutputs)}/>
                        </Tooltip>
                    </div>
                   
                    <div style={{display: showAllOutputs ? 'block' : 'none'}}>
                        <select name="" id="" onChange={outputHandler}>
                            <option value="">-- Selecteer output --</option>
                            {allOutputs && allOutputs.map(output => (
                                <option data-docid={output.docid} value={output.ID}>{output.Title}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddIndicators

