import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import plusButton from '../../images/icons/plus-icon.png'
import { useFirestore, useFirestoreMeasureMoments, useFirestoreID, useFirestoreGeneral, useFirestoreOrderBy } from "../../firebase/useFirestore";
import { useState, useContext, useEffect } from "react";
import { client } from '../../hooks/Client';
import uuid from "react-uuid";
import deleteIcon from '../../images/icons/delete-icon.png'
import { db, timestamp } from "../../firebase/config.js"
import EditIcon from "../../images/icons/edit-icon.png";
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import MeasureMoment from "../../components/Research/MeasureMoment";
import Tooltip from "../../components/common/Tooltip";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from "../../StateManagment/Auth";
import { ResearchBuilderSystemMessage, ResearchBuilderToolCallContent, ResearchBuilderSaveMessage } from "../../hooks/impactAI/Prompts"
import QuestionaireMeta from "../../components/Questionnaire/QuestionaireMeta";
import robotImage from "../../images/icons/robot-icon.png"
import TargetgroupMeta from '../../components/stakeholders/TargetgroupMeta';
import useSettings from "../../hooks/Settings";
import Breadcrumb from "../../components/common/Breadcrumb";
import settingsIcon from "../../images/icons/settings-icon.png";
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import { create, set } from 'lodash';

const ResearchBuilder = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
        position: [postition, setPosition],
        extraData: [extraData, setExtraData]
    } = useContext(ImpactAI);

    // State
    const [editQuestionnaire, setEditQuestionnaire] = useState('none')
    const [showTable, setShowTable] = useState('none')
    const [title, setTitle] = useState(null)
    const [editTargetgroup, setEditTargetgroup] = useState('none')
    const [pageDocid, setPageDocid] = useState('123')
    const [pageID, setPageID] = useState('123')
    const [researchHasQuestionnaire, setResearchHasQuestionnaire] = useState(false)
    const [questionnaireID, setQuestionnaireID] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    // Hooks
    const route = Location()[3]
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()
    Modal.setAppElement('#root');
    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    // Firestore
    const researches = useFirestoreID('Research', route ? route : '123')
    const questionnaires = useFirestoreOrderBy('Questionnaires', 'Position', 'asc')
    const researchQuestionnaireFields = useFirestoreGeneral('QuestionnaireFields', 'QuestionnaireID', questionnaireID ? questionnaireID : '123')
    const measureMoments = useFirestoreMeasureMoments(route ? route : '123')
    const stakeholders = useFirestore('Stakeholders')

    const deleteResearch = (e) => {
        const docid = e.target.dataset.docid

        db.collection('Research')
        .doc(docid)
        .delete()

    }

     //ImpactAI
     const pageSystemMessage = ResearchBuilderSystemMessage(title)
     const pageToolCallContent = ResearchBuilderToolCallContent()
     const pageSaveMessage = ResearchBuilderSaveMessage()
     const pageStartMessage = `Welkom, ${auth?.ForName}. Hier gaan we het onderzoek "${title}" verder inhoud geven.
     Kan ik je ergens mee helpen?`
     const pageCollection = 'MeasureMoments'
     const pageField = 'Title'
     const pageParentId = pageID
     const pageType = 'researchBuilder'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages([])
        setPosition(measureMoments ? measureMoments.length + 1 : 0)
    }, [auth,title, measureMoments, pageID])

    // Hide the table when there is no questionnaire selected
    const hideTable = () => {
        if(measureMoments && measureMoments.length > 0){
            setShowTable('none')
        } else {
            setShowTable('block')
        }
    }

    useEffect(() => {
        hideTable()
    }, [researches])

    // Set researchmeta in state
    useEffect(() => {
        researches && researches.map(research => (
            setTitle(research.Title),
            setPageDocid(research.docid),
            setPageID(research.ID),
            setQuestionnaireID(research.QuestionnaireID),
            setResearchHasQuestionnaire(research.QuestionnaireID !== ''),
            setExtraData(research.QuestionnaireID)
        ))
    }, [researches])

    // Add measure moment
    const addMoment = (e) => {

        const questionnaireID = e.target.dataset.questionnaireid
        const researchID = e.target.dataset.researchid
        const researchTitle = e.target.dataset.researchtitle

        const position = measureMoments.length + 1

        db.collection('MeasureMoments')
        .doc()
        .set({
            ResearchID: researchID,
            ResearchTitle: researchTitle,
            Moment: new Date(),
            Title: '',
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            QuestionnaireID: questionnaireID,
            Responses: 0,
            Position: position
        })
    }

    // Connect questionnaire to research
    const connectQuestionnaire = (questionnaireID, questionnaireTitle, docid) => {

    db.collection('Research')
        .doc(docid)
        .update({
            QuestionnaireID: questionnaireID,
            QuestionnaireTitle: questionnaireTitle

        })
        .then(() => {
            setSaved('flex')
            setIsOpen(false);
            setEditQuestionnaire('none')
        })
    }

    // Create questionnaire
    const addQuestionnaireHandler = (e) => {

        const docid = e.target.dataset.docid
        const researchTitle = e.target.dataset.researchtitle

        const questionaireId = uuid()
        const title = `Vragenlijst onderzoek ${researchTitle}`

        db.collection('Questionnaires')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: questionaireId,
            Timestamp: timestamp,
            Title: title,
            Description: '',
            Position: questionnaires.length + 1
        })
        .then(() => {
            connectQuestionnaire(questionaireId, title, docid)
        })
    }

    // Connect questionnaire
    const changeQuestioinnaireHandler = (e) => {


        const questionnaireID = e.target.dataset.questionnaireid
        const docid = e.target.dataset.docid
        const questionnaireTitle = e.target.dataset.title
        
        connectQuestionnaire(questionnaireID, questionnaireTitle, docid)
  
    }

    // Change research title
    const changeResearchTitle = (e) => {
        const docid = e.target.dataset.docid
        const title = e.target.value

        db.collection('Research')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Change targetgroup
    const changeTargetgroupHandler = (e) => {
            
        const targetgroupID = e.target.options[e.target.selectedIndex].value
        const targetgroupTitle = e.target.options[e.target.selectedIndex].dataset.title
        const docid = e.target.dataset.docid

        db.collection('Research')
        .doc(docid)
        .update({
            TargetgroupID: targetgroupID,
            TargetgroupTitle: targetgroupTitle

        })
        .then(() => {
            setSaved('flex')
            setEditTargetgroup('none')
        })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Wat zijn geschikte meetmomenten voor dit onderzoek?')
    }

    // Dropdown
    const toggleDropdown = () => setIsOpen(!isOpen);


  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <BiotechOutlinedIcon/>
                <h1>Onderzoek instellingen</h1>
            </div>
            <div id='research-details-container'>
                {researches && researches.map(research => (
                    <div id='research-details-item-container' key={research.ID}>
                        <div className="table-container dashboard-container">
                            <h3>Titel</h3>
                            <input type="text" data-docid={research.docid} defaultValue={research.Title} onChange={changeResearchTitle} />
                        </div>

                        <div className="table-container dashboard-container">
                            <h3>Doelgroep</h3>
                            <table>
                                <tr>
                                    <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                                </tr>
                                <tr>
                                    <td> 
                                        <div className='edit-type-container'>
                                            {research.TargetgroupID 
                                            ?
                                                <TargetgroupMeta targetgroupID={research.TargetgroupID}/>
                                            :
                                                <p>Nog geen doelgroep geselecteerd</p>
                                            }
                                            <Tooltip content={'Doelgroep selecterem'}  top='-60px'>
                                                <img src={EditIcon} alt="" onClick={() => editTargetgroup === 'none' ? setEditTargetgroup('block') : setEditTargetgroup('none')}/>
                                            </Tooltip>
                                        </div>
                                        <select name="" id="" data-docid={research.docid} defaultValue={research.QuestionnaireID} onChange={changeTargetgroupHandler} style={{display:editTargetgroup}}>
                                            <option value="">-- Selecteer een doelgroep --</option>
                                            {stakeholders && stakeholders.map(item => (
                                                <option key={item.ID} value={item.ID} data-title={item.Organisation} >{item.Organisation}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="table-container dashboard-container" >
                            <h3>Meetmomenten</h3>
                            <div className='list-top-row-container'>
                                <Tooltip content={'Meetmoment toevoegen'} width={'30px'} top='35px'>
                                    <img 
                                    className='add-item-button' 
                                    data-questionnaireid={research.QuestionnaireID} 
                                    src={plusButton} alt="" 
                                    data-researchid={research.ID} 
                                    data-researchtitle={research.Title} 
                                    onClick={addMoment} 
                                    />
                                </Tooltip>
                            </div>
                            <MeasureMoment research={research}/>
                            <p className='table-delete-icon' data-docid={research.docid} onClick={deleteResearch} src={deleteIcon} alt="" />
                        </div>

                        <div className="table-container dashboard-container" style={{display: measureMoments.length > 0 ? 'block' : 'none'}}>
                            <h3>Vragenlijst</h3>
                            <div className='list-top-row-container'>
                               
                            </div>
                            <table>
                                <tr>
                                    <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                                    <th style={{backgroundColor: secundairyColor}}>AANTAL VRAGEN</th>
                                    <th style={{backgroundColor: secundairyColor}}>INSTELLINGEN</th>
                                </tr>

                                <tr>
                                    <td>
                                        <div className='edit-type-container'>
                                            <QuestionaireMeta id={research.QuestionnaireID}/>
                                            <div className="dropdown" style={{display: editQuestionnaire}}>
                                                <div className="dropdown-header" onClick={toggleDropdown}>
                                                    {selectedOption ? selectedOption : `-- Selecteer een vragenlijst --`}
                                                    <span className={`arrow ${isOpen ? 'open' : ''}`}></span>
                                                </div>
                                                {isOpen && (
                                                     <div className="dropdown-list">
                                                        <div 
                                                        className="dropdown-item dropdown-type-container" 
                                                        data-researchtitle={research.Title} 
                                                        data-docid={research.docid} 
                                                        onClick={addQuestionnaireHandler}
                                                        >
                                                            + Vragenlijst toevoegen
                                                        </div>
                                                        <div 
                                                        className="dropdown-item dropdown-type-container" 
                                                        onClick={() => history.push(`/${client}/questionnaires`)}>
                                                            -- Vragenlijsten beheren
                                                        </div>
                                                        <div className="dropdown-type-container">
                                                            {questionnaires && questionnaires.map(questionnaire => (
                                                                <div
                                                                    key={questionnaire.ID}
                                                                    className="dropdown-item"
                                                                    data-questionnaireid={questionnaire.ID}
                                                                    data-title={questionnaire.Title}
                                                                    data-docid={research.docid}
                                                                    onClick={changeQuestioinnaireHandler}
                                                                >
                                                                    {questionnaire.Title}
                                                                </div>
                                                            ))}
                                                        </div>
                                                       
                                                    </div>
                                                )}
                                            </div>
                                            <Tooltip content={'Vragenlijst selecteren'} top='-65px'>
                                                <img src={EditIcon} alt="" onClick={() => editQuestionnaire === 'none' ? setEditQuestionnaire('block') : setEditQuestionnaire('none')}/>
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td>
                                        {research.QuestionnaireID !== '' 
                                            ?
                                            <p id='research-builder-questionnairefields-count' onClick={() => history.push(`/${client}/questionairebuilder/${research.QuestionnaireID}/${research.ID}`)}>
                                                {researchQuestionnaireFields.length}
                                            </p>
                                            :
                                            <p>Nog geen vragenlijst geselecteerd</p>
                                        }
                                    </td>
                                    <td>
                                        {research.QuestionnaireID !== '' 
                                            ?
                                            <img className='table-delete-icon' onClick={() => history.push(`/${client}/questionairebuilder/${research.QuestionnaireID}/${research.ID}`)} src={settingsIcon} alt="" />
                                            :
                                            <p>Nog geen vragenlijst geselecteerd</p>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
            <Breadcrumb
            section={'Meten'}
            previousPage={'Onderzoeken'}
            previousPageUrl={'research'}
            nextPage={''}
            nextPageUrl={'outputresults'}
            counter={2}
            totalSteps={5}
            />
        </div>
    </div>
  )
}

export default ResearchBuilder