import { client } from "../../hooks/Client"
import { useFirestoreOrderBy } from "../../firebase/useFirestore"
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';
import Tooltip from "../../components/common/Tooltip";
import { useState, useEffect, useContext } from "react";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import deleteIcon from '../../images/icons/delete-icon.png'
import Breadcrumb from "../../components/common/Breadcrumb";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import { db, timestamp } from "../../firebase/config";
import uuid from 'react-uuid'
import { EffectsSystemMessage, EffectsToolCallContent, EffectsSaveMessage } from "../../hooks/impactAI/Prompts"
import plusButton from '../../images/icons/plus-icon.png'
import robotImage from "../../images/icons/robot-icon.png"
import Modal from 'react-modal';
import database from "../../components/effects/EffectDatabase";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import EffectDatabaseItemsList from "../../components/effects/EffectDatabaseItemsList";
import SelectTargetgroup from "../../components/effects/SelectTargetgroup";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import IndicatorsCount from '../../components/Indicators/IndicatorsCount';
import firebase from "firebase";

const Effects = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // State 
    const [hoverIndex, setHoverIndex] = useState(null)
    const [editTerm, setEditTerm] = useState('none')
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedEffects, setSelectedEffects] = useState([])

    // Hooks
    ScrollToTop()
    const history = useHistory()
    const secundairyColor = useSettings().SecundairyColor
    Modal.setAppElement('#root');
    const modalStyles = {
      content: {
        margin: '5%'
      },
    };

    // Firestore 
    const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc')

    //ImpactAI
    const pageSystemMessage = EffectsSystemMessage()
    const pageToolCallContent = EffectsToolCallContent()
    const pageSaveMessage = EffectsSaveMessage()
    const pageStartMessage = `Welkom bij de effecten, ${auth?.ForName}. Hier ga je de maatschappelijke effecten benoemen die je met je activiteiten wilt bereiken.
    Kan ik een voorstel doen?`
    const pageExampleMessages = []
    const pageCollection = 'OutputEffects'
    const pageField = 'Effect'
    const pageParentId = ''
    const pageType = 'effect'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth])

    // Update the title
    const titleHandler = (e) => {
        const effect = e.target.value 
        const docid = e.target.dataset.docid

        db.collection('OutputEffects')
        .doc(docid)
        .update({
            Effect: effect
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Delete the effect
    const deleteEffect = (e) => {

        const docid = e.target.dataset.docid
        const id = e.target.dataset.id

        db.collection('OutputEffects')
        .doc(docid)
        .delete()
        .then(() => {
            removeIndicators(id)
        })
    }

    // Remove the indicators
    const removeIndicators = (id) => {
        db.collection('QuestionnaireFields')
        .where('EffectId', 'array-contains', id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                db.collection('QuestionnaireFields')
                .doc(doc.id)
                .update({
                    EffectId: firebase.firestore.FieldValue.arrayRemove(id)
                })
            })
        })
    }

    // Add a new effect
    const addEffect = () => {

        db.collection('OutputEffects')
        .add({
            Effect: '',
            Position: effects.length + 1,
            ID: uuid(),
            Compagny: client,
            Term: 'long',
            CompagnyID: client,
            Timestamp: timestamp,
            type: 'manual'
        })
        .then(() => {
            setSaved('flex')
        })
    }

    // Update the term
    const termHandler = (e) => {
        const term = e.target.options[e.target.selectedIndex].value
        const docid = e.target.dataset.docid

        db.collection('OutputEffects')
        .doc(docid)
        .update({
            Term: term
        })
        .then(() => {
            setSaved('flex')
            setEditTerm('none')
        })

    }

    // Edit the term
    const editTermHandler = () => {
        editTerm === 'none' ? setEditTerm('block') : setEditTerm('none')
    }

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])


    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, absoluut!')
    }

    // Save selected effects
    const saveSelectedEffects = () => {

        selectedEffects.forEach((effect) => {
            db.collection('OutputEffects')
            .add({
                Effect: effect.subeffect,
                MainEffect: effect.effect,
                SDGs: effect.sdgs,
                Position: effects.length + 1,
                ID: uuid(),
                Compagny: client,
                CompagnyID: client,
                Timestamp: timestamp,
                type: 'database'
            })
        })

        setModalOpen(false)

    }

    const effectTerm = (term) => {
        if (term === 'short') {
            return 'Korte termijn'
        } else if (term === 'long') {
            return 'Lange termijn'
        } else {
            return 'Geen termijn toegevoegd'
        }
    }

  return (
    <div className="main">
    <div className="main-container">
        <div className="page-header">
            <PodcastsOutlinedIcon/>
            <h1>Effecten</h1>
        </div>
        <div className="table-container dashboard-container">
            <div className='list-top-row-container'>
                <Tooltip content={'Selecteer uit effecten database'} top='35px' width='30px'>
                    <ListAltOutlinedIcon onClick={() => setModalOpen(true)} />
                </Tooltip>
                <Tooltip content={'Handmatig effect toevoegen'} top='35px' width='30px'>
                    <img  src={plusButton} alt="" onClick={addEffect} />
                </Tooltip>
                <Tooltip content={'Kan ik je helpen?'} width='30px' top='-10px' left='30px'>
                    <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                </Tooltip>
                {/* <p onClick={() => history.push(`/${client}/StandardEffects`)}>of voeg gestandaardiseerd effect toe</p> */}
            </div>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>EFFECT</th>
                        <th style={{backgroundColor: secundairyColor}}>INDICATOREN</th>
                        <th style={{backgroundColor: secundairyColor}}>DOELGROEP</th>
                        <th style={{backgroundColor: secundairyColor}}>TERMIJN</th> 
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                </thead>    
                {effects && effects.map((effect, index) => (
                    <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={effect}
                        input={
                            <Tooltip content={effect.Effect} width='80%' right='-220px' top='-35px'>
                                <input type="text" data-docid={effect.docid} defaultValue={effect.Effect} placeholder='Effect' onChange={titleHandler}/>
                            </Tooltip>
                        }
                        parentIndex={index}
                        collection={'OutputEffects'}
                        itemArray={effects}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={effect.Effect}
                        setPlaceholderIndex={setPlaceholderIndex}
                        >
                        <td>
                            <div id='indicator-effect-icons-container'>
                                <Tooltip content={'Indicatoren toevoegen'} top='-60px'>
                                    <img  src={plusButton} alt="" className='table-delete-icon' onClick={() => history.push(`/${client}/addindicators/${effect.ID}`)}/>
                                </Tooltip>
                                <Tooltip content={'Aantal indicatoren'} top='-60px' width='30px'>
                                    <p><IndicatorsCount effectId={effect.ID} /></p>
                                </Tooltip>
                            </div>
                        </td>

                        <td>
                            <SelectTargetgroup effect={effect} />
                        </td>
                        <td>
                            <div className="table-edit-container">
                                <div id='edit-term-container'>
                                    <select name="" id="" data-docid={effect.docid} onChange={termHandler} style={{display: editTerm}}>
                                        <option value="">-- Selecteer termijn --</option>
                                        <option value="short">Korte termijn</option>
                                        <option value="long">Lange termijn</option>
                                    </select>
                                </div>
                                <p style={{display: editTerm === 'none' ? 'flex' : 'none'}}>{effectTerm(effect.Term)}</p>
                                <Tooltip content={'Termijn aanpassen'} top='-60px'>
                                    <CreateOutlinedIcon onClick={editTermHandler}/>
                                </Tooltip>
                            </div>
                        </td>
                        <td>
                            <img className='table-delete-icon' data-id={effect.ID} data-docid={effect.docid} onClick={deleteEffect} src={deleteIcon} alt="" />
                        </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
        </div>
        <Breadcrumb
        section={'Impactstrategie'}
        previousPage={'Outputs'}
        previousPageUrl={'Outputs'}
        nextPage={'Theory Of Change'}
        nextPageUrl={'theoryofchange'}
        counter={7}
        totalSteps={8}
        />
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={modalOpen}
        style={modalStyles}
        contentLabel="Effect selecteren"
        >
            <div>
                <h1>Effect database</h1>
                <div>
                    {database && database.map((effect, index) => (
                        <EffectDatabaseItemsList effect={effect} index={index} setSelectedEffects={setSelectedEffects} secundairyColor={secundairyColor} />
                    ))}
                </div> 
                <div id='modal-button-container'>
                    <button id='modal-cancel-button'onClick={() => setModalOpen(false)}>Annuleren</button>
                    <button id='modal-save-button'  onClick={saveSelectedEffects}>Opslaan</button>
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default Effects