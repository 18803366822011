import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useFirestoreGeneral } from '../../firebase/useFirestore';
import PortfolioMeta from '../../components/syncronisations/PortfolioMeta';
import PortfolioOutputs from '../../components/syncronisations/PortfolioOutputs';
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client";

const Notifications = () => {

  // Hooks
  const history = useHistory()

  // Firestore
  const syncs = useFirestoreGeneral('Synchronisations', 'Status', 'requested')

  return (
    <div className="main">
        <div className="main-container" >
            <div className="page-header" >
                <NotificationsNoneOutlinedIcon />
                <h1>Notificaties</h1>
            </div>
            <div id='notifications-container'>
              {syncs && syncs.map((sync) => (
                <div className='notification-item-container'>
                  <h2><PortfolioMeta portfolioID={sync.PortfolioID} syncs={syncs}/></h2>
                  <p>wil zijn {sync.Type}</p>
                  <p><b><PortfolioOutputs portfolioID={sync.PortfolioID} outputID={sync.SyncItem} syncs={syncs}/></b></p>
                  <p>synchroniseren</p>
                  <button onClick={() => history.push(`/${client}/synchronisationbuilder/${sync.ID}`)}>Verzoek behandelen</button>
                </div>
              ))}
            </div>
        </div>
    </div>
  )
}

export default Notifications