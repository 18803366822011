import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TinyMCE from '../../components/common/TinyMCE';
import Location from '../../hooks/Location';
import { useEffect, useState, useContext } from 'react';
import { useFirestoreGeneral, useFirestoreID, useFirestoreGeneralArrayContains } from '../../firebase/useFirestore';
import GoalIndicators from '../../components/personas/GoalIndicators';
import { Responses } from "../../StateManagment/Responses";
import { v4 as uuid } from 'uuid';
import { client } from '../../hooks/Client';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { db, timestamp } from '../../firebase/config';

const PersonaReportDetail = () => {
  // Context
  const [saved, setSaved] = useContext(SavedIcon)

  // State
  const [body, setBody] = useState('')
  const [docid, setDocid] = useState('')
  const [date, setDate] = useState('')
  const [researchId, setResearchId] = useState('')
  const [questionnaireId, setQuestionnaireId] = useState('')
  const [effectId, setEffectId] = useState('')
  const [responses, setResponses] = useState([])

  // Hooks
  const reportId = Location()[3]
  const personaId = Location()[4]
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

  // Firestore
  const report = useFirestoreID('PersonaReports', reportId ? reportId : '')
  const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', personaId ? personaId : '')
  const questionnaireField = useFirestoreGeneralArrayContains('QuestionnaireFields', 'EffectId', effectId ? effectId : '')
  const research = useFirestoreGeneral('Research', 'QuestionnaireID', questionnaireId ? questionnaireId : '')

  // Set docid of goal in state
  useEffect(() => {
    report && report.forEach(item => {
      setBody(item.Body)
      setDocid(item.docid)
      setDate(item.Timestamp.toDate().toLocaleDateString("nl-NL", options))
    })
  }, [report])

  // Save body to firestore
  const saveBody = () => {

    db.collection('PersonaReports')
    .doc(docid)
    .update({
      Body: body
    })

  }

  // Get questionnaire id
  useEffect(() => {
    questionnaireField && questionnaireField.map(field => {
        setQuestionnaireId(field.QuestionnaireID)
    })
  }, [questionnaireField])

  // Get research id
  useEffect(() => {
      research && research.map(doc => {
          setResearchId(doc.ID)
      })
  }, [research])

  // Save results
  const saveResults = () => {

    const formID = uuid()

    responses && responses.map(response => {

        db.collection('QuestionnairesResponses')
            .doc()
            .set({
            FieldID: response.FieldID,
            Input: response.Input,
            Timestamp: timestamp,
            CompagnyID: client,
            QuestionannaireID: '',
            MomentID: '',
            ResearchID: researchId,
            FormID: formID,
            ID: uuid(),
            OptionType: response.OptionType ? response.OptionType : '',
            Persona: personaId ? personaId : '',
            PersonaType: 'coach',
            ReportID: reportId,
        })
        .then(() => {
            setSaved(true)
        })
    })
}

  return (
    <div className="main">
        <div className="main-container" >
        <div className='page-header'>
            <SummarizeOutlinedIcon/>
            <h1>Rapportage instellingen</h1>
            <p>{date}</p>
        </div>
        <div className="dashboard-container">
          <h2>Rapportage</h2>
            <TinyMCE body={body} setBody={setBody} />
            <button className="button-simple" onClick={saveBody}>Opslaan</button>
        </div>
        <div className="dashboard-container">
          <h2>Doelen</h2>
          {goals && goals.map(goal => (
            <div key={goal.ID} id='persona-goal-item-container'>
              <h3>{goal.Goal}</h3>
              <GoalIndicators 
              effects={goal.Effects}
              reportId={reportId}
              setEffectId={setEffectId}
              setResponses={setResponses}
              />
            </div>
          ))}
           <button className='button-simple' onClick={saveResults} >Opslaan</button>
        </div>
      </div>
    </div>
  )
}

export default PersonaReportDetail