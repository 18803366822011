import { useEffect, useState } from 'react'
import { db } from '../../firebase/config'
import IndicatorData from '../Indicators/IndicatorData'

const PersonaGoalData = ({ goal }) => {

  // State
  const [questionnaireFields, setQuestionnaireFields] = useState([])

  const effects = goal.Effects

  // Get the questionnaireFields for the effect
  useEffect(() => {
    const fetchQuestionnaireFields = async () => {
      const array = []

      if (effects) {
        for (const effectId of effects) {
          const querySnapshot = await db.collection('QuestionnaireFields')
            .where('EffectId', '==', effectId)
            .get()
          
          querySnapshot.forEach(doc => {
            array.push(doc.data())
          })
        }
      }

      setQuestionnaireFields(array)
    }

    fetchQuestionnaireFields()
  }, [effects])

  return (
    <>
        {questionnaireFields && questionnaireFields.map(field => (
            <div key={field.ID}>
                <IndicatorData indicator={field} />
            </div>
        ))}
    </>
  )
}

export default PersonaGoalData