import { useState } from 'react'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Tooltip from './Tooltip';

const CopyLink = ({link}) => {
    const [copied, setCopied] = useState(false)

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://impactdashboard.deccos.nl/${link}`)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }  

  return (
    <div id='copy-link-container'>
        <Tooltip content='Kopieer link' top='-60px'>
            <ContentCopyOutlinedIcon className='icon-margin-left' onClick={copyToClipboard} />
        </Tooltip>
        {copied && <p className='copied'>Gekopieerd!</p>}
    </div>
  )
}

export default CopyLink