import { useFirestoreGeneral } from '../../firebase/useFirestore';
import PersonaGoalData from '../../components/personas/PersonaGoalData';
import MixedBarChart from '../../components/Visualisations/MixedBarChart';
import { useEffect, useState } from 'react';
import CheckIcon from '../../images/icons/check-icon.png';
import ErasorIcon from '../../images/icons/erasor-icon.png';
import XIcon from '../../images/icons/x-icon.png';
import CalendarIcon from '../../images/icons/calendar-icon.png';

const Dashboard = ({ personaNavigation, item }) => {
  // State
  const [presenceVisualisationData, setPresenceVisualisationData] = useState([]); 
  const [totalPresent, setTotalPresent] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [totalCorrections, setTotalCorrections] = useState(0);

  // Hooks
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const timeFormat = new Intl.DateTimeFormat('nl-NL', { hour: '2-digit', minute: '2-digit' });

  // Firestore
  const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', item.ID ? item.ID : '');
  const presenceReports = useFirestoreGeneral('PersonaPresenceReports', 'PersonaID', item.ID ? item.ID : ''); // Reported presence by support worker
  const presence = useFirestoreGeneral('PersonaPresence', 'PersonaID', item.ID ? item.ID : ''); // Supposed presence

  // SetPresenceData
  useEffect(() => {
    const totalPresent = [];
    const totalAbsent = [];
    const totalCorrections = [];

    presenceReports && presenceReports.map(report => {
      if (report.Type === 'correct') {
        totalPresent.push(report);
      } else if (report.Type === 'absent') {
        totalAbsent.push(report);
      } else if (report.Type === 'correction') {
        totalCorrections.push(report);
      }

    });

    setTotalPresent(totalPresent.length);
    setTotalAbsent(totalAbsent.length);
    setTotalCorrections(totalCorrections.length);

  }, [presenceReports]);


  // Create visualisation data
  const createVisualisationData = () => {

    const array = []

    // Loop through presence reports
    presenceReports && presenceReports.map(report => {

      console.log(report)

      const object = {
        date: report.Date.toDate().toLocaleDateString('nl-NL', options),
        Geroosterd: 8,
        Afwezig: 1,
        reason: report.Reason,
        type: report.Type
      };

      const reportDate = report.Date

      array.push(object)

    });

    array.sort((a, b) => a - b);



    setPresenceVisualisationData(array);
  };

  useEffect(() => {

    createVisualisationData();

  }, [presenceReports]);

  const calculatePresenceDifferenceCorrection = (start, end, date) => {

    const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const weekday = dayNames[date.toDate().getDay()];
    const startDateTime = start && new Date(date.toDate().setHours(...start.split(':').map(Number)));
    const endDateTime = end && new Date(date.toDate().setHours(...end.split(':').map(Number)));

    let totalDifference = 0;
    
    presence.filter(presence => {
      const presenceTimes = presence.PresenceTimes || {};
      if (presenceTimes[weekday] && presenceTimes[weekday].start && presenceTimes[weekday].end) {
        const supposedPresence = new Date(date.toDate().setHours(...presenceTimes[weekday].end.split(':').map(Number))) - 
                                 new Date(date.toDate().setHours(...presenceTimes[weekday].start.split(':').map(Number)));
        const actualPresence = endDateTime - startDateTime;

        const difference = actualPresence - supposedPresence

        const differenceinHours = difference / 3600000;

        const differenceinHoursToNumber = parseFloat(differenceinHours);

        
        totalDifference = differenceinHoursToNumber
      }
    });

    return totalDifference;
  }

  // Add current date to timeline
  const currentDate = (startDate, endDate) => {
    const currentDate = new Date();
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);

    // Calculate total days
    const totalDays = (endDateObject - startDateObject) / (1000 * 60 * 60 * 24);

    // Calculate difference in days from start date
    const differenceInDaysFromStart = (currentDate - startDateObject) / (1000 * 60 * 60 * 24);

    // Calculate percentage difference in days from start date from total days
    const percentageDifference = (differenceInDaysFromStart / totalDays) * 100;

    console.log(percentageDifference)


    return (
      <div id='timeline-current-date-container' style={{ width: `${percentageDifference}%` }}>
        <p>Vandaag</p>
        <div id='timeline-current-date'></div>
      </div>
    );
  }



  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      const tooltipType = (type) => {
        if (type === 'correct') {
          return 'Gecorrigeerd';
        } else if (type === 'absent') {
          return 'Afwezig';
        } else if (type === 'correction') {
          return 'Gecorrigeerd';
        }
      }

      return (
        <div className="custom-tooltip">
          <p className="label">Datum: {payload[0].payload.date}</p>
          <p className="label" id='presence-tooltip-type'>{tooltipType(payload[0].payload.type)}</p>
          <p className="desc">Geroosterd: {payload[0].payload.planned}</p>
          <p className="desc">Afwezig: {payload[0].payload.absent}</p>
          <p className="desc">Reden: {payload[0].payload.reason}</p>
        </div>
      );
    }
  
    return null;
  };
  
  

  return (
    <div style={{ display: personaNavigation === 'dashboard' ? 'block' : 'none' }}>
      <h2>Dashboard</h2>

      <div className='table-container dashboard-container'>
        <h2>Tijdlijn</h2>
        <div id='timeline-container'>
          <div>
            <h3>Startdatum</h3>
            <p>{item.StartDate}</p>
          </div>
          <div id='timeline-activities-container'>
            {currentDate(item.StartDate, item.EndDate)}
          </div>
          <div>
            <h3>Einddatum</h3>
            <p>{item.EndDate}</p>
          </div>
        </div>
      </div>

      <div className='table-container dashboard-container'>
        <h2>Doelen</h2>
        {goals && goals.map(goal => (
          <div key={goal.ID} id='persona-goal-item-container'>
            <h3>{goal.Goal}</h3>
            <PersonaGoalData goal={goal} />
          </div>
        ))}
      </div>

      <div className='table-container dashboard-container'>
        <h2>Aanwezigheid</h2>
        <div>
          <div className='presence-data-container'>
            <img src={CalendarIcon} alt="erasor" id='register-presence-check-icon'/>
            <p><b>Totaal geroosterd</b></p>
            <p>{presenceReports.length }</p>
          </div>
          <div className='presence-data-container'>
            <img src={CheckIcon} alt="erasor" id='register-presence-check-icon'/>
            <p>Waarvan aanwezig</p>
            <p>{totalPresent}</p>
          </div>
          <div className='presence-data-container'>
            <img src={ErasorIcon} alt="erasor" id='register-presence-check-icon'/>
            <p>Waarvan gecorrigeert</p>
            <p>{totalCorrections}</p>
          </div>
          <div className='presence-data-container'>
            <img src={XIcon} alt="erasor" id='register-presence-check-icon'/>
            <p>Waarvan afwezig</p>
            <p>{totalAbsent}</p>
          </div>
        </div>
        <MixedBarChart data={presenceVisualisationData} customTooltip={<CustomTooltip/>} />
      </div>
    </div>
  );
};

export default Dashboard;