import React from 'react'
import firebase from 'firebase'
import deleteIcon from '../../images/icons/delete-icon.png'
import { db } from '../../firebase/config'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import { useContext, useState } from "react";
import useSettings from "../../hooks/Settings";
import { useHistory } from "react-router-dom";
import ResearchMeta from "../../components/Research/ResearchMeta";
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { useFirestoreID, useFirestore } from "../../firebase/useFirestore";
import PersonaResearchLink from "../../components/personas/PersonaResearchLink";

const Research = ({personaNavigation, item, personaDocid}) => {

    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [selectedResearch, setSelectedResearch] = useState('')

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()
    const route = Location()[3]

    // Firestore
    const research = useFirestore('Research')
    const personas = useFirestoreID('Personas', route)

    const researchHandler = (e) => {

        const value = e.target.options[e.target.selectedIndex].value

        setSelectedResearch(value)

    }

    const addResearch = () => {

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            Research: firebase.firestore.FieldValue.arrayUnion(selectedResearch)
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteResearch = (e) => {

        const item = e.target.dataset.item

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            Research: firebase.firestore.FieldValue.arrayRemove(item)
        })
        .then(() => {
            setSaved('flex')
        })

    }

  return (
    <div style={{display: personaNavigation === 'research' ? 'block' : 'none'}}>
        <h2>Onderzoeken</h2>
        <div className="table-container dashboard-container">

            <p>Koppel één of meerdere onderzoeken aan deze persona</p>
            <select name="" id="" onChange={researchHandler}>
                <option value="">-- Selecteer een onderzoek --</option>
                {research && research.map(item => (
                    <option key={item.ID} value={item.ID}>{item.Title}</option>
                ))}
            </select>
            <div className="edit-persona-button-container">
                <button className="button-simple" onClick={addResearch}>Voeg toe</button>
            </div>
            
            <div className='table-container'>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>ONDERZOEK</th>
                        <th style={{backgroundColor: secundairyColor}}>START ONDERZOEK</th>
                        <th style={{backgroundColor: secundairyColor}}>RESULTATEN</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {personas && personas.map(persona => (
                        <>
                            {item.Research && item.Research.map(item => (
                                <tr key={persona.ID}>
                                    <td>
                                        <ResearchMeta item={item}/>
                                    </td>
                                    <td>
                                        <PersonaResearchLink researchID={item} personaID={persona.ID}/>
                                    </td>
                                    <td>
                                        <BarChartOutlinedIcon className="table-delete-icon" onClick={() => history.push(`/${client}/researchresultspersonas/${item}/${persona.ID}`)}/>
                                    </td> 
                                    <td>
                                        <img className='table-delete-icon' data-item={item} onClick={deleteResearch} src={deleteIcon} alt="" />
                                    </td>
                                </tr>
                            ))}
                        </>     
                    ))}
                </table>
            </div>

        </div>
    </div>
  )
}

export default Research