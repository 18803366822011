import { useState, useEffect, useContext } from "react";
import Section from "./Section";
import TopLevelFields from "./TopLevelFields";
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid';
import { Responses } from "../../StateManagment/Responses";
import { useFirestoreGeneralOrderBy } from "../../firebase/useFirestore";
import Location from "../../hooks/Location"
import { client } from "../../hooks/Client";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const QuestionnaireField = ({questionaire, setTotalTopLevelQuestions, setFieldIndex, fieldIndex, compagnyId}) => {
    // Context
    const [responses, setResponses] = useContext(Responses)

    // State
    const [saved, setSaved] = useState(false)

    // Get url params
    const moment = Location()[4]
    const research = Location()[5]
    const persona = Location()[6]
    const test = Location()[7]

    // Firestore
    const fields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'QuestionnaireID', questionaire.ID, 'Position', 'asc') 

    // Set total top level questions
    const topLevelFields = fields && fields.filter(field => field.SectionID === '')   

    setTotalTopLevelQuestions(topLevelFields?.length)

    const saveResponses = () => {

        const formID = uuid()

        responses && responses.map(response => {

            db.collection('QuestionnairesResponses')
                .doc()
                .set({
                FieldID: response.FieldID,
                Input: response.Input,
                Timestamp: timestamp,
                CompagnyID: client,
                QuestionannaireID: questionaire.ID,
                MomentID: moment,
                ResearchID: research,
                FormID: formID,
                ID: uuid(),
                OptionType: response.OptionType ? response.OptionType : '',
                Persona: persona ? persona : '',
            })
            .then(() => {
                setSaved(true)
            })
        })
    }


    return(
        <div id='questionnaire-fields-container'>
            {topLevelFields && topLevelFields.map((field, index) => (
                <div key={field.ID} id='questionnaire-field-section-item-container'>
                    {field.Type === 'section' && 
                        <Section 
                        field={field} 
                        index={index} 
                        setFieldIndex={setFieldIndex} 
                        fieldIndex={fieldIndex} 
                        compagnyId={compagnyId} 
                        questionaire={questionaire}
                        />
                    }
                    {field.Type !== 'section' && 
                        <TopLevelFields 
                        field={field} 
                        index={index} 
                        setFieldIndex={setFieldIndex} 
                        fieldIndex={fieldIndex} 
                        compagnyId={compagnyId} 
                        questionaire={questionaire}
                        />
                    }
                </div>
             ))} 
             <div style={{display: fieldIndex + 1  > topLevelFields?.length ? 'block' : 'none'}}>
                <div style={{display: saved ? 'none' : 'block'}}>
                    <h2>Dit is het einde van de vragenlijst</h2>
                    {test === 'test' ? <p>Dit is een previewversie van de vragenlijst. De antwoorden kunnen niet worden opgeslagen.</p>
                    :
                    <p>Klik op verzenden om de vragenlijst op te slaan</p>
                    }
                    <div className="questionnaire-button-container">
                        <button onClick={() => setFieldIndex(fieldIndex - 1)}>Terug</button>
                        <button onClick={saveResponses} id='questionniare-send-button' style={{display: test === 'test' ? 'none' : 'block'}}>
                            <div id='send-button-inner-container' >
                                <SendOutlinedIcon />
                                <p >Verzenden</p>
                            </div>
                        </button>
                    </div>
                </div>
               
                <div style={{display: saved ? 'block' : 'none'}}>
                    <h2>Uw antwoorden zijn opgeslagen</h2>
                    <button onClick={() => window.location.reload()}>Opnieuw</button>
             </div>
             </div>
        </div>
    )
}

export default QuestionnaireField