import { NavLink } from "react-router-dom";
import { client } from '../../hooks/Client';
import { useContext, useState, useEffect } from 'react';
import { Auth } from '../../StateManagment/Auth';
import { useFirestoreAdmins, useFirestoreNoCompagny } from "../../firebase/useFirestore"
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import Tooltip from "../common/Tooltip";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SwitchAccount from "../common/SwitchAccount";
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import useSettings from "../../hooks/Settings";
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { useHistory } from 'react-router-dom';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

const LeftSideBarAuthProfile = () => {
    // Context
    const [authO] = useContext(Auth)

    // State
    const [open, setOpen] = useState(true)
    const [impactAdminArray, setImpactAdminArray] = useState([])
    const [superAdminsArray, setSuperAdminsArray] = useState([])

    // Hooks
    const SecColor = useSettings().SecundairyColor
    const logo = useSettings().logo
    const history = useHistory()

    // Firestore
    const admins = useFirestoreAdmins(authO ? authO.ID : '')
    const impactAIAdmins = useFirestoreNoCompagny('Users', 'ImpactAIAdmin', true)
    const superAdmins = useFirestoreNoCompagny('Users', 'SuperAdmin', true)

    // Set background color of active link
    const setBackgroundcolor = (match) => {
        if (match) {
          return { backgroundColor: SecColor }; // Set your desired background color here
        }
        return null; // No background color when the route is not active
      };

    // Create array of superAdmins
    useEffect(() => {
        if (superAdmins) {
            const adminIDs = superAdmins.map(admin => admin.ID); // Extract all IDs
            setSuperAdminsArray(adminIDs); // Update the state once with all IDs
        }
    }, [superAdmins]);
    
    const Admin = () => {
        if(admins.length > 0 || superAdminsArray.includes(authO?.ID)){
            return <div className="channel-div">
                        <h3>Admin</h3>
                        <div className="channel-inner-div">
                            {/* <div className='activity-meta-title-container'>
                                <CardMembershipOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/subscription`}>Abonnement</NavLink>
                            </div> */}
                            <div className='activity-meta-title-container'>
                                <SettingsOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/Settings`}>Instellingen</NavLink>
                            </div>
                            <div className='activity-meta-title-container'>
                                <GroupsOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/Members`}>Team</NavLink>
                            </div>
                            <div className='activity-meta-title-container'>
                                <ManageAccountsOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/UserRoles`}>Gebruikersrollen</NavLink>
                            </div>
                            <div className='activity-meta-title-container'>
                                <FolderOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/Data`}>Data</NavLink>
                            </div>
                        </div>
                    </div>
        } else {
            return null
        }
    }

    const SuperAdmin = () => {
        if(superAdminsArray.includes(authO?.ID)){
            return <div>
                        <h3>Super admin</h3>
                        <div className="channel-inner-div">
                            {/* <div className='activity-meta-title-container'>
                                <AdminPanelSettingsOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/adminlist`}>Admins</NavLink>
                            </div> */}
                            <div className='activity-meta-title-container'>
                                <AccountBalanceOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/adminfunds`}>Fondsen beheer</NavLink>
                            </div>
                            <div className='activity-meta-title-container'>
                                <AccountBalanceOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/metadata`}>Meta data</NavLink>
                            </div>
                        </div>
                    </div>
        } else {
            return null
        }
    }

    const ImpactAIAdmins = () => {
        if(impactAdminArray.includes(authO?.ID)){
            return <div>
                        <h3>ImpactAI</h3>
                        <div className="channel-inner-div">
                            <div className='activity-meta-title-container'>
                                <SettingsOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/aisettings`}>Instellingen</NavLink>
                            </div>
                            <div className='activity-meta-title-container'>
                                <PsychologyOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/dataindexing`}>Geheugen</NavLink>
                            </div>
                            <div className='activity-meta-title-container'>
                                <ContactSupportOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/qandas`}>Reacties</NavLink>
                            </div>
                            <div className='activity-meta-title-container'>
                                <LightbulbOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/aisuggestions`}>Suggesties</NavLink>
                            </div>
                            <div className='activity-meta-title-container'>
                                <ChatBubbleOutlineOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/aiprompts`}>Prompts</NavLink>
                            </div>
                        </div>
                    </div>
        } else {
            return null
        }
    }

    return (
        <div id='left-side-bar-toggle-container'>
            <div className="left-sidebar-container" style={{width: open ? '257px' : '0px'}}>
                <div id='leftsidebar-logo-container'  style={{display: open ? 'flex' : 'none'}}>
                    <img src={logo} className="top-bar-logo" alt="logo" onClick={() => history.push(`/${client}/home`)}/>
                </div>

                <div className="leftsidebar-seperator"></div>
                {/* <ImpactAIAdmins/> */}
                <div className="left-side-bar" style={{display: open ? 'block' : 'none'}}>
                    <SuperAdmin/>
                    <Admin/>
                    <div className="channel-div">
                        <h3>Mijn profiel</h3>
                        <div className="channel-inner-div">
                            <div className='activity-meta-title-container'>
                                <PersonOutlineOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/Profile`}>Mijn profiel</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="switch-account-container" style={{display: authO && authO.Compagny.length > 0 ? 'block' : 'none'}}>
                        <h3>Mijn organisaties</h3>
                        <SwitchAccount/>
                    </div>
                </div>
            </div>
            <div id='leftsidebar-toggle-item-container' >
                <div style={{display: open ? 'block' : 'none'}} onClick={() => setOpen(false)}>
                    <Tooltip content='Menu inklappen' >
                        <KeyboardDoubleArrowLeftOutlinedIcon onClick={() => setOpen(false)}/>
                    </Tooltip>
                </div>
                <div style={{display: open ? 'none' : 'block'}} onClick={() => setOpen(true)}>
                    <Tooltip content='Menu uitklappen' >
                        <KeyboardDoubleArrowRightOutlinedIcon onClick={() => setOpen(true)}/>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default LeftSideBarAuthProfile
