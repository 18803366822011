
const database = [
    {
        id: 1,
        name: 'Fysieke gezondheid',
        subeffects: [
            {
                id: 1,
                name: 'Verbeterde lichamelijke activiteit',
                SDGs: [3],
                Questions: [
                    {
                        id: 1,
                        question: 'In hoeverre ben je tevreden met ?',
                        targetgroup: 'kinderen'
                    },
                    {
                        id: 2,
                        question: 'Hoeveel uur per week sport je?',
                        targetgroup: 'jongeren'
                    },
                ],
                MKBA: [
                    {
                        id: 1,
                        number: 350,
                    }
                ]
            },
            {
                id: 2,
                name: 'Verbeterde voedingsatronen',
                SDGs: [3]
            },
            {
                id: 3,
                name: 'Preventie van ziekte',
                SDGs: [3]
            },
            {
                id: 4,
                name: 'Vermindering verslaving(en)',
                SDGs: [3]
            },
            {
                id: 5,
                name: 'Gezonder lichaamsgewicht',
                SDGs: [3]
            },
            {
                id: 6,
                name: 'Omgaan met chronische ziekte',
                SDGs: [3]
            },
            {
                id: 7,
                name: 'Verbeterde slaapgezondheid',
                SDGs: [3]
            },
            {
                id: 8,
                name: 'Meer ontspanning',
                SDGs: [3]
            },
            {
                id: 9,
                name: 'Verbeterde toegang tot gezondheidszorg',
                SDGs: [3]
            },

            {
                id: 10,
                name: 'Verbeterde seksuele gezondheid',
                SDGs: [3]
            },
            {
                id: 11,
                name: 'Verbeterde hygiëne',
                SDGs: [3]
            },
            {
                id: 12,
                name: 'Verminderd werkverzuim',
                SDGs: [3,8]
            }
        ],
    },
    {
        id: 2,
        name: 'Mentale gezondheid',
        subeffects: [
            {
                id: 1,
                name: 'Stressvermindering- en preventie',
                SDGs: [3]
            },
            {
                id: 2,
                name: 'Angstbeheersing- en preventie',
                SDGs: [3]
            },
            {
                id: 3,
                name: 'Depressiebeheersing- en preventie',
                SDGs: [3]
            },
            {
                id: 4,
                name: 'Verbeterd zelfbeeld',
                SDGs: [3]
            },
            {
                id: 5,
                name: 'Verhoogd zelfvertrouwen',
                SDGs: [3]
            },
            {
                id: 6,
                name: 'Verhoogde emotionele intelligentie',
                SDGs: [3]
            },
            {
                id: 7,
                name: 'Verbeterde copingvaardigheden',
                SDGs: [3]
            },
            {
                id: 8,
                name: 'Verbeterde toegang tot geestelijk ondersteuning en zorg',
                SDGs: [3]
            },
            {
                id: 9,
                name: 'Toename in gevoel van zingeving',
                SDGs: [3]
            },
            {
                id: 10,
                name: 'Verminderd werkverzuim',
                SDGs: [3,8]
            }
        ],
    },
    {
        id: 3,
        name: 'Sociale gezondheid',
        subeffects: [
            {
                id: 1,
                name: 'Bevordering sociale cohesie',
                SDGs: [11]
            },
            {
                id: 2,
                name: 'Vergroten van sociale netwerk',
                SDGs: [3]
            },
            {
                id: 3,
                name: 'Vermindering van sociale isolatie en eenzaamheid',
                SDGs: [3]
            },
            {
                id: 4,
                name: 'Vermindering van sociale conflicten',
                SDGs: [3]
            },
            {
                id: 5,
                name: 'Bevorderen van interculturele dialoog',
                SDGs: [11]
            },
            {
                id: 6,
                name: 'Bevorderen van intergenerationele dialoog',
                SDGs: [11]
            },
            {
                id: 7,
                name: 'Verbeterde sociale vaardigheden',
                SDGs: [3]
            },
            {
                id: 8,
                name: 'Ondersteuning en preventie van sociale problemen',
                SDGs: [3]
            },
        ],
    },
    {
        id: 4,
        name: 'Persoonlijke ontwikkeling',
        subeffects: [
            {
                id: 1,
                name: 'Toename van kennis en vaardigheden',
                SDGs: [4]
            },
            {
                id: 2,
                name: 'Verbeterde professionele vaardigheden',
                SDGs: [8]
            },
            {
                id: 3,
                name: 'Verbeterde creativiteit en artisticiteit ',
                SDGs: [3]
            },
            {
                id: 4,
                name: 'Verhoogde zelfbewustzijn- en zelfreflectie',
                SDGs: [3]
            },
            {
                id: 5,
                name: 'Verbeterde persoonlijke effectiviteit',
                SDGs: [3]
            },
            {
                id: 6,
                name: 'Verbeterde leiderschapsvaardigheden',
                SDGs: [3]
            },
            {
                id: 7,
                name: 'Verbeterde financiële geletterdheid en zelfredzaamheid',
                SDGs: [8]
            },
            {
                id: 8,
                name: 'Verbeterde taalvaardigheden',
                SDGs: [8]
            },
            {
                id: 9,
                name: 'Verbeteren soft skills',
                SDGs: [8]
            }
        ],
    },
    {
        id: 5,
        name: 'Zelfredzaamheid',
        subeffects: [
            {
                id: 1,
                name: 'Verbeterde financiële zelfredzaamheid',
                SDGs: [1]
            },
            {
                id: 2,
                name: 'Verbeterde sollicitatievaardigheden',
                SDGs: [1,8]
            },
            {
                id: 3,
                name: 'Verbeterde zelfvoorziening in dagelijks leven',
                SDGs: [3]
            },
            {
                id: 4,
                name: 'Verbeterde besluitvorming en probleemoplossing',
                SDGs: [3]
            },
            {
                id: 5,
                name: 'Verbeterde zelfzorg',
                SDGs: [3]
            },
            {
                id: 6,
                name: 'Verbeterde woonvaardigheden',
                SDGs: [3]
            },
            {
                id: 7,
                name: 'Verhoogde mobiliteit',
                SDGs: [3]
            },
            {
                id: 8,
                name: 'Verhoogde digitale geletterdheid',
                SDGs: [8]
            },
            {
                id: 9,
                name: 'Verbeterde daginvulling',
                SDGs: [3]
            },

            {
                id: 10,
                name: 'Verhoogde fysieke zelfredzaamheid',
                SDGs: [3]
            },
            {
                id: 11,
                name: 'Verhoogde psychische zelfredzaamheid',
                SDGs: [3]
            },
            {
                id: 12,
                name: 'Verhoogde cognitieve zelfredzaamheid',
                SDGs: [3]
            },
            {
                id: 13,
                name: 'Verhoogde sociale steun',
                SDGs: [11]
            },
            {
                id: 14,
                name: 'Uitbreiden van professioneel netwerk',
                SDGs: [8]
            },
            {
                id: 15,
                name: 'Verbeterde dagstructuur',
                SDGs: [3]
            }
        ],
    },
    {
        id: 6,
        name: 'Maatschappelijke participatie',
        subeffects: [
            {
                id: 1,
                name: 'Toename van gemeenschapsgerichte initiatieven (zoals vrijwilligerswerk)',
                SDGs: [11]
            },
            {
                id: 2,
                name: 'Verhoogde politieke participatie',
                SDGs: [11]
            },
            {
                id: 3,
                name: 'Verhoogde inclusie',
                SDGs: [11]
            },
            {
                id: 4,
                name: 'Verhoogd gemeenschapsgevoel',
                SDGs: [11]
            },
            {
                id: 5,
                name: 'Verhoogde waardering van cultuur',
                SDGs: [11]
            },
            {
                id: 6,
                name: 'Verhoogde internationale solidariteit',
                SDGs: [11]
            },
            {
                id: 7,
                name: 'Verhoogde burgerlijke betrokkenheid',
                SDGs: [11]
            },
            {
                id: 8,
                name: 'Verhoogde maatschappelijke verantwoordelijkheid',
                SDGs: [11]
            }
        ],
    }


]

// import { useState, useEffect } from 'react'

// const Database = () => {

//     const [database, setDatabase] = useState([])

//     useEffect(() => {
//         fetch('https://us-central1-openmkba.cloudfunctions.net/database')
//         .then(res => {
//             if (!res.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             return res.json(); // Return the result of res.json()
//         })
//         .then(data => {
//             setDatabase(data);
//         })
//         .catch(error => {
//             console.error('There has been a problem with your fetch operation:', error);
//         });
//     }, [])

//     return (
//         database
//     )


// }

export default database