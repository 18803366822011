import { useFirestoreID } from "../../../firebase/useFirestore"
import LiveReportEffectData from "../LiveReportEffectData"
import LiveReportDashboardMedia from "../LiveReportDashboardMedia"

const PrintLiveReportEffects = ({effect, startDate, endDate}) => {

    const effects = useFirestoreID('OutputEffects', effect ? effect : '' )

  return (
    <div className="report-activities-container">
        {effects && effects.map(item => (
            <div className="print-report-activity-item-container" style={{borderBottom: effects.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="print-report-activity-item-description-container">
                    <h3>{item.Effect}</h3>
                    <div id='print-description-content-container'>
                        <p>{item.Description}</p>
                        <LiveReportDashboardMedia parent={item}/>
                    </div>
                </div>
                <div className="print-report-activity-item-graph-container">
                    <h3>Resultaten uit onderzoek</h3>
                    <LiveReportEffectData effectId={item.ID} startDate={startDate} endDate={endDate}/>
                </div>
            </div>
        ))}
    </div>
  )
}

export default PrintLiveReportEffects