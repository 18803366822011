import { useFirestoreGeneral } from '../../firebase/useFirestore'

const LiveReportDashboardMedia = ({parent}) => {

    const media = useFirestoreGeneral('LiveReportMedia', 'Parent', parent.ID)

    const displayMedia = (type, item) => {
      const filetype = type.split('/')[0]
      
      if(filetype === 'image') {
          return <img src={item.Media} alt="" />
      } else if(filetype === 'video') {
          return <video controls><source src={item.Media}/></video>
      } else if(filetype === 'audio') {
          return <audio controls><source src={item.Media}/></audio>
      }
  }

  return (
    <>
        {media && media.map(item => (
            <div className="report-media-dashboard-item-container">
                {displayMedia(item.Type, item)}
            </div>
        ))}
    </>
  )
}

export default LiveReportDashboardMedia