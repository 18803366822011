import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import plusButton from '../../images/icons/plus-icon.png'
import { useHistory } from "react-router-dom";
import useSettings from "../../hooks/Settings";
import { client } from "../../hooks/Client"
import Tooltip from "../../components/common/Tooltip";
import deleteIcon from '../../images/icons/delete-icon.png'
import settingsIcon from '../../images/icons/settings-icon.png'
import { useFirestore } from '../../firebase/useFirestore';
import { db } from '../../firebase/config';
import DraggableTableItems from '../../components/common/DraggableTableItems';
import { useState } from 'react';
import FundName from '../../components/financiers/FundName';

const Funds = () => {
     // State 
     const [hoverIndex, setHoverIndex] = useState(null)
     const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()

    // Firestore
    const funds = useFirestore('CompanyFunds')

    const deleteFund = (e) => {

        const docid = e.target.dataset.docid 

        db.collection('CompanyFunds').doc(docid).delete()

    }

  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <AccountBalanceOutlinedIcon/>
                <h1>Fondsen</h1>
            </div>
            <div className="table-container dashboard-container">
                <div className='list-top-row-container'>
                    <Tooltip content={'Fonds toevoegen'} top='35px'>
                        <img  src={plusButton} alt="" onClick={() => history.push(`/${client}/fundsdatabase`)} />
                    </Tooltip>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                            <th style={{backgroundColor: secundairyColor}}>STATUS</th>
                            <th style={{backgroundColor: secundairyColor}}>DETAILS</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                    </thead>    
                    {funds && funds.map((item, index) => (
                        <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={item}
                            input={
                                <Tooltip content={<FundName id={item.FundID} />} width='80%' right='-220px' top='-35px'>
                                   <FundName id={item.FundID}/>
                                </Tooltip>
                            }
                            parentIndex={index}
                            collection={'Funds'}
                            itemArray={funds}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={item.Name}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                            <td>
                                {item.Status}
                            </td>
                            <td>
                                <img className='table-delete-icon' onClick={() => history.push(`/${client}/funddetails/${item.FundID}`)} src={settingsIcon} alt="" />
                            </td>
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid} onClick={deleteFund} src={deleteIcon} alt="" />
                            </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
            </div> 
        </div>
    </div>
  )
}

export default Funds