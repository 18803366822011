import ArrowLeftIcon from '../../images/icons/arrow-left-icon.png'
import { useHistory } from "react-router-dom";

const BackButton = ({link}) => {

    // Hooks
    const history = useHistory()

    const goBack = () => {

        // history.push(link)

    }

  return (
    <div id='backbutton-container' onClick={goBack()}>
        <img src={ArrowLeftIcon} alt="" />
        <p>Terug</p>
    </div>
  )
}

export default BackButton