import strategyImage from '../../images/impactstrategy.png'
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect, useState } from "react";
import { ImpactstrategySystemMessage } from "../../hooks/impactAI/Prompts"
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"
import SegmentProgressBar from '../../components/common/SegmentProgressBar';
import { useFirestore, useFirestoreOrderBy, useFirestoreTimestamp } from "../../firebase/useFirestore";
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';

const Impactstrategy = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen]
  } = useContext(ImpactAI);

  // Hooks
  const history = useHistory()

  // Firestore
  const sourceData = useFirestore('SourceData')
  const centralProblem = useFirestore('CentralProblem')
  const stakeholders = useFirestoreOrderBy('Stakeholders', 'Position', 'asc')
  const goals = useFirestoreTimestamp("Goals")
  const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')
  const outputs = useFirestoreOrderBy('Outputs', 'Position', 'asc')
  const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc')

  //ImpactAI
  const pageSystemMessage = ImpactstrategySystemMessage()
  const pageStartMessage = `Welkom bij de impactstrategie, ${auth?.ForName}. De impactstrategie is de basis van je impactmanagement.
  Heb je vragen over de impactstrategie?`
  const pageExampleMessages = ['Wat is een impactstrategie?', 'Wat is een Theory of Change?']
  const pageType = 'impactstrategy'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])

  const theoryOfChangeComplete = () => {
    if (centralProblem && centralProblem[0].CentralProblem === '' && stakeholders && stakeholders.length === 0 && goals && goals[0].Title === '' && activities && activities.length === 0 && outputs && outputs.length === 0 && effects && effects.length === 0) {
      return false
    } else {
      return true
    }
  }



  const segmentSteps = [
    {
      name: 'Organisatie omschrijving',
      info: `Omschrijf je organisatie kort en krachtig. 
      Deze informatie wordt gebruikt door impactAI om je te helpen bij het formuleren van je impactstrategie`,
      completed: sourceData && sourceData[0].Summary === '' ? false : true,
      url: 'organisationcontext',
      icon: ContentPasteOutlinedIcon
    },
    {
      name: 'Maatschappelijk probleem',
      info: 'Formuleer het maatschappelijk probleem dat je wilt oplossen',
      completed: centralProblem && centralProblem[0].CentralProblem === '' ? false : true,
      url: 'problemanalysis'
    },
    {
      name: 'Doelgroepen',
      info: 'Formuleer wie je doelgroep(en) is/zijn',
      completed: stakeholders && stakeholders.length === 0 ? false : true,
      url: 'stakeholders'
    },  
    {
      name: 'Impactdoel',
      info: 'Formulier het impactdoel dat je wilt bereiken',
      completed: goals && goals[0].Title === '' ? false : true,
      url: 'goals'
    },
    {
      name: 'Activiteiten',
      info: 'Formuleer welke activiteiten je uitvoert om het impactdoel te bereiken',
      completed: activities && activities.length === 0 ? false : true,
      url: 'activities'
    },
    {
      name: 'Outputs',
      info: 'Formuleer welke outputs (kwantitatieve impact) je gaat bijhouden',
      completed: outputs && outputs.length === 0 ? false : true,
      url: 'outputs'
    },
    {
      name: 'Effecten',
      info: 'Formuleer welke effecten (kwalitatieve impact) je wilt bereiken voor je doelgroep',
      completed: effects && effects.length === 0 ? false : true,
      url: 'effects'
    },
    {
      name: 'Theory of Change',
      info: 'Hier vind je de visuele weergave van je impactstrategie',
      completed: theoryOfChangeComplete(),
      url: 'theoryofchange'
    }
  ]

  return (
    <div>
      <div className='section-explainer-header-image-container'>
        <img src={strategyImage} alt="" />
      </div>
      <div className='section-explainer-text-container'>
        <h1>Impactstrategie</h1>
        <div>
          <p>Welkom bij de impactstrategie (ook wel de Theory of Change genoemd). Dit is de eerste stap in 
            het impactmanagementproces en tevens de basis voor het meetplan en de communicatie.</p>
          <p>
            De impactstrategie is een visuele weergave van de impact die je wilt maken. Het is een reis die begint met het maatschappelijke probleem dat je wilt oplossen.
            Vervolgens doorloop je de stappen die nodig zijn om tot de gewenste maatschappelijke effecten (impact) te komen.
          </p>
        </div>
        <SegmentProgressBar steps={segmentSteps} />
        {/* <div id='impact-guide-container'>
          <div className="home-start-image-container">
            <VerifiedUserOutlinedIcon />
          </div>
          <div className="home-section-explainer-container">
            <h3>Zeker weten dat je de juiste keuzes maakt?</h3>
            <p>Laat je impactstrategie checken door een 
            impactspecialist van Alexander Impact en ontvang een digitaal kwaliteitskeurmerk op je live-rapportage.</p>
          </div>
          <div className="home-cta-container" onClick={() => history.push(`/${client}/qualitycheck`)}>
              <p>Meer informatie</p>
            </div>
        </div> */}
      </div>
      <Breadcrumb
      section={'Impactstrategie'}
      previousPage={'Home'}
      previousPageUrl={'home'}
      nextPage={'Organisatie context'}
      nextPageUrl={'organisationcontext'}
      counter={0}
      totalSteps={8}
      />
    </div>
  )
}

export default Impactstrategy