import { useFirestoreUsersApproved, useFirestore, useFirestoreNoCompagny,} from "../../firebase/useFirestore";
import { useHistory } from "react-router-dom";
import { client } from '../../hooks/Client';
import { db, auth } from "../../firebase/config";
import { useState, useContext, useEffect } from "react";
import { Auth } from '../../StateManagment/Auth';
import deleteIcon from '../../images/icons/delete-icon.png'
import ScrollToTop from "../../hooks/ScrollToTop";
import ButtonClicked from "../../hooks/ButtonClicked";
import Hostname from '../../hooks/Hostname'
import firebase from "firebase";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { SavedIcon } from "../../StateManagment/SavedIcon";

const Members = () => {
    // Context
    const [authO] = useContext(Auth)
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [showDeleteButton, setShowDeleteButton] = useState("none")
    const [email, setEmail] = useState('')
    const [communityName, setCommunityName] = useState('')
    const [userDocid, setUserDocid] = useState('')

    // Hooks
    const history = useHistory()
    const host = Hostname()
    ScrollToTop()

    // Firestore
    const docs = useFirestoreUsersApproved()
    const compagnies = useFirestore("CompagnyMeta")
    const admins = useFirestore('Admins')
    const users = useFirestoreNoCompagny('Users', 'Email', email)
    
    const updateRoute = (e) => {

        const memberID = e.target.id

        history.push(`/${client}/PublicProfile/${memberID}`)
    }

    useEffect(() => {
        compagnies && compagnies.forEach(compagny => {
            setCommunityName(compagny.CommunityName)
        })
 
    }, [compagnies])

    useEffect(() => {
        const showDeleteButtonForAdmin = () => {
            admins && admins.forEach(admin => {
                if(admin.UserID === authO?.ID){
                    setShowDeleteButton("block")
                }
            })
        }
        showDeleteButtonForAdmin()
    }, [admins])

    useEffect(() => {
        users && users.forEach(item => {
            const docid = item.docid 

            setUserDocid(docid)
        })
    },[users])

    const emailHandler = (e) => {
        const value = e.target.value 

        setEmail(value)
    }


    const inviteMemberHandler = (e) => {

        if(users.length > 0){
            console.log('bestaat al')
            updateExistingMember()
            sendMailToExistingMember()
        } else if(users.length === 0){
            console.log('bestaat nog niet')
            sendMailToNewMember()
        } 

        ButtonClicked(e, 'Uitnodiging verstuurd')

    }

    const updateExistingMember = async () => {

        const updateUserAccountWhenInvited = firebase.functions().httpsCallable('updateUserAccountWhenInvited')  

        const result = await updateUserAccountWhenInvited({uid: userDocid, client: client}) 

        if(result.data === 'success') {
           console.log('success')
        } else if(result.data === 'error') {
            alert(`Er is iets mis gegaan, neem contact op met Deccos via info@deccos.nl`)
        }

    }

    const sendMailToExistingMember = () => {

        db.collection("Email").doc().set({
            to: email,
            cc: "info@deccos.nl",
            from: "info@deccos.nl",
            replyTo: `${host.Name}`,
            message: {
            subject: `${communityName} nodigt je uit om lid te worden van de ${host.Name} ${host.Text} omgeving`,
            html: `
                ${communityName} nodigt je uit om lid te worden van de ${host.Name} ${host.Text} omgeving. <br><br>

                <a href='https://${host.Hostname}/${client}'>Klik hier</a> om naar de omgeving van ${communityName} te gaan.<br><br>

                Met vriendelijke groet, <br><br>

                Team ${host.Name} <br><br>
                
                `,
            Gebruikersnaam: `${client}`,
            Emailadres: email,
            Type: "Invite member"
                }     
        })
    }

    const sendMailToNewMember = () => {

        console.log(email)

        db.collection("Email").doc().set({
            to: email,
            cc: "info@deccos.nl",
            from: "info@deccos.nl",
            replyTo: `${host.Name}`,
            message: {
            subject: `${communityName} nodigt je uit om lid te worden van de ${host.Name} ${host.Text} omgeving`,
            html: `
                ${communityName} nodigt je uit om lid te worden van de ${host.Name} ${host.Text} omgeving. <br><br>

                <a href='https://${host.Hostname}/${client}/RegisterInvitation'>Klik hier</a> om een account aan te maken.<br><br>

                Met vriendelijke groet, <br><br>

                Team ${host.Name} <br><br>
                
                `,
            Gebruikersnaam: `${client}`,
            Emailadres: email,
            Type: "Invite member"
                }     
        })
    }

    const deleteUser = (e) => {

        const docid = e.target.dataset.id

        db.collection('Users')
        .doc(docid)
        .update({
            Compagny: firebase.firestore.FieldValue.delete(client),
        })
        .then(() => {
            setSaved(true)
        })
    }

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <GroupsOutlinedIcon/>
                    <h1>Team</h1>
                </div>
                <div className="profile-inner-container">
                    <h2>Teamlid uitnodigen</h2>
                    <p>Emailadres</p>
                    <input type="text" placeholder='Noteer hier het emailadres' onChange={emailHandler} />
                    <div className='button-container button-container-invite-members'>
                        <button onClick={inviteMemberHandler}>Uitnodigen</button>
                    </div>
                    <h2>Teamleden</h2>
                    {docs && docs.map(doc => (
                    <div id="members-container" key={doc.ID}>
                        <img src={doc.Photo} alt="" id={doc.ID} onClick={updateRoute} />
                        <h3 id={doc.ID} onClick={updateRoute}>{doc.UserName}</h3>
                        <img src={deleteIcon} alt="" className="userrole-users-delete-button" data-id={doc.docid} onClick={deleteUser} />
                    </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Members