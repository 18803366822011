import HomeMultipleBarChart from "../Visualisations/HomeMultipleBarChart"
import ScaleMomentVisualisation from "../Visualisations/ScaleMomentVisualisation"
import MultipleMultilineGraphResponsive from "../Visualisations/MultipleMultilineGraphResponsive"
import DevelopmentMultilineGraphResponsive from "../Visualisations/DevelopmentMultilineGraphResponsive"
import ScaleDevelopmentResultsGraphResponsive from "../Visualisations/ScaleDevelopmentResultsGraphResponsive"
import informationIcon from '../../images/icons/information-icon.png'
import Tooltip from "../common/Tooltip"
import { useEffect, useState } from "react"
import { db } from "../../firebase/config"
import ParagraphBarChartResponsive from "../Visualisations/ParagraphBarChartResponsive"
import OutputResultsGraphResponsive from "../Visualisations/OutputResultsGraphResponsive"
import QuestionMeta from "../dashboard/QuestionMeta"
import OutputMeta from '../outputs/OutputMeta'
import { useFirestoreGeneral } from "../../firebase/useFirestore"
import OpenQuestionSlider from "../liveReport/OpenQuestionSlider"
import OptionsIcon from '../../images/icons/options-icon-2.png'

const IndicatorData = ({indicator, startDate, endDate, docid}) => {
    // State
    const [title, setTitle] = useState('')
    const [researchId, setResearchId] = useState('')
    const [numberOfMeasureMoments, setNumberOfMeasureMoments] = useState('')
    const [type, setType] = useState('')
    const [momentId, setMomentId] = useState('')
    const [showOptions, setShowOptions] = useState(false)

    // Firestore 
    const research = useFirestoreGeneral('Research', 'QuestionnaireID', indicator.QuestionnaireID ? indicator.QuestionnaireID : '')
    const measureMoments = useFirestoreGeneral('MeasureMoments', 'ResearchID', researchId ? researchId : '')

    // Function to format date
    function formatDate(date) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
      }

    // Get research id
    useEffect(() => {
        research && research.map(doc => {
            setResearchId(doc.ID)
        })
    }, [research])

    // Get number of measure moments 
    useEffect(() => {
        if(measureMoments){
            setNumberOfMeasureMoments(measureMoments.length)
        }
    }, [measureMoments])

    // Get type of moment
    useEffect(() => {
        if(numberOfMeasureMoments === 1){
            setType(`${indicator.Type}-moment`)
            setMomentId(measureMoments[0].ID)
        } else if(numberOfMeasureMoments > 1){
            setType(`${indicator.Type}-development`)
        } else {
            setType('output')
        }
    }, [numberOfMeasureMoments])

    // Function to update indicator visibility
    const updateIndicatorVisibility = async (e) => {

        const type = e.target.dataset.type 

        await db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            LiveReportHidden: type === 'on' ? false : true
        })
    }

      // Function to set tooltip content
    const tooltipContent = (indicator, type) => {


        const typeSetting = () => {
            if(measureMoments.length === 1){
                return 'Meetmoment'
            } else if(measureMoments.length > 1){
                return 'Ontwikkeling'
            } else{
                return 'Outputresultaat'
            }
        }
        
        // Function to get research title
        const researchTitle = async () => {

            const snapshot = await db.collection('Research')
            .where('ID', '==', indicator.ResearchID ? indicator.ResearchID : "")
            .get()
            
            let title = ''
            let id = ''
        
            snapshot.docs.map(doc => {
                title = doc.data().Title
                id = doc.ID
            })
        
            setTitle(title)
            setResearchId(id)
          }
        
          researchTitle()
        
    return(
        <div className="home-kpi-tooltip-container">
            <p><b>Onderzoek</b></p>
            <p>{title}</p>
            <p><b>Type</b></p>
            <p>{typeSetting()}</p>
            <p><b>Periode</b></p>
            <p>{startDate && formatDate(startDate)} - {endDate && formatDate(endDate)}</p>
        </div>
        )
    }

    // Function to render chart type
    const chartType = () => {
         if(type === 'multiple-one-moment'){
            return (
                <HomeMultipleBarChart moment={momentId} field={indicator.ID} startDate={startDate} endDate={endDate}/>
            )
        }if(type === 'multiple-multiple-moment'){
            return (
                <HomeMultipleBarChart moment={momentId} field={indicator.ID} startDate={startDate} endDate={endDate}/>
            )
        } else if(type === 'scale-moment'){
            return (
                <ScaleMomentVisualisation moment={momentId} field={indicator.ID} startDate={startDate} endDate={endDate}/>
            )
        } else if(type === 'paragraph-moment'){
            return (
                <>
                    <ParagraphBarChartResponsive  moment={momentId} field={indicator.ID} startDate={startDate} endDate={endDate}/>
                    <OpenQuestionSlider moment={momentId} field={indicator.ID} startDate={startDate} endDate={endDate}/>
                </>
            )
        } else if(type === 'multiple-one-development'){
            return (
                <MultipleMultilineGraphResponsive field={indicator.ID} researchID={researchId} startDate={startDate} endDate={endDate}/>
            )
        } else if(type === 'multiple-multiple-development'){
            return (
                <MultipleMultilineGraphResponsive field={indicator.ID} researchID={researchId} startDate={startDate} endDate={endDate}/>
            )
        }else if(type === 'scale-development'){
            return (
                <ScaleDevelopmentResultsGraphResponsive field={indicator.ID} researchID={researchId} startDate={startDate} endDate={endDate}/>
            )
        } else if(type === 'paragraph-development'){
            return (
                <DevelopmentMultilineGraphResponsive field={indicator.ID} researchID={researchId} startDate={startDate} endDate={endDate}/>
            )
        } 
        else if(type === 'output'){
            return (
                <OutputResultsGraphResponsive output={indicator.ID} color={indicator.Color} startDate={startDate} endDate={endDate}/>
            )
        } 
        else {
            return (
                <p>Unknown type</p>
            )
        }
    }

    return (
        <div className="home-kpi-chart-container">
            <div className="home-kpi-description-container">
                <QuestionMeta field={indicator.ID}/>
                <p>{indicator.Title}</p>
                <Tooltip content={tooltipContent(indicator, indicator.Type)} width={'50px'} top='30px' left='-100px'>
                    <img src={informationIcon} alt=""/>
                </Tooltip>
                <div id='show-options-container'>
                    <Tooltip content={'Opties'} width={'50px'} top='30px'>
                        <img id='options-icon' src={OptionsIcon} alt="" onClick={() => setShowOptions(!showOptions)}/>
                    </Tooltip>
                    {showOptions &&
                        <div className="home-kpi-options-container">
                            <div className="home-kpi-option-container">
                                <p><b>Zichtbaarheid</b></p>
                                <p 
                                className="home-kpi-indicator-visibility-option" 
                                style={{color: indicator.LiveReportHidden ? 'black' : 'green'}} 
                                data-type={'on'} 
                                onClick={updateIndicatorVisibility}>
                                    Aan
                                </p>
                                <p 
                                className="home-kpi-indicator-visibility-option" 
                                style={{color: indicator.LiveReportHidden ? 'red' : 'black'}} 
                                data-type={'off'} 
                                onClick={updateIndicatorVisibility}>
                                    Uit
                                </p>
                            </div>
                        </div>
                    }
                </div>
                
            </div>
            
            {chartType()} 
        </div>
    )
}

export default IndicatorData