import {useFirestore } from "../../firebase/useFirestore"
import robotImage from "../../images/icons/robot-icon.png"
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import Tooltip from "../../components/common/Tooltip";
import { db } from "../../firebase/config.js"
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext, useEffect } from "react";
import { ProblemSystemMessage, ProblemToolCallContent, ProblemSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import Breadcrumb from "../../components/common/Breadcrumb";

const ProblemAnalyse = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
} = useContext(ImpactAI);

    // Hooks
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore 
    const centralProblem = useFirestore('CentralProblem')

     //ImpactAI
     const pageSystemMessage = ProblemSystemMessage()
     const pageToolCallContent = ProblemToolCallContent()
     const pageSaveMessage = ProblemSaveMessage()
     const pageStartMessage = `Welkom bij het maatschappelijk probleem, ${auth?.ForName}. Hier gaan we beschrijven welk maatschappelijk probleem jullie willen oplossen.
     Als we een beeld hebben van het probleem, kunnen we ook beter bepalen wat de maatschappelijke impact van jullie organisatie is.
     Mag ik een voorstel doen?`
     const pageCollection = 'CentralProblem'
     const pageField = 'CentralProblem'
     const pageParentId = ''
     const pageType = 'problem'
 
     useEffect(() => {
         setType(pageType)
         setSystemMessage(pageSystemMessage)
         setStartMessage(pageStartMessage)
         setToolCallContent(pageToolCallContent)
         setSaveMessage(pageSaveMessage)
         setCollection(pageCollection)
         setField(pageField)
         setParentId(pageParentId)
         setExampleMessages([])
     }, [auth])

     // Set docid
     useEffect(() => {
         if(centralProblem) {
             setDocid(centralProblem[0].docid)
         }
     }, [centralProblem])

    // Handle change
    const centralProblemHandler = (e) => {
        const centralProblem = e.target.value 
        const docid = e.target.dataset.docid

        db.collection('CentralProblem')
        .doc(docid)
        .update({
            CentralProblem: centralProblem
        })
        .then(() => {
           setSaved('flex')
        })
    }

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, graag!')
    }
    
  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <ExtensionOutlinedIcon/>
                <h1>Maatschappelijk probleem</h1>
            </div>
            <div className="table-container dashboard-container">
                <div className='list-top-row-container'>
                    <Tooltip content={'Kan ik je helpen?'} width='80%' top='-10px' left='30px'>
                        <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                    </Tooltip>
                </div>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>MAATSCHAPPELIJK PROBLEEM</th>
                    </tr>
                    {centralProblem && centralProblem.map(item => (
                        <tr key={item.ID}>
                            <td className="single-row-table">
                                <textarea data-docid={item.docid} defaultValue={item.CentralProblem} placeholder='Noteer hier het centrale probleem' onChange={centralProblemHandler} />
                            </td>
                        </tr>  
                    ))}
                </table>
            </div>
            <Breadcrumb
            section={'Impactstrategie'}
            previousPage={'Organisatie context'}
            previousPageUrl={'OrganisationContext'}
            nextPage={'Doelgroepen'}
            nextPageUrl={'Stakeholders'}
            counter={2}
            totalSteps={8}
            />
        </div>
    </div>
  )
}

export default ProblemAnalyse