import {useFirestoreOrderBy} from "../../firebase/useFirestore"
import MenuStatus from "../../hooks/MenuStatus";
import { useHistory } from "react-router-dom";
import { client } from '../../hooks/Client';
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import robotImage from "../../images/icons/robot-icon.png"
import Tooltip from "../../components/common/Tooltip";
import Modal from 'react-modal';
import { StakeholdersSystemMessage, StakeholdersToolCallContent, StakeholdersSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import { useContext, useEffect } from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { db } from "../../firebase/config.js"
import { v4 as uuid } from 'uuid';
import { useState } from "react";
import deleteIcon from '../../images/icons/delete-icon.png'
import DraggableTableItems from "../../components/common/DraggableTableItems";
import plusButton from '../../images/icons/plus-icon.png'
import { timestamp } from "../../firebase/config";
import DeleteModal from "../../components/common/DeleteModal";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import spinner from '../../images/spinner-ripple.svg'
import firebase from "firebase";
import targetgroupCategories from "../../hooks/impactAI/TargetgroupCategories";

const Stakeholders = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [openAddTargetgroupModal, setOpenAddTargetgroupModal] = useState(false)
    const [targetgroupName, setTargetgroupName] = useState('')
    const [targetgroupCategorie, setTargetgroupCategorie] = useState('')
    const [loading, setLoading] = useState(false)

    // Hooks
    const menuState = MenuStatus()
    ScrollToTop()
    const history = useHistory()
    const secundairyColor = useSettings().SecundairyColor
    Modal.setAppElement('#root');
    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    // Firestore
    const stakeholders = useFirestoreOrderBy('Stakeholders', 'Position', 'asc')
    const researches = useFirestoreOrderBy('Research', 'Position', 'asc')
    const questionnaires = useFirestoreOrderBy('Questionnaires', 'Position', 'asc')
    const reports = useFirestoreOrderBy('LiveReports', 'Position', 'asc')

    //ImpactAI
    const pageSystemMessage = StakeholdersSystemMessage()
    const pageToolCallContent = StakeholdersToolCallContent()
    const pageSaveMessage = StakeholdersSaveMessage()
    const pageStartMessage = `Hoi, ${auth?.ForName}. In deze stap gaan we bepalen wie jullie doelgroep is.
    Zal ik een voorstel doen?`
    const pageExampleMessages = []
    const pageCollection = 'Stakeholders'
    const pageField = 'Organisation'
    const pageParentId = ''
    const pageType = 'stakeholder'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth])

    // Set delete modal meta to state and open the modal
    const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename

        setDeleteDocid(docid)
        setDeleteName(deleteName)

        setDeleteModal(!deleteModal)
    }

    // Delete a stakeholder
    const deleteStakeholder = (e) => {

        db.collection('Stakeholders')
        .doc(deleteDocid)
        .delete()

    }

    // Add a new stakeholder 
    const addStakeholder = (e) => {

        const targetgroupID = uuid()

        db.collection('Stakeholders')
        .doc()
        .set({
            Organisation: targetgroupName,
            Categorie: targetgroupCategorie,
            ID: targetgroupID,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Position: stakeholders.length + 1
        })
        .then(() => {
            setOpenAddTargetgroupModal(false)
            setSaved(true)
        })
    }

    // Edit the name of a stakeholder
    const editNameHandler = (e) => {

        const docid = e.target.dataset.docid
        const name = e.target.value

        db.collection('Stakeholders')
        .doc(docid)
        .update({
            Organisation: name,
        })
        .then(() => {
            setSaved(true)
        })
    }

    // Set the selected name to the state
    const nameHandler = (e) => {

        const name = e.target.value

        setTargetgroupName(name)
    }

    // Set the selected categorie to the state
    const categorieHandler = (e) => {
        const categorie = e.target.options[e.target.selectedIndex].innerText

        setTargetgroupCategorie(categorie)
    }

    // Edit the categorie of a stakeholder
    const editCategoryHandler = (e) => {

        const docid = e.target.dataset.docid
        const categorie = e.target.options[e.target.selectedIndex].value

        db.collection('Stakeholders')
        .doc(docid)
        .update({
            Categorie: categorie,
        })
        .then(() => {
            setSaved(true)
        })
    }

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, zeker!')
    }

    return (
        <div className="main">
            <div className="main-container" style={{display: menuState}}>
                <div className="page-header">
                    <PersonOutlineOutlinedIcon />
                    <h1>Doelgroepen</h1>
                </div>
                <div className="table-container dashboard-container">
                    <div className='list-top-row-container'>
                        <Tooltip content='Voeg een doelgroep toe' width={'30px'} left='-20px'>
                            <img src={plusButton} onClick={() => setOpenAddTargetgroupModal(true)} alt="" />
                        </Tooltip>
                        <Tooltip content={'Kan ik je helpen?'} width={'30px'} top='-10px' left='30px'>
                            <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                        </Tooltip>
                    </div>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>DOELGROEP</th>
                            <th style={{backgroundColor: secundairyColor}}>CATEGORIE</th>
                            <th style={{backgroundColor: secundairyColor}}>DELETE</th>
                        </tr>
                        {stakeholders && stakeholders.map( (stakeholder, index) => (
                                <>
                                {placeholderIndex === index && (
                                    <tr className="placeholder-row">
                                        <td style={{backgroundColor: secundairyColor}}></td>
                                    </tr>
                                )}
                                <DraggableTableItems 
                                item={stakeholder}
                                input={
                                    <Tooltip content={stakeholder.Organisation} width='80%' top='-60px'>
                                        <input type="text" data-docid={stakeholder.docid} defaultValue={stakeholder.Organisation} placeholder='Organisatie' onChange={editNameHandler} />
                                    </Tooltip>
                                }
                                parentIndex={index}
                                collection={'Stakeholders'}
                                itemArray={stakeholders}
                                setHoverIndex={setHoverIndex}
                                hoverIndex={hoverIndex}
                                title={stakeholder.Categorie}
                                setPlaceholderIndex={setPlaceholderIndex}
                                >
                                    <td>
                                        <select name="categorie" id="" data-docid={stakeholder.docid} defaultValue={stakeholder.Categorie} onChange={editCategoryHandler}>
                                            <option value="">-- Selecteer een categorie --</option>
                                            {targetgroupCategories.map((categorie) => (
                                                <option value={categorie}>{categorie}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <img className='table-delete-icon' data-docid={stakeholder.docid} data-deletename={stakeholder.Organisation} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                                    </td>
                                </DraggableTableItems>
                            </>
                        ))}
                    </table>
                </div>
                <Breadcrumb
                section={'Impactstrategie'}
                previousPage={'Maatschappelijk probleem'}
                previousPageUrl={'problemanalysis'}
                nextPage={'Impactdoel'}
                nextPageUrl={'Goals'}
                counter={3}
                totalSteps={8}
                />
                <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteStakeholder} deleteName={deleteName} />
                <Modal
                isOpen={openAddTargetgroupModal}
                onRequestClose={openAddTargetgroupModal}
                style={modalStyles}
                contentLabel="Doelgroep toevoegen"
                >
                    <div>
                        <h1>Doelgroep toevoegen</h1>
                        <div>
                            <p>Selecteer een categorie</p>
                            <select name="categorie" id="" onChange={categorieHandler}>
                                <option value="">-- Selecteer een categorie --</option>
                                {targetgroupCategories.map((categorie) => (
                                    <option value={categorie}>{categorie}</option>
                                ))}
                            </select>
                            <p>Geef de doelgroep een naam</p>
                            <input type="text" placeholder='Schrijf hier de naam van de doelgroep' onChange={nameHandler} />
                            
                        </div>
                        <div id='modal-button-container'>
                            <button id='modal-cancel-button'onClick={() => setOpenAddTargetgroupModal(false)} style={{display: loading ? 'none' : 'block'}}>Annuleren</button>
                            <button id='modal-save-button' onClick={addStakeholder} style={{display: loading ? 'none' : 'block'}}>Opslaan</button>
                            <img src={spinner} alt="" style={{display: loading ? 'block' : 'none'}}/>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default Stakeholders
