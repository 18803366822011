import { useFirestoreGeneral } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext, useEffect, useState } from "react";
import clearIcon from '../../images/icons/clear-icon.png'
import Tooltip from '../../components/common/Tooltip'
import firebase from 'firebase/app'

const Presence = ({ personaNavigation, item }) => {
  // Context
  const [saved, setSaved] = useContext(SavedIcon)

  // State
  const [docid, setDocid] = useState(null)
  const [personaDocid, setPersonaDocid] = useState(item.docid ? item.docid : '')

  // Firestore
  const presence = useFirestoreGeneral('PersonaPresence', 'PersonaID', item.ID ? item.ID : '')

  // Functions
  useEffect(() => {
    if (presence.length > 0) {
      setDocid(presence[0].docid)
    }
  }, [presence])

  const dayNameSettings = (day) => {

    switch (day) {
      case 'monday':
        return 'Maandag'
      case 'tuesday':
        return 'Dinsdag'
      case 'wednesday':
        return 'Woensdag'
      case 'thursday':
        return 'Donderdag'
      case 'friday':
        return 'Vrijdag'
      case 'saturday':
        return 'Zaterdag'
      case 'sunday':
        return 'Zondag'
      default:
        return 'Error'
    }
  }

  // HANDLERS
  const timeHandler = (e) => {
    const day = e.target.dataset.day
    const type = e.target.dataset.type
    const value = e.target.value

    if (docid) {
      db.collection('PersonaPresence')
        .doc(docid)
        .update({
          [`PresenceTimes.${day}.${type}`]: value,
          Timestamp: timestamp
        })
        .then(() => {
          setSaved(true)
        })
    } else {
      db.collection('PersonaPresence')
        .add({
          Compagny: client,
          CompagnyID: client,
          Timestamp: timestamp,
          PersonaID: item.ID,
          PersonaDocid: personaDocid,
          PresenceTimes: {
            [day]: { [type]: value }
          }
        })
        .then(docRef => {
          setDocid(docRef.id)
          setSaved(true)
        })
    }
  }

  const startTime = (day) => {
    if (presence.length > 0) {
      return presence[0].PresenceTimes?.[day]?.start || ''
    }
  }

  const endTime = (day) => {
    if (presence.length > 0) {
      return presence[0].PresenceTimes?.[day]?.end || ''
    }
  }

  const clearHandler = (e) => {
    const day = e.target.dataset.day
    if (docid) {
      db.collection('PersonaPresence')
        .doc(docid)
        .update({
          [`PresenceTimes.${day}`]: { start: '', end: '' }
        })
    }
  }

  return (
    <div style={{ display: personaNavigation === 'presence' ? 'block' : 'none' }}>
      <h2>Aanwezigheid</h2>
      <div className='table-container dashboard-container'>
        <h2>Rooster</h2>
        <div>
          {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
            <div key={day} id='presence-day-container'>
              <p id='presence-day-weekday'>{dayNameSettings(day)}</p>
              <div className='presence-time-container'>
                <input type="time" defaultValue={startTime(day)} data-day={day} data-type='start' onChange={timeHandler} />
              </div>
              <div className='presence-time-container'>
                <input type="time" defaultValue={endTime(day)} data-day={day} data-type='end' onChange={timeHandler} />
              </div>
              <Tooltip content='Verwijder tijden' width={'30px'}>
                <img src={clearIcon} alt="" data-day={day} onClick={clearHandler} />
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Presence