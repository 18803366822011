import communicateImage from '../../images/communicate.png'
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { CommunicationSystemMessage } from "../../hooks/impactAI/Prompts"
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';

const Communication = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen]
  } = useContext(ImpactAI);

  // Hooks
  const history = useHistory()

  //ImpactAI
  const pageSystemMessage = CommunicationSystemMessage()
  const pageStartMessage = `Welkom bij Communiceren, ${auth?.ForName}. Hier kun je gerealiseerde en onderbouwde impact communiceren via live-rapportages.
  Kan ik je ergens mee helpen?`
  const pageExampleMessages = ['Wat zijn live-rapportages?']
  const pageType = 'communication'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])


  return (
    <div>
      <div className='section-explainer-header-image-container'>
        <img src={communicateImage} alt="" />
      </div>
      <div className='section-explainer-text-container'>
        <h1>Communiceren</h1>
        <div>
          <p>Welkom bij Communiceren, {auth?.ForName}. Hier ga je je gerealiseerde en onderbouwde impact communiceren via live-rapportages.</p>
        </div>
        <div id='impact-guide-container'>
          <div className="home-start-image-container">
            <VerifiedUserOutlinedIcon />
          </div>
          <div className="home-section-explainer-container">
            <h3>Live-rapportage op je website?</h3>
            <p>Wist je dat het mogeiljk is om je live-rapportage(s) rechtstreeks op je eigen website te tonen?</p>
          </div>
          <div className="home-cta-container" onClick={() => history.push(`/${client}/qualitycheck`)}>
              <p>Meer informatie</p>
            </div>
        </div>

      </div>
      <Breadcrumb
      section={'Communiceren'}
      previousPage={'Home'}
      previousPageUrl={'home'}
      nextPage={'Live-rapportages'}
      nextPageUrl={'livereports'}
      counter={0}
      totalSteps={1}
      />
    </div>
  )
}

export default Communication