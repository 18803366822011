import MenuStatus from "../../hooks/MenuStatus";
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import HelpdeskBanner from "../../images/helpdesk.png";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useContext } from "react";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { useEffect } from "react";
import { HelpdeskSystemMessage } from "../../hooks/impactAI/Prompts";
import { Auth } from "../../StateManagment/Auth";

const Helpdesk = () => {
  // Context
  const [auth] = useContext(Auth);
  const {
    systemMessage: [systemMessage, setSystemMessage],
    startMessage: [startMessage, setStartMessage],
    exampleMessages: [exampleMessages, setExampleMessages],
    chatOpen: [chatOpen, setChatOpen],
    type: [type, setType],
    hightlighted: [hightlighted, setHightlighted],
    hightlightText: [hightlightText, setHightlightText],
} = useContext(ImpactAI);

  // Hooks
  const menuState = MenuStatus();
  ScrollToTop();
  const secundairyColor = useSettings().SecundairyColor;

  // ImpactAI
   const pageSytemMessage = HelpdeskSystemMessage()
   const pageStartMessage = `Hoi ${auth?.ForName}, welkom op de helpdesk pagina. Kan ik iets voor je betekenen?`
   const pageExampleMessages = []

   useEffect(() => {
       setSystemMessage(pageSytemMessage)
       setStartMessage(pageStartMessage)
       setExampleMessages(pageExampleMessages)
       setType('helpdesk')
   }, [auth])

   // Reset hightlights
  useEffect(() => {
    setHightlighted(false)
    setHightlightText('')
  },[])

  return (
    <div className="main">
      <div className="main-container" style={{ display: menuState }}>
        <div className="page-header">
          <SupportAgentOutlinedIcon />
          <h1>Helpdesk</h1>
        </div>
        <div className="table-container dashboard-container">
          <h2>Kom je er niet uit?</h2>
          <div id="helpdesk-container">
            <div className="helpdesk-item-container">
              <p>
                <b>Neem contact op met de helpdesk.</b>
              </p>
              <p>
                De helpdesk is bereikbaar op werkdagen van 9:00 tot 17:00 uur.
              </p>
              <div className="helpdesk-contact-item-container">
                <EmailOutlinedIcon />
                <p>info@deccos.nl</p>
              </div>
              <div className="helpdesk-contact-item-container">
                <LocalPhoneOutlinedIcon />
                <p>06 244 098 43</p>
              </div>
              <div className="helpdesk-contact-item-container">
                <ChatBubbleOutlineOutlinedIcon />
                <p>06 244 098 43</p>
              </div>
            </div>
            <div className="helpdesk-item-container helpdesk-banner-container">
              <img id="helpdesk-banner" src={HelpdeskBanner} alt="" />
            </div>
          </div>
        </div>
        {/* <div className="table-container dashboard-container">
          <h2>Bekijk de rondleiding</h2>
          <iframe width="100%" height="515" src="https://www.youtube.com/embed/ltD2HtCTLdI?si=eR5XKh_9Oyum92pw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div> */}
      </div>
    </div>
  );
};

export default Helpdesk;
