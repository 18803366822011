import HomeMultipleBarChart from "../Visualisations/HomeMultipleBarChart"
import ScaleMomentVisualisation from "../Visualisations/ScaleMomentVisualisation"
import MultipleMultilineGraphResponsive from "../Visualisations/MultipleMultilineGraphResponsive"
import DevelopmentMultilineGraphResponsive from "../Visualisations/DevelopmentMultilineGraphResponsive"
import ScaleDevelopmentResultsGraphResponsive from "../Visualisations/ScaleDevelopmentResultsGraphResponsive"
import informationIcon from '../../images/icons/information-icon.png'
import Tooltip from "../common/Tooltip"
import { useEffect, useState } from "react"
import { db } from "../../firebase/config"
import ParagraphBarChartResponsive from "../Visualisations/ParagraphBarChartResponsive"
import OutputResultsGraphResponsive from "../Visualisations/OutputResultsGraphResponsive"
import QuestionMeta from "../dashboard/QuestionMeta"
import OutputMeta from '../outputs/OutputMeta'
import { useFirestoreGeneral } from "../../firebase/useFirestore"
import OpenQuestionSlider from "../liveReport/OpenQuestionSlider"

const ResearchData = ({fieldType, fieldId, researchId, startDate, endDate}) => {
  // State
  const [title, setTitle] = useState('')
  const [numberOfMeasureMoments, setNumberOfMeasureMoments] = useState('')
  const [type, setType] = useState('')
  const [momentId, setMomentId] = useState('')

  // Firestore 
  const measureMoments = useFirestoreGeneral('MeasureMoments', 'ResearchID', researchId ? researchId : '')

  // Function to format date
  function formatDate(date) {
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }

  // Get number of measure moments 
  useEffect(() => {
      if(measureMoments){
          setNumberOfMeasureMoments(measureMoments.length)
      }
  }, [measureMoments])

  // Get type of moment
  useEffect(() => {
      if(numberOfMeasureMoments === 1){
          setType(`${fieldType}-moment`)
          setMomentId(measureMoments[0].ID)
      } else if(numberOfMeasureMoments > 1){
          setType(`${fieldType}-development`)
      } else {
          setType('output')
      }
  }, [numberOfMeasureMoments])


    // Function to set tooltip content
  const tooltipContent = () => {


      const typeSetting = () => {
          if(measureMoments.length === 1){
              return 'Meetmoment'
          } else if(measureMoments.length > 1){
              return 'Ontwikkeling'
          } else{
              return 'Outputresultaat'
          }
      }
      
      // Function to get research title
      const researchTitle = async () => {

          const snapshot = await db.collection('Research')
          .where('ID', '==', researchId ? researchId : "")
          .get()
          
          let title = ''
          let id = ''
      
          snapshot.docs.map(doc => {
              title = doc.data().Title
              id = doc.ID
          })
      
          setTitle(title)
        }
      
        researchTitle()
      
  return(
      <div className="home-kpi-tooltip-container">
          <p><b>Onderzoek</b></p>
          <p>{title}</p>
          <p><b>Type</b></p>
          <p>{typeSetting()}</p>
          <p><b>Periode</b></p>
          <p>{startDate && formatDate(startDate)} - {endDate && formatDate(endDate)}</p>
      </div>
      )
  }

  // Function to render chart type
  const chartType = () => {
       if(type === 'multiple-one-moment'){
          return (
              <HomeMultipleBarChart moment={momentId} field={fieldId} startDate={startDate} endDate={endDate}/>
          )
      }if(type === 'multiple-multiple-moment'){
          return (
              <HomeMultipleBarChart moment={momentId} field={fieldId} startDate={startDate} endDate={endDate}/>
          )
      } else if(type === 'scale-moment'){
          return (
              <ScaleMomentVisualisation moment={momentId} field={fieldId} startDate={startDate} endDate={endDate}/>
          )
      } else if(type === 'paragraph-moment'){
          return (
              <>
                  <ParagraphBarChartResponsive  moment={momentId} field={fieldId} startDate={startDate} endDate={endDate}/>
                  <OpenQuestionSlider moment={momentId} field={fieldId} startDate={startDate} endDate={endDate}/>
              </>
          )
      } else if(type === 'multiple-one-development'){
          return (
              <MultipleMultilineGraphResponsive field={fieldId} researchID={researchId} startDate={startDate} endDate={endDate}/>
          )
      } else if(type === 'multiple-multiple-development'){
          return (
              <MultipleMultilineGraphResponsive field={fieldId} researchID={researchId} startDate={startDate} endDate={endDate}/>
          )
      }else if(type === 'scale-development'){
          return (
              <ScaleDevelopmentResultsGraphResponsive field={fieldId} researchID={researchId} startDate={startDate} endDate={endDate}/>
          )
      } else if(type === 'paragraph-development'){
          return (
              <DevelopmentMultilineGraphResponsive field={fieldId} researchID={researchId} startDate={startDate} endDate={endDate}/>
          )
      } 
      else {
          return (
              <p>Unknown type</p>
          )
      }
  }

  return (
      <div className="home-kpi-chart-container">
          <div className="home-kpi-description-container">
              <QuestionMeta field={fieldId}/>
              {/* <p>{indicator.Title}</p> */}
              <Tooltip content={tooltipContent()} width={'50px'} top='30px' left='-100px'>
                  <img src={informationIcon} alt=""/>
              </Tooltip>
          </div>
          {chartType()} 
      </div>
  )
}

export default ResearchData