import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MixedBarChart = ({data, customTooltip}) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={customTooltip} />
          <Legend />
          <Bar dataKey="Afwezig" stackId="a" fill="#f44336" onClick={() => console.log('click')}/>
          <Bar dataKey="Geroosterd" stackId="a" fill="#4caf50" onClick={() => console.log('click')}/>
        </BarChart>
      </ResponsiveContainer>
  )
}

export default MixedBarChart