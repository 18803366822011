import Calendar from "../../components/common/Calender";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useState, useEffect } from "react";
import { useFirestore } from "../../firebase/useFirestore";
import { db } from "../../firebase/config";
import { format, subWeeks, nextDay, addWeeks } from 'date-fns';

const Agenda = () => {
  const [events, setEvents] = useState([]);

  const presence = useFirestore('PersonaPresence');

  const eventTitle = async (personaDocid) => {
    let personaName = '';
    await db.collection('Personas')
      .doc(personaDocid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          personaName = doc.data().Name;
        } else {
          console.log('No such document!');
        }
      });
    return personaName;
  };

  const getNext7Weekdays = (startDate, weekday) => {
    let currentDay = nextDay(startDate, weekday);
    let previousDay = subWeeks(currentDay, 1);
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.unshift(format(previousDay, 'yyyy-MM-dd'));
      previousDay = subWeeks(previousDay, 1);
    }
    currentDay = nextDay(startDate, weekday);
    for (let i = 0; i < 7; i++) {
      weekDays.push(format(currentDay, 'yyyy-MM-dd'));
      currentDay = addWeeks(currentDay, 1);
    }
    return weekDays;
  };

  const createEvents = async (weekday, item, start, end) => {
    const next7Days = getNext7Weekdays(new Date(), weekday);
    const eventsToAdd = await Promise.all(next7Days.map(async day => {
      const title = await eventTitle(item.PersonaDocid);
      return {
        Title: `${title} aanwezig`,
        Deadline: day,
        Time: `${start} - ${end}`
      };
    }));
    setEvents(prevEvents => [...prevEvents, ...eventsToAdd]);
  };

  const createPresenceEvents = () => {
    if (presence) {
      for (let item of presence) {
        const presenceTimes = item.PresenceTimes || {};
        // Monday
        if (presenceTimes.monday && presenceTimes.monday.start && presenceTimes.monday.end) {
          createEvents(1, item, presenceTimes.monday.start, presenceTimes.monday.end);
        }
        // Tuesday
        if (presenceTimes.tuesday && presenceTimes.tuesday.start && presenceTimes.tuesday.end) {
          createEvents(2, item, presenceTimes.tuesday.start, presenceTimes.tuesday.end);
        }
        // Wednesday
        if (presenceTimes.wednesday && presenceTimes.wednesday.start && presenceTimes.wednesday.end) {
          createEvents(3, item, presenceTimes.wednesday.start, presenceTimes.wednesday.end);
        }
        // Thursday
        if (presenceTimes.thursday && presenceTimes.thursday.start && presenceTimes.thursday.end) {
          createEvents(4, item, presenceTimes.thursday.start, presenceTimes.thursday.end);
        }
        // Friday
        if (presenceTimes.friday && presenceTimes.friday.start && presenceTimes.friday.end) {
          createEvents(5, item, presenceTimes.friday.start, presenceTimes.friday.end);
        }
        // Saturday
        if (presenceTimes.saturday && presenceTimes.saturday.start && presenceTimes.saturday.end) {
          createEvents(6, item, presenceTimes.saturday.start, presenceTimes.saturday.end);
        }
        // Sunday
        if (presenceTimes.sunday && presenceTimes.sunday.start && presenceTimes.sunday.end) {
          createEvents(0, item, presenceTimes.sunday.start, presenceTimes.sunday.end);
        }
      }
    }
  };

  useEffect(() => {
    if (presence) {
      createPresenceEvents();
    }
  }, [presence]);

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <DateRangeOutlinedIcon />
          <h1>Planning</h1>
        </div>
        <div id='agenda-calender-container'>
          <Calendar events={events} />
        </div>
      </div>
    </div>
  );
};

export default Agenda;