import communicateImage from '../../images/communicate.png'
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { CommunicationSystemMessage } from "../../hooks/impactAI/Prompts"
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"

const Financing = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen]
  } = useContext(ImpactAI);

  // Hooks
  const history = useHistory()

  //ImpactAI
  const pageSystemMessage = CommunicationSystemMessage()
  const pageStartMessage = `Welkom bij Communiceren, ${auth?.ForName}. Hier kun je gerealiseerde en onderbouwde impact communiceren via live-rapportages.
  Kan ik je ergens mee helpen?`
  const pageExampleMessages = ['Wat zijn live-rapportages?']
  const pageType = 'communication'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])


  return (
    <div>
      <div className='section-explainer-header-image-container'>
        <img src={communicateImage} alt="" />
      </div>
      <div className='section-explainer-text-container'>
        <h1>Financiering</h1>
        <div>
          <p>Welkom bij Financiering, {auth?.ForName}. Hier ga kun je je impact vertalen naar financiering.</p>
        </div>

      </div>
      <Breadcrumb
      section={'Financieren'}
      previousPage={'Home'}
      previousPageUrl={'home'}
      nextPage={'Fondsen'}
      nextPageUrl={'livereports'}
      counter={0}
      totalSteps={1}
      />
    </div>
  )
}

export default Financing