import Tooltip from '../../components/common/Tooltip'
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { useFirestoreGeneral } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import { useContext } from "react";
import useSettings from "../../hooks/Settings";
import settingsIcon from "../../images/icons/settings-icon.png";
import { useHistory } from "react-router-dom";

const Goals = ({personaNavigation}) => {
      // Context
      const [saved, setSaved] = useContext(SavedIcon)

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const route = Location()[3]
    const history = useHistory()

    // Firestore
    const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', route)

    const addGoal = () => {

        db.collection('PersonaGoals')
        .add({
            ID: uuid(),
            PersonaID: route,
            Goal: '',
            Description: '',
            CompagnyID: client,
            Compagny: client,
            Position: goals.length + 1,
            Timestamp: timestamp
        })

    }

    const deleteGoal = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaGoals')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    const goalTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaGoals')
        .doc(docid)
        .update({
            Goal: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const goalDescriptionHanlder = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaGoals')
        .doc(docid)
        .update({
            Description: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }


  return (
    <div style={{display: personaNavigation === 'goals' ? 'block' : 'none'}}>
        <h2>Doelen</h2>
        <div  className="persona-option-container">

            <div className='list-top-row-container'>
                <Tooltip content='Doel toevoegen' top='-60px'>
                    <img src={plusButton} onClick={addGoal} alt="" />
                </Tooltip>
            </div>
            
            <div className='table-container'>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>DOEL</th>
                        <th style={{backgroundColor: secundairyColor}}>INSTELLINGEN</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {goals && goals.map(goal => (
                        <tr key={goal.ID}>
                            <td>
                                <Tooltip content={goal.Goal} top='-30px' width={'100%'}>
                                    <input type="text" data-docid={goal.docid} defaultValue={goal.Goal} onChange={goalTitleHandler} />
                                </Tooltip>
                            </td>
                            {/* <td>
                                <Tooltip content={goal.Description} top='-60px'>
                                    <input type="text" data-docid={goal.docid} defaultValue={goal.Description} onChange={goalDescriptionHanlder}/>     
                                </Tooltip>                                   
                            </td> */}
                            <td>
                                <Tooltip content={'Doel instellingen bekijken'} top='-40px'>
                                    <img src={settingsIcon} alt="" className="table-delete-icon" onClick={() => history.push(`/${client}/personagoaldetail/${goal.ID}/${route}`)}/>
                                </Tooltip>
                            </td>
                            <td>
                                <Tooltip content={'Goal verwijderen'} top='-60px'>
                                    <img className='table-delete-icon' data-docid={goal.docid} onClick={deleteGoal} src={deleteIcon} alt="" />
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    </div>
  )
}

export default Goals