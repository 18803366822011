import measureplanImage from '../../images/measureplan.png'
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { MeasurementplanSystemMessage } from "../../hooks/impactAI/Prompts"
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"

const Measurementplan = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen]
  } = useContext(ImpactAI);

  // Hooks
  const history = useHistory()

  //ImpactAI
  const pageSystemMessage = MeasurementplanSystemMessage()
  const pageStartMessage = `Welkom bij het meetplan, ${auth?.ForName}.
   Heb je vragen over het meetplan?`
  const pageExampleMessages = ['Wat is een meetplan?']
  const pageType = 'measurementplan'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])

  return (
    <div>
    <div className='section-explainer-header-image-container'>
      <img src={measureplanImage} alt="" />
    </div>
    <div className='section-explainer-text-container'>
      <h1>Meten</h1>
      <div>
        <p>Welkom bij het meten, {auth?.ForName}. Hier ga je 
          je impact meten door de effecten die je in de impactstrategie hebt benoemd te gaan onderbouwen met impactdata die je op gaat halen bij je doelgroep(en).</p>
      </div>
      {/* <div id='impact-guide-container' className='quality-check-cta-container'>
        <div className="home-start-image-container">
          <VerifiedUserOutlinedIcon />
        </div>
        <div className="home-section-explainer-container">
          <h3>Zeker weten dat je de juiste keuzes maakt?</h3>
          <p>Laat je meetplan checken door een 
          impactspecialist van Alexander Impact en ontvang een digitaal kwaliteitskeurmerk op je live-rapportage.</p>
        </div>
        <div className="home-cta-container" onClick={() => history.push(`/${client}/qualitycheck`)}>
            <p>Meer informatie</p>
          </div>
      </div> */}
    </div>
    <Breadcrumb
    section={'Meetplan'}
    previousPage={'Home'}
    previousPageUrl={'home'}
    nextPage={'Onderzoeken'}
    nextPageUrl={'research'}
    counter={0}
    totalSteps={5}
    />
  </div>
  )
}

export default Measurementplan