import { client } from "../../hooks/Client"
import { useFirestoreOrderBy, useFirestore} from "../../firebase/useFirestore"
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { db } from "../../firebase/config";
import useSettings from "../../hooks/Settings";
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import Tooltip from "../../components/common/Tooltip";
import { OutputsSystemMessage, OutputsToolCallContent, OutputsSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { useContext, useState, useEffect } from "react";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import plusButton from '../../images/icons/plus-icon.png'
import { v4 as uuid } from 'uuid';
import { timestamp } from "../../firebase/config";
import RandomColorGenerator from "../../hooks/RandomColorGenerator";
import { ImpactAI } from '../../StateManagment/ImpactAI';
import Breadcrumb from "../../components/common/Breadcrumb";
import robotImage from "../../images/icons/robot-icon.png"
import SelectActivity from "../../components/outputs/SelectActivity";
import OutputIsSyncedTitle from "../../components/syncronisations/OutputIsSyncedTitle";
import OutputIsSyncedDelete from "../../components/syncronisations/OutputIsSyncedDelete";

const OutputSettings = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // States
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    
    // Hooks
    const history = useHistory()
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const randomColor = RandomColorGenerator()

    // Firestore
    const outputs = useFirestoreOrderBy('Outputs', 'Position', 'asc')
    const activities = useFirestore('Activities')

    //ImpactAI
    const pageSystemMessage = OutputsSystemMessage()
    const pageToolCallContent = OutputsToolCallContent()
    const pageSaveMessage = OutputsSaveMessage(activities.length)
    const pageDocid = ''
    const pageStartMessage = `Hoi, ${auth?.ForName}. In deze stap gaan we de kwantitatieve impact (outputs) benoemen die je gaat bijhouden voor je activiteiten. 
    Mag ik een voorstel doen?`
    const pageExampleMessages = []
    const pageCollection = 'Outputs'
    const pageField = 'Title'
    const pageParentId = ''
    const pageType = 'output'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth, activities])

    // color picker
    const colorHandler = (e) => {
        const color = e.target.value

        const docid = e.target.dataset.docid

        db.collection('Outputs')
        .doc(docid)
        .update({
            Color: color
        })
        .then(() => {
            setSaved('flex')
        })
    }

    // Add output
    const addOutput = (e) => {

        db.collection('Outputs')
        .doc()
        .set({
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            ActivityID: '',
            Title: '',
            Position: outputs.length + 1,
            Color: randomColor
        })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

     // Hightlight impactAI
     const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, graag!')
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className="page-header">
                <OutputOutlinedIcon/>
                <h1>Outputs</h1>
            </div>
            <div className='table-container dashboard-container'>
                <div className='list-top-row-container'>
                    <Tooltip content='Output toevoegen' width={'30px'} top='35px'>
                        <img src={plusButton} alt="" onClick={addOutput}/>
                    </Tooltip>
                    <Tooltip content={'Kan ik je helpen?'} width={'30px'} top='-10px' left='30px'>
                        <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                    </Tooltip>
                </div>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                        <th style={{backgroundColor: secundairyColor}}>ACTIVITEIT</th>
                        {/* <th style={{backgroundColor: secundairyColor}}>KLEUR (IN GRAFIEKEN)</th> */}
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {outputs && outputs.map((output, index) => (
                            <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={output}
                            input={
                                <OutputIsSyncedTitle outputID={output.ID}/>
                                }
                            parentIndex={index}
                            collection={'Outputs'}
                            itemArray={outputs}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={output.Title}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                            <td>
                                <SelectActivity output={output}/>
                            </td>
                            {/* <td>
                                <input type="color" className="setting-color-picker" data-docid={output.docid} defaultValue={output.Color} onChange={colorHandler}/>
                            </td> */}
                            <td>
                                <OutputIsSyncedDelete outputID={output.ID}/>
                            </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
            </div>
            <Breadcrumb
            section={'Impactstrategie'}
            previousPage={'Activiteiten'}
            previousPageUrl={'Activities'}
            nextPage={'Effecten'}
            nextPageUrl={'effects'}
            counter={6}
            totalSteps={8}
            />
        </div>
    </div>
  )
}

export default OutputSettings