import React from 'react'
import { useState, useEffect } from 'react'
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import LiveReportMedia from './LiveReportMedia'
import deleteIcon from '../../images/icons/delete-icon.png'
import { db } from '../../firebase/config'
import Tooltip from '../common/Tooltip'
import LiveReportResearchData from './LiveReportResearchData'
import firebase from 'firebase'

const ReportBuilderResearch = ({researchId, docid}) => {
    // State
    const [questionnaireId, setQuestionnaireId] = useState('')

    // Firestore
    const research = useFirestoreGeneralOrderBy('Research', 'ID', researchId ? researchId : '', 'Position', 'asc')

    // Add description to research
    const researchDescriptionHandler = (e) => {
        const docid = e.target.dataset.docid

        db.collection('Research')
        .doc(docid)
        .update({
            Description: e.target.value
        })
    }

    // Delete research
    const deleteResearch = (e) => {
        const id = e.target.dataset.id

        db.collection('LiveReports')
        .doc(docid)
        .update({
            Researches: firebase.firestore.FieldValue.arrayRemove(id)
        })


    }

    // Set questionnaire id 
    useEffect(() => {
        research && research.forEach(item => {
            setQuestionnaireId(item.QuestionnaireID)
        })
    },[research])

  return (
    <div className="report-activities-container">
        {research && research.map(item => (
            <div className="report-activity-item-container" style={{borderBottom: research.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="report-activity-item-description-container">
                    <h3>{item.Title}</h3>
                    <textarea cols="30" data-docid={item.docid} defaultValue={item.Description} placeholder="Voeg een omschrijving toe van de activiteit" rows="10" onChange={researchDescriptionHandler}></textarea>
                    <p><b>Voeg foto's of video's toe</b></p>
                    <LiveReportMedia parent={item}/>
                </div>
                <div className="report-activity-item-graph-container">
                    <h3>Resultaten uit onderzoek</h3>
                    <LiveReportResearchData questionnaireId={questionnaireId} researchId={item.ID}/>
                </div>
                <Tooltip content="Effect verwijderen" width='4%' top='-60px'>
                    <img className="delete-icon-report-builder" src={deleteIcon} alt="" data-id={item.ID} onClick={deleteResearch}/>
                </Tooltip>
            </div>
        ))}
    </div>
  )
}

export default ReportBuilderResearch