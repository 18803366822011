import Location from "../../hooks/Location"
import { useFirestoreID, useFirestoreGeneralOrderBy, useFirestoreQuestionnaireFields } from "../../firebase/useFirestore";
import { useState, useEffect, useContext } from "react";
import useSettings from "../../hooks/Settings";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { PersonaBuilderSystemMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from "../../StateManagment/Auth";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import Tooltip from "../../components/common/Tooltip";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client";
import CategorizeFieldNotice from "../../components/researchResults/CategorizeFieldNotice";
import FieldResponsesCount from "../../components/Research/FieldResponsesCount";
import { db } from "../../firebase/config";

const ResearchResults = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen],
      hightlighted: [hightlighted, setHightlighted],
      hightlightText: [hightlightText, setHightlightText],
  } = useContext(ImpactAI);

 //State
 const [questionnaireID, setQuestionnaireID] = useState('')
 const [researchTitle, setResearchTitle] = useState('')
 const [title, setTitle] = useState('')

 // Hooks
 const researchId = Location()[3]
 const secundairyColor = useSettings().SecundairyColor
 const history = useHistory()

 // Firestore
 const research = useFirestoreID('Research', researchId ? researchId : '')
 const fields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'QuestionnaireID', questionnaireID ? questionnaireID : '', 'Position', 'asc')

 // ImpactAI
 const pageSystemMessage = PersonaBuilderSystemMessage()
 const pageStartMessage = `Hoi ${auth?.ForName}, welkom bij de onderzoeksanalyse van onderzoek ${title}. 
 Hier kun je de resultaten van het onderzoek inzien en de eventuele open vragen categoriseren.
 Kan ik je ergens mee helpen?`
 const pageType = 'personabuilder'
 const paegExampleMessages = ['Hoe kan ik een open vraag analyseren?']

 useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(paegExampleMessages)
 }, [auth, title])

 // Reset hightlights
 useEffect(() => {
     setHightlighted(false)
     setHightlightText('')
 },[])

 // Set research title
 useEffect(() => {
     research && research.forEach(item => {
         setQuestionnaireID(item.QuestionnaireID)
         setResearchTitle(item.Title)
     })
 },[research])

return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>{researchTitle}</h1>
                </div>
                <div className='table-container table-container-dashboard'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>VRAAG</th>
                            <th style={{backgroundColor: secundairyColor}}>RESPONSES</th>
                            <th style={{backgroundColor: secundairyColor}}>VISUALISATIES</th>
                            <th style={{backgroundColor: secundairyColor}}>CATEGORISATIE</th>
                        </tr>
                        {fields && fields.map(item => (
                            <tr>
                                <td>
                                    <p>{item.Question}</p>
                                </td>
                                <td>
                                    <div id='reserach-overview-responses-container' onClick={() => {history.push(`/${client}/researchresponses/${item.ID}/${researchId}`)}}>
                                        <Tooltip content='Bekijk de responses' top={'-60px'}>
                                            <ChatBubbleOutlineOutlinedIcon className='table-delete-icon'/>
                                        </Tooltip>
                                        <FieldResponsesCount fieldID={item.ID} researchID={researchId}/>
                                    </div>
                                </td>
                                <td>
                                    <Tooltip content='Bekijk de visualisaties' top={'-60px'}>
                                        <AssessmentOutlinedIcon className='table-delete-icon' onClick={() => {history.push(`/${client}/researchresultsvisualisations/${item.ID}/${researchId}`)}}/>
                                    </Tooltip>
                                </td>
                                <td>
                                    <CategorizeFieldNotice fieldID={item.ID} researchID={researchId} type={item.Type}/>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
                <Breadcrumb
                section={'Meten'}
                previousPage={'Onderzoeken'}
                previousPageUrl={'Research'}
                nextPage={''}
                nextPageUrl={''}
                counter={2}
                totalSteps={5}
                />
            </div>
        </div>
    )
}

export default ResearchResults