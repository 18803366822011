import { useState, useEffect } from 'react';
import { useFirestore } from "../firebase/useFirestore";

const useSettings = () => {
  const [MKBAOn, setMKBAOn] = useState('none');
  const [personasOn, setPersonasOn] = useState('none');
  const [sdgOn, setSdgOn] = useState('none');
  const [contextOn, setContextOn] = useState('block')
  const [reportOn, setReportOn] = useState('none')
  const [docid, setDocid] = useState('')
  const [PrimaryColor, setPrimaryColor] = useState('rgb(244, 129, 131)')
  const [SecundairyColor, setSecundairyColor] = useState('#63cadc')
  const [TertairyColor, setTertairyColor] = useState('rgb(255, 203, 15)')
  const [impactAI, setImpactAI] = useState('none')

  const [compagnyName, setCompagnyName] = useState('')
  const [logo, setLogo] = useState('none')
  const [subscription, setSubscription] = useState(false)
  const [trailDays, setTrailDays] = useState(0)
  const [stripeId, setStripeId] = useState('')
  const [email, setEmail] = useState('')
  const [subscriptionType, setSubscriptionType] = useState('')
  const [finance, setFinance] = useState('none')

  const compagnyMeta = useFirestore('CompagnyMeta')


  useEffect(() => {
    compagnyMeta && compagnyMeta.forEach(item => {
      item.MKBA === 'true' ? setMKBAOn('block') : setMKBAOn('none');
      item.Personas === 'true' ? setPersonasOn('block') : setPersonasOn('none');
      item.SDGs === 'true' ? setSdgOn('block') : setSdgOn('none');
      item.Report === 'true' ? setReportOn('block') : setReportOn('none');
      item.PrimaryColor && setPrimaryColor(item.PrimaryColor)
      item.SecundairyColor && setSecundairyColor(item.SecundairyColor)
      item.TertairyColor && setTertairyColor(item.TertairyColor)
      item.ImpactAI === 'true' ? setImpactAI('block') : setImpactAI('none')
      item.CommunityName && setCompagnyName(item.CommunityName)
      item.Logo && setLogo(item.Logo)
      item.Subscription && setSubscription(item.Subscription)
      item.StripeCustomerId && setStripeId(item.StripeCustomerId)
      item.Email && setEmail(item.Email)
      item.SubscriptionType && setSubscriptionType(item.SubscriptionType)
      item.Financing === true ? setFinance('block') : setFinance('none')

      // Set trail days
      const today = new Date()
      const created = new Date(item.Timestamp.toDate())
      const diffTime = Math.abs(today - created);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if(diffDays > 7){
          setTrailDays(0)
      } else {
          setTrailDays(7 - diffDays)
      }

      // Set docid
      setDocid(item.docid)

      // Display context in leftsidebar based on settings
      if(item.MKBA === 'false' && item.Personas === 'false' && item.SDGs === 'false'){
        setContextOn('none')
      }
    })
  }, [compagnyMeta]);

  return { 
    MKBAOn, 
    personasOn, 
    sdgOn, 
    contextOn, 
    reportOn, 
    docid, 
    PrimaryColor, 
    SecundairyColor, 
    TertairyColor, 
    impactAI, 
    compagnyName, 
    logo,
    trailDays,
    subscription,
    stripeId,
    email,
    subscriptionType,
    finance
   };
};

export default useSettings;