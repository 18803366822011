import { useContext, useEffect, useState } from 'react';
import { client } from '../../hooks/Client';
import { useHistory } from "react-router"
import { SavedIcon } from '../../StateManagment/SavedIcon'
import Location from '../../hooks/Location';
import Hostname from '../../hooks/Hostname';
import useSettings from '../../hooks/Settings';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { db } from '../../firebase/config';
import { Link } from 'react-router-dom';
import Tooltip from "../../components/common/Tooltip";
import { Auth } from '../../StateManagment/Auth';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useFirestoreGeneral } from '../../firebase/useFirestore';
import OnboardingModal from '../common/OnboardingModal';
import { Onboarding } from '../../StateManagment/Onboarding';

const Topbar = () => {
    // Context
    const [authO] = useContext(Auth)
    const [saved, setSaved] = useContext(SavedIcon)
    const { step, setStep } = useContext(Onboarding);

    // State
    const [logo, setLogo] = useState('https://firebasestorage.googleapis.com/v0/b/deccos-app.appspot.com/o/Logos%2FLOGO-logo.png?alt=media&token=2785407c-d390-4ddd-a7c8-db38d1b6a0ba')
    const [display, setDisplay] = useState('block')
    const [displayTopbar, setDisplayTopbar] = useState('block')
    const [compagnyLogo, setCompagnyLogo] = useState(null)

    // Hooks
    const history = useHistory()
    const urlTwo = Location()[2]
    const urlOne = Location()[1]
    const urlFour = Location()[4]
    const urlSix = Location()[6]
    const host = Hostname()
    const trailDays = useSettings().trailDays
    const subscription = useSettings().subscription
    const secondaryColor = useSettings().SecundairyColor

    // Firestore
    const syncs = useFirestoreGeneral('Synchronisations', 'Status', 'requested')

    // Onboarding
    const onboardingTitleProfile = 'Hier vind je jouw profiel'
    const onboardingTextProfile = 'Klik op jouw profielfoto om naar jouw profiel te gaan'
    const onboardingTitleSupport = 'Hier vind je de helpdesk'
    const onboardingTextSupport = 'Klik op het icoon om naar de helpdesk te gaan'

    // Get compagny logo
    useEffect(() => {
       
        db.collection("CompagnyMeta")
        .where("CompagnyID", "==", urlOne)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setCompagnyLogo(doc.data().Logo)
            }
            )
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        })
    }
    ,[urlOne])

    // Check if days since creation is more than 30
    const checkDaysSinceCreation = () => {
        if(subscription === 'trial' && trailDays !== 0){
            return 'flex'
        } else{
            return 'none'
        }
    }

    // Display logo and hide iconbar if client is not approved, not registered or not logged in
    const displayLogo = () => {

        if(client === '' || urlTwo === 'login' || urlOne === 'login' || urlOne === 'createaccount' || urlOne === 'impactplan' ){
            setLogo(host.Logo)
            setDisplay('none')
        } else if(urlTwo === 'Register' || urlTwo === 'NotApproved' || urlTwo === 'RegisterInvitation'){
            setDisplay('none')
            setLogo(compagnyLogo)
        } else{
            setLogo(compagnyLogo)
            setDisplay('block')
        }
    }

    const showTopbar = () => {
        if( urlFour === 'open' || urlSix === 'open'){
            setDisplayTopbar('none')
        } else{
            setDisplayTopbar('block')
        }
    }



    useEffect(() => {
        displayLogo()
    },[ client, urlTwo, urlOne, compagnyLogo, host ])

    useEffect(() => {
        showTopbar()
    },[urlFour, urlSix])

    return (
        <>
        <header id="top-bar-container" style={{display: displayTopbar}}>
            <div className="top-bar">
                <div style={{display: checkDaysSinceCreation()}} id='subscribtion-container'>
                    <p id='days-left-in-trial-text'>Proefabonnement eindigd over {trailDays} dagen</p>
                    <p>&nbsp;  - &nbsp; </p>
                    <ArrowUpwardOutlinedIcon style={{fill: secondaryColor}} onClick={() => history.push(`/${client}/subscription`)}/>
                    <p id='upgrade-cta' style={{color: secondaryColor}} onClick={() => history.push(`/${client}/subscription`)}>Upgrade</p>
                </div>
                <SaveAltOutlinedIcon style={{display: saved}} id='topbar-saved-icon'/>
                <div className="icon-container">
                    <Tooltip content={'Notificaties'} top='60px'>
                        <Link to={`/${client}/notifications`}>
                            {syncs && syncs.length > 0 &&
                                <div id='notification-badge'>
                                    <p>{syncs && syncs.length}</p>
                                </div>
                            }
                            <NotificationsNoneOutlinedIcon className='icon-bar-icon' id='topbar-home-icon' />
                        </Link>
                    </Tooltip>
                </div> 
                <div className="icon-container">
                    <Tooltip content={'Home'} top='60px'>
                        <Link to={`/${client}/home`}>
                            <HomeOutlinedIcon className='icon-bar-icon' id='topbar-home-icon' />
                        </Link>
                    </Tooltip>
                </div> 
                <div className="icon-container">
                    <Tooltip content={'Helpdesk'}  top='60px'>
                        <Link to={`/${client}/helpdesk`}>
                        <SupportAgentOutlinedIcon className='icon-bar-icon' id='topbar-support-icon'/>
                        </Link>
                    </Tooltip>
                </div> 
                
                <div className="profile-photo">
                    <Tooltip content={'Mijn profiel'} top='40px'>
                        <Link to={`/${client}/Profile`}>
                            <div id='photo-container-topbar'>
                                 <img src={authO && authO.Photo} alt="" id='topbar-photo' />
                            </div>
                        </Link>
                    </Tooltip>
                </div>
            </div>
            {step === 1 &&
             <OnboardingModal 
                selector='#topbar-photo'
                title={onboardingTitleProfile}
                text={onboardingTextProfile}
             />
            }
            {step === 2 &&
             <OnboardingModal 
                selector='#topbar-support-icon'
                title={onboardingTitleSupport}
                text={onboardingTextSupport}
             />
            }
        </header>
        </>
    )
}

export default Topbar
