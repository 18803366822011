import { useFirestore } from "../../firebase/useFirestore";
import { useContext, useEffect, useState } from "react";
import { db } from "../../firebase/config.js"
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import useSettings from "../../hooks/Settings";
import { ContextSystemMessage, ContextToolCallContent, ContextSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import robotImage from "../../images/icons/robot-icon.png"
import Breadcrumb from "../../components/common/Breadcrumb";
import Tooltip from "../../components/common/Tooltip";
import OnboardingModal from '../../components/common/OnboardingModal';
import { Onboarding } from '../../StateManagment/Onboarding';
import { ImpactAIData } from "../../StateManagment/ImpactAIData";

const OrganisationContext = () => {
     // Context
     const [saved, setSaved] = useContext(SavedIcon)
     const [auth] = useContext(Auth)
     const { step, setStep } = useContext(Onboarding);
     const {
         type: [type, setType],
         systemMessage: [systemMessage, setSystemMessage],
         toolCallContent: [toolCallContent, setToolCallContent],
         collection: [collection, setCollection],
         docid: [docid, setDocid],
         field: [field, setField],
         saveMessage: [saveMessage, setSaveMessage],
         startMessage: [startMessage, setStartMessage],
         parentId: [parentId, setParentId],
         exampleMessages: [exampleMessages, setExampleMessages],
         chatOpen: [chatOpen, setChatOpen],
         hightlighted: [hightlighted, setHightlighted],
         hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);
    const {
        summary: [summary, setSummary],
    } = useContext(ImpactAIData);

 
     // Hooks
     ScrollToTop()
     const secundairyColor = useSettings().SecundairyColor
 
     // Firestore 
     const sourceData = useFirestore('SourceData')
 
      //ImpactAI
    const pageType = 'context'
    const pageSystemMessage = ContextSystemMessage()
    const pageToolCallContent = ContextToolCallContent()
    const pageSaveMessage = ContextSaveMessage()
    const pageStartMessage = `Welkom bij de organisatie context, ${auth?.ForName}. Hier ga je de context van je organisatie beschrijven. 
    Ik gebruik deze informatie om je te helpen bij de rest van het impactmanagementproces.
    Zullen we aan de slag gaan?`
    const pageCollection = 'SourceData'
    const pageField = 'Summary'
    const pageParentId = ''

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages([])
    }, [auth])

    // Onboarding
    const onboardingTitleImpactAI = 'Je bent nu op een willekeurige pagina in het proces.'
    const onboardingTextImpactAI = 'Bij iedere stap uit het proces vind je dit robot-icoontje. Klik op robot-icoontje om naar de chat te gaan en hulp te vragen aan onze ingebouwde AI Impactadviseur.'
    const onboardingTitleBreadcrumb = 'Hier kun je zien waar je bent in het proces'
    const onboardingTextBreadcrumb = 'Navigeer door het proces door op de stappen te klikken'

    // Set sourcedata docid
    useEffect(() => {
        sourceData && sourceData.forEach(item => {
            setDocid(item.docid)
            setSummary(item.Summary)
        })
    },[sourceData])

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, laten we starten!')
    }

    // Handle change
    const summaryHandler = (e) => {
        const docid = e.target.dataset.docid
        const summary = e.target.value

        db.collection('SourceData')
        .doc(docid)
        .update({
            Summary: summary
        })
        .then(() => {
            setSaved(true)
        })
    }

  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header ">
                <ContentPasteOutlinedIcon/>
                <h1>Organisatie omschrijving</h1>
            </div>
            <div className="table-container dashboard-container">
                <div className='list-top-row-container'>
                    <Tooltip content={'Kan ik je helpen?'} width='30px' top='-10px' left='30px'>
                        <img src={robotImage} alt="" id='assistant-icon' className="ai-assistant-icon" onClick={impactaiHandler}/>
                    </Tooltip>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>OMSCHRIJVING</th>
                        </tr>
                    </thead>
                    {sourceData && sourceData.map((item, index) => (
                        <div key={item.ID}>
                            <textarea name="" id="" cols="30" rows="10" placeholder="Geef hier een omschrijving van je organisatie" defaultValue={item.Summary} data-docid={item.docid} onChange={summaryHandler}></textarea>
                        </div>
                    ))}
                </table>
            </div>
            <div id='breadcrumb-onboarding'>
                <Breadcrumb
                section={'Impactstrategie'}
                previousPage={'Impactstrategie'}
                previousPageUrl={'Impactstrategy'}
                nextPage={'Maatschappelijk probleem'}
                nextPageUrl={'problemanalysis'}
                counter={1}
                totalSteps={8}
                />
            </div>
             {step === 3 &&
             <OnboardingModal 
                selector='#assistant-icon'
                title={onboardingTitleImpactAI}
                text={onboardingTextImpactAI}
                margin={'250px 650px 0px 0px'}
             />
            }
            {step === 4 &&
             <OnboardingModal 
                selector='#breadcrumb-onboarding'
                title={onboardingTitleBreadcrumb}
                text={onboardingTextBreadcrumb}
                margin={'520px 0px 0px 0px'}
             />
            }
        </div>
    </div>
  )
}

export default OrganisationContext