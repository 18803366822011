import EffectIndicator from "./EffectIndicator"

const GoalIndicators = ({effects, reportId, setEffectId, setResponses}) => {


  return (
    <>
        {effects && effects.map(effect => (
            <div key={effect}>
                <EffectIndicator 
                effectId={effect}
                reportId={reportId}
                setResponses={setResponses}
                />
                {setEffectId(effect)}
            </div>
        ))}
    </>
  )
}

export default GoalIndicators