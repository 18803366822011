import {useFirestoreOrderBy} from "../../firebase/useFirestore"
import { client } from "../../hooks/Client"
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import Tooltip from "../../components/common/Tooltip";
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import { ActivitiesSystemMessage, ActivitiesToolCallContent, ActivitiesSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { useContext, useState, useEffect } from "react";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Breadcrumb from "../../components/common/Breadcrumb";
import DeleteModal from "../../components/common/DeleteModal";
import deleteIcon from '../../images/icons/delete-icon.png'
import DraggableTableItems from "../../components/common/DraggableTableItems";
import plusButton from '../../images/icons/plus-icon.png'
import { db, timestamp } from "../../firebase/config.js"
import { v4 as uuid } from 'uuid';
import robotImage from "../../images/icons/robot-icon.png"

const Activities = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Hooks
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')   

     //ImpactAI
     const pageSystemMessage = ActivitiesSystemMessage()
     const pageToolCallContent = ActivitiesToolCallContent()
     const pageSaveMessage = ActivitiesSaveMessage()
     const pageStartMessage = `Welkom bij de activiteiten, ${auth?.ForName}. Hier ga we de concrete activiteiten benoemen die je uitvoert om je impactdoel te bereiken.
     Mag ik een voorstel doen?`
     const pageExampleMessages = []
     const pageCollection = 'Activities'
     const pageField = 'Activity'
     const pageParentId = ''
     const pageType = 'activity'

     useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth])

    const activityHandler = (e) => {

        const title = e.target.value 
        const docid = e.target.dataset.docid

        db.collection('Activities')
        .doc(docid)
        .update({
            Activity: title
        })
        .then(() => {
            setSaved('flex')
         })

    }

    const addActivity = (e) => {

        const id = uuid()
        
        db.collection('Activities')
        .doc()
        .set({
            Activity: '',
            ID: id,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Position: activities.length + 1,
        })
    }

    const deleteActivity = (e) => {
        const docid = e.target.dataset.docid
        const id = e.target.dataset.id

        db.collection('Activities')
        .doc(docid)
        .delete()
        .then(() => {
            // Delete outputs
            db.collection('Outputs')
            .where('ActivityID', '==', id)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    doc.ref.delete()
                })
            })
        })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, zeker!')
    }

    return (
        <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <MonitorHeartOutlinedIcon/>
                <h1>Activiteiten</h1>
            </div>
            <div className="table-container dashboard-container">
                <div className='list-top-row-container'>
                    <Tooltip content='Activiteit toevoegen' width={'30px'} top='35px'>
                        <img src={plusButton} onClick={addActivity} alt="" />
                    </Tooltip>
                    <Tooltip content={'Kan ik je helpen?'} width={'30px'} top='-10px' left='30px'>
                        <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                    </Tooltip>
                </div>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>ACTIVITEIT</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {activities && activities.map((activity, index) => (
                        <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={activity}
                            input={
                                <Tooltip content={activity.Activity} width='100%' top='-60px' >
                                    <input contentEditable type="text" data-docid={activity.docid} defaultValue={activity.Activity} placeholder='Titel' onChange={activityHandler} />
                                </Tooltip>
                            }
                            parentIndex={index}
                            collection={'Activities'}
                            itemArray={activities}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={activity.Activity}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                                <td>
                                    <Tooltip content='Activiteit verwijderen' top='-60px'>
                                        <img className='table-delete-icon' data-id={activity.ID} data-docid={activity.docid} onClick={deleteActivity} src={deleteIcon} alt="" />
                                    </Tooltip>
                                </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
            </div>
            <Breadcrumb
            section={'Impactstrategie'}
            previousPage={'Impactdoel'}
            previousPageUrl={'Goals'}
            nextPage={'Outputs'}
            nextPageUrl={'Outputs'}
            counter={5}
            totalSteps={8}
            />
            {/* <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteStakeholder} deleteName={deleteName} /> */}
        </div>
    </div>
    )
}

export default Activities
