import { useState } from 'react'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const EffectdatabaseItemsList = ({setSelectedEffects, index, effect, secundairyColor}) => {
    const [expandedEffectList, setExpandedEffectList] = useState('')

    // Place a name after the SDG number
    const SDGName = (sdg) => {

        switch(sdg) {
            case 1:
                return 'Geen armoede'
            case 2:
                return 'Geen honger'
            case 3:
                return 'Goede gezondheid en welzijn'
            case 4:
                return 'Kwaliteitsonderwijs'
            case 5:
                return 'Gendergelijkheid'
            case 6:
                return 'Schoon water en sanitair'
            case 7:
                return 'Betaalbare en duurzame energie'
            case 8:
                return 'Eerlijk werk en economische groei'
            case 9:
                return 'Industrie, innovatie en infrastructuur'
            case 10:
                return 'Ongelijkheid verminderen'
            case 11:
                return 'Duurzame steden en gemeenschappen'
            case 12:
                return 'Verantwoorde consumptie en productie'
            case 13:
                return 'Klimaatactie'
            case 14:
                return 'Leven in het water'
            case 15:
                return 'Leven op het land'
            case 16:
                return 'Vrede, justitie en sterke publieke diensten'
            case 17:
                return 'Partnerschap om doelstellingen te bereiken'
            default:
                return 'Geen SDG'
        }
    }

    // Add subeffect
    const subeffectHandler = (e) => {
        const subeffect = e.target.dataset.subeffect;
        const sdgs = e.target.dataset.sdgs;
        const effect = e.target.dataset.effect;
        const isChecked = e.target.checked;
    
        if (isChecked) {
            // Checkbox is selected, add the item to the state
            setSelectedEffects(prevState => [...prevState, { subeffect: subeffect, sdgs: sdgs, effect: effect }]);
        } else {
            // Checkbox is deselected, remove the item from the state
            setSelectedEffects(prevState => 
                prevState.filter(effect => effect.subeffect !== subeffect)
            );
        }
    };


    // Add manual effect
    const manualEffectHandler = (e) => {
        const effect = e.target.value

        setSelectedEffects(prevState => [...prevState, { subeffect: effect, sdgs: [], effect: '' }]);
    }

    // Expand list
    const expandList = (index) => {
        if (index === expandedEffectList) {
            return 'block'
        } else {
            return 'none'
        }
    }

  return (
    <div key={effect.id}>
        <div 
        className="effect-selector-title-container" 
        onClick={() => setExpandedEffectList(!expandedEffectList)}
        style={{backgroundColor: expandedEffectList ? "#f4f4f4" : "white"}}
        >
            <h3>{effect.name}</h3>
            {expandedEffectList ? <KeyboardArrowDownOutlinedIcon style={{transform: 'rotate(180deg)'}}/> : <KeyboardArrowDownOutlinedIcon/>}
        </div>
        <div style={{display: expandedEffectList ? 'block' : 'none'}}>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}></th>
                        <th style={{backgroundColor: secundairyColor}}>EFFECT</th>
                        {/* <th style={{backgroundColor: secundairyColor}}>SDG'S</th> */}
                    </tr>
                </thead>  
                {effect.subeffects.map((subeffect) => (
                    <tr>
                        <td>
                            <input type="checkbox" data-effect={effect.name} data-sdgs={subeffect.SDGs} data-subeffect={subeffect.name} name="" id="" onChange={subeffectHandler}/>
                        </td>
                        <td>
                            <p>{subeffect.name}</p>
                        </td>
                        {/* <td>
                            <ul>
                                {subeffect.SDGs.map((sdg) => (
                                    <li>{sdg}: {SDGName(sdg)}</li>
                                ))}
                            </ul>
                        </td> */}
                    </tr>
                ))}
            </table>
        </div>
    </div>
  )
}

export default EffectdatabaseItemsList