import { NavLink } from "react-router-dom";
import { client } from '../../hooks/Client';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import userIcon from '../../images/icons/user-icon.png'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import worldIcon from '../../images/icons/world-icon.png'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import calendarIcon from '../../images/icons/calendar-icon.png'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';
import useSettings from "../../hooks/Settings";
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Location from '../../hooks/Location';
import { useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import Tooltip from "../common/Tooltip";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Auth } from '../../StateManagment/Auth';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { useFirestore } from "../../firebase/useFirestore";
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import downCircleIcon from '../../images/icons/down-circle-icon.png'
import upCircleIcon from '../../images/icons/up-circle-icon.png'

const LeftSideBar = () => {
    // Context
    const [authO] = useContext(Auth)

    // State
    const [open, setOpen] = useState(true)
    const [showImpactStrategy, setShowImpactStrategy] = useState(true)


    // Hooks
    const SecColor = useSettings().SecundairyColor
    const client = Location()[1]
    const history = useHistory()
    const logo = useSettings().logo

    // Firestore
    const syncs = useFirestore('Synchronisations')

    // Set the background color of the active route
    const setBackgroundcolor = (match) => {
        if (match) {
          return { backgroundColor: SecColor }; // Set your desired background color here
        }
        return null; // No background color when the route is not active
      };

    // Icon for the expand menu
    const icon = () => {
        if(showImpactStrategy){
            return upCircleIcon
        } else {
            return downCircleIcon
        }
    }

    return (
        <div id='left-side-bar-toggle-container'>
            <div className="left-sidebar-container" style={{width: open ? '257px' : '0px'}}>
                <div id='leftsidebar-logo-container'  style={{display: open ? 'flex' : 'none'}}>
                    <img src={logo} className="top-bar-logo" alt="logo" onClick={() => history.push(`/${client}/home`)}/>
                </div>

                <div className="leftsidebar-seperator"></div>

                <div className="left-side-bar" style={{display: open ? 'block' : 'none'}}>

                    <div className="channel-div">
                        <h3></h3>
                        <div className="channel-inner-div-empty"></div>
   
                        <div className="channel-inner-div">
                            <div className='activity-meta-title-container'>
                                <DashboardOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/dashboard`}>Dashboard</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="leftsidebar-seperator"></div>
                
                    <div className="channel-div">
                        <div className="nav-title-container">
                            <NavLink activeClassName='activeTitle' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/impactstrategy`}>
                                <h3>Impactstrategie</h3>
                            </NavLink>
                            {/* <div className="expand-menu-icon-container">
                                <img src={icon()} id='expand-menu-icon' className="menu-icon" onClick={() => setShowImpactStrategy(!showImpactStrategy)}/>
                            </div> */}
                        </div>
                        <div className="channel-items-container" style={{display: showImpactStrategy ? 'block' : 'none'}}>
                            <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <ContentPasteOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/organisationcontext`}>Organisatie omschrijving</NavLink>
                                </div>
                            </div>
                            <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <ExtensionOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/problemanalysis`}>Maatschappelijk probleem</NavLink>
                                </div>
                            </div>
                            <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <PersonOutlineOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/Stakeholders`}>Doelgroepen</NavLink>
                                </div>
                            </div>
                            <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <FlagOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/Goals`}>Impactdoel</NavLink>
                                </div>
                            </div>
                            <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <MonitorHeartOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/Activities`}>Activiteiten</NavLink>
                                </div>
                            </div>
                            <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <OutputOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/Outputs`}>Outputs</NavLink>
                                </div>
                            </div>
                            <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <PodcastsOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/effects`}>Effecten</NavLink>
                                </div>
                            </div>
                            {/* <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <HighlightAltOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/indicators`}>Indicatoren</NavLink>
                                </div>
                            </div> */}
                            <div className="channel-inner-div">
                                <div className='activity-meta-title-container'>
                                    <ChangeCircleOutlinedIcon className="menu-icon"/>
                                    <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/theoryofchange`}>Theory of change</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="leftsidebar-seperator"></div>

                    <div className="channel-div">
                        <div className="nav-title-container">
                            <NavLink activeClassName='activeTitle' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/measurementplan`}>
                                <h3>Meten</h3>
                            </NavLink>
                        </div>
                        <div className="channel-inner-div">
                            <div className='activity-meta-title-container'>
                                <BiotechOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/research`}>Onderzoeken</NavLink>
                            </div>
                        </div>
                        {/* <div className="channel-inner-div">
                            <div className='activity-meta-title-container'>
                                <ListAltOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/questionnaires`}>Vragenlijsten</NavLink>
                            </div>
                        </div> */}
                        {/* <div className="channel-inner-div">
                            <div className='activity-meta-title-container'>
                                <TrackChangesOutlinedIcon className="menu-icon"/>       
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/kpis`}>KPI's</NavLink>
                            </div>
                        </div> */}
                        <div className="channel-inner-div">
                            <div className='activity-meta-title-container'>
                                <BarChartOutlinedIcon className="menu-icon"/>       
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/outputresults`}>Outputresultaten</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="leftsidebar-seperator"></div>

                    <div className="channel-div" style={{display: useSettings().personasOn}}>
                        <div className="nav-title-container">
                            <NavLink activeClassName='activeTitle' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/personasExplainer`}>
                                <h3>Deelnemers</h3>
                            </NavLink>
                        </div>
                        <div className="channel-inner-div" >
                            <div className='activity-meta-title-container'>
                                <AspectRatioOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/personadashboard`}>Overzicht</NavLink>
                            </div>
                        </div>
                        <div className="channel-inner-div" >
                            <div className='activity-meta-title-container'>
                                <GroupsOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/personas`}>Deelnemers</NavLink>
                            </div>
                        </div>
                        <div className="channel-inner-div" >
                            <div className='activity-meta-title-container'>
                                <DateRangeOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/agenda`}>Planning</NavLink>
                            </div>
                        </div>
                        <div className="channel-inner-div" >
                            <div className='activity-meta-title-container'>
                                <SettingsOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/personasettings`}>Instellingen</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="leftsidebar-seperator"></div>


                    <div className="channel-div" style={{display: useSettings().reportOn}}>
                        <div className="nav-title-container">
                            <NavLink activeClassName='activeTitle' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/communication`}>
                                <h3>Communiceren</h3>
                            </NavLink>
                        </div>
                        <div className="channel-inner-div">
                            <div className='activity-meta-title-container'>
                                <AssessmentOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/livereports`}>Live rapportages</NavLink>
                            </div>
                        </div>
                        <div className="channel-inner-div" style={{display: syncs && syncs.length > 0 ? 'flex' : 'none'}}>
                            <div className='activity-meta-title-container'>
                                <SyncOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/synchronisations`}>Synchronisaties</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="leftsidebar-seperator"></div>

                    <div className="channel-div" style={{display: useSettings().finance}}>
                        <div className="nav-title-container">
                            <NavLink activeClassName='activeTitle' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/financing`}>
                                <h3>Financiering</h3>
                            </NavLink>
                        </div>
                        <div className="channel-inner-div" >
                            <div className='activity-meta-title-container'>
                                <HandshakeOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/funds`}>Fondsenwerving</NavLink>
                            </div>
                        </div>
                        {/* <div className="channel-inner-div" >
                            <div className='activity-meta-title-container'>
                                <StorefrontOutlinedIcon className="menu-icon"/>
                                <NavLink activeClassName='active' isActive={(match, location) => setBackgroundcolor(match)} style={setBackgroundcolor} to={`/${client}/impactmarket`}>Impactmarkt</NavLink>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
            <div id='leftsidebar-toggle-item-container' >
                <div style={{display: open ? 'block' : 'none'}} onClick={() => setOpen(false)}>
                    <Tooltip content='Menu inklappen' >
                        <KeyboardDoubleArrowLeftOutlinedIcon onClick={() => setOpen(false)}/>
                    </Tooltip>
                </div>
                <div style={{display: open ? 'none' : 'block'}} onClick={() => setOpen(true)}>
                    <Tooltip content='Menu uitklappen' >
                        <KeyboardDoubleArrowRightOutlinedIcon onClick={() => setOpen(true)}/>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default LeftSideBar
