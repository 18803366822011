import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import ForkRightOutlinedIcon from '@mui/icons-material/ForkRightOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ErasorIcon from '../../images/icons/erasor-icon.png';
import XIcon from '../../images/icons/x-icon.png';
import StatusData from '../../components/personas/StatusData';
import RouteData from '../../components/personas/RouteData';
import { useEffect, useState, useContext } from 'react';
import { useFirestoreGeneral, useFirestore } from '../../firebase/useFirestore';
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"
import useSettings from "../../hooks/Settings";
import PersonaNameMeta from '../../components/personas/PersonaNameMeta';
import { Auth } from '../../StateManagment/Auth'
import { timestamp, db } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CheckIcon from '../../images/icons/check-icon.png';

const PersonaDashboard = () => {
  // Context
  const [auth] = useContext(Auth);

  // State
  const [outflowRange, setOutflowRange] = useState(3);
  const [outflowPersonas, setOutflowPersonas] = useState([]);
  const [displayCorrection, setDisplayCorrection] = useState(false);
  const [correctStartTime, setCorrectStartTime] = useState('');
  const [correctEndTime, setCorrectEndTime] = useState('');
  const [makeCorrection, setMakeCorrection] = useState(false);
  const [absent, setAbsent] = useState(false);
  const [absentReason, setAbsentReason] = useState('');
  const [correctionReason, setCorrectionReason] = useState('');

  // Hooks
  const history = useHistory();
  const secundairyColor = useSettings().SecundairyColor;

  // Firestore
  const personas = useFirestoreGeneral('Personas', 'Status', 'active');
  const presence = useFirestore('PersonaPresence');
  const presenceReports = useFirestore('PersonaPresenceReports');

  // Helper functions
  const convertDateFormat = (dateString) => {
    const parts = dateString.split('-');
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  // Get today's date in word format and long format
  const today = new Date();
  const dayName = new Intl.DateTimeFormat('en-EN', { weekday: 'long' }).format(today);
  const dutchDayName = new Intl.DateTimeFormat('nl-NL', { weekday: 'long' }).format(today);
  const capitalizedDutchDayName = dutchDayName.charAt(0).toUpperCase() + dutchDayName.slice(1);
  const longName = new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(today);
  const hours = today.getHours();
  const minutes = today.getMinutes();

  // Filter presence for today
  const presenceToday = () => {
    const array = [];

    presence && presence.forEach(presence => {
      const presenceTimes = presence.PresenceTimes || {};

      if (presenceTimes[dayName.toLowerCase()] && presenceTimes[dayName.toLowerCase()].start && presenceTimes[dayName.toLowerCase()].end) {
        const object = {
          name: presence.PersonaID,
          start: presenceTimes[dayName.toLowerCase()].start,
          end: presenceTimes[dayName.toLowerCase()].end
        };
        array.push(object);
      }
    });

    return array;
  };

  // Outflow range handler
  const outflowRangeHandler = (e) => {
    const value = e.target.options[e.target.selectedIndex].value;
    setOutflowRange(parseInt(value));
  };

  // Filter personas
  useEffect(() => {
    const array = [];

    personas && personas.forEach(persona => {
      const endDate = new Date(persona.EndDate);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - endDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= outflowRange * 30) {
        array.push(persona);
      }
    });

    setOutflowPersonas(array);
  }, [personas, outflowRange]);

  // Save presence
  const savePresence = (e) => {
    const personaID = e.target.dataset.personaid;
    const start = e.target.dataset.start;
    const end = e.target.dataset.end;

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: personaID,
        Start: start,
        End: end,
        Date: new Date(),
        CompagnyID: client,
        Timestamp: timestamp,
        Type: 'correct',
        ID: uuid(),
        Author: auth.ID,
      })
      .then(() => {
        e.target.innerHTML = 'Opgeslagen';
        setTimeout(() => {
          e.target.innerHTML = 'Ja';
        }, 2000);
      });
  };

  // Save correction
  const saveCorrection = (e) => {
    const personaId = e.target.dataset.personaid;

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: personaId,
        Start: correctStartTime,
        End: correctEndTime,
        Date: new Date(),
        CompagnyID: client,
        Timestamp: timestamp,
        Reason: correctionReason,
        ID: uuid(),
        Type: 'correction',
        Author: auth.ID,
      })
      .then(() => {
        setDisplayCorrection(false);
      });
  };

  // Save absent
  const saveAbsent = (e) => {
    const personaId = e.target.dataset.personaid;

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: personaId,
        Date: new Date(),
        CompagnyID: client,
        Timestamp: timestamp,
        ID: uuid(),
        Type: 'absent',
        Reason: absentReason,
        Author: auth.ID,
      })
      .then(() => {
        setAbsent(false);
        setMakeCorrection(false);
      });
  
  }

  // Show checked
  const showChecked = (presence) => {
    let checked = false;

    presenceReports && presenceReports.forEach(report => {
      if (report.PersonaID === presence.name && report.Date.toDate().toDateString() === new Date().toDateString()) {
        checked = true;
      }
    });

    return checked;
  };

  // Show not checked
  const showNotChecked = () => {

    const array = [];

    return ''

  }

    

  return (
    <div className="main">
      <div className="main-container" >
        <div className='page-header'>
          <AspectRatioOutlinedIcon />
          <h1>Overzicht</h1>
        </div>

        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <CalendarTodayOutlinedIcon className='icon' />
            <h2>Aanwezigheid</h2>
          </div>
          <div id='persona-dashboard-presence-today-container'>
            <p id='persona-dashboard-day-name'>{capitalizedDutchDayName}</p>
            <p>{longName}</p>
            <div id='persona-dashboard-presence-today-time-container'>
              <p>{hours}:</p>
              <p>{minutes}</p>
            </div>
          </div>

          <div>
            {presenceToday().map((presence, index) => (
              <div key={index} id='dashboard-presence-container'>
                <div id='dashboard-presence-name-container'>
                  <PersonaNameMeta personaID={presence.name} />
                </div>

                <div id='dashboard-presence-times-container'>
                  <p>{presence.start}</p>
                  <p>-</p>
                  <p>{presence.end}</p>
                </div>

                <div id='check-precense-container'>
                  {showChecked(presence) ?
                    <div id='presence-is-checked-notice-container'>
                      <CheckOutlinedIcon />
                      <p>Gecontroleerd</p>
                    </div>
                    :
                    <div>
                      <div id='register-presence-container'>
                        <div className='register-precense-options-container'>
                          <img src={CheckIcon} alt="erasor" id='register-presence-check-icon' data-personaid={presence.name} data-start={presence.start} data-end={presence.end} onClick={savePresence} />
                          <p data-personaid={presence.name} data-start={presence.start} data-end={presence.end} onClick={savePresence}>Correct</p>
                        </div>
                        <div className='register-precense-options-container'>
                          <img src={ErasorIcon} alt="erasor" onClick={() => setMakeCorrection(!makeCorrection)} />
                          <p onClick={() => setMakeCorrection(!makeCorrection)}>Correctie</p>
                        </div>
                        <div className='register-precense-options-container'>
                          <img src={XIcon} alt="x" onClick={() => setAbsent(!absent)} />
                          <p onClick={() => setAbsent(!absent)}>Afwezig</p>
                        </div>
                      </div>
                      <div id='register-presence-container' style={{ display: makeCorrection ? 'flex' : 'none' }}>
                        <p>Correctie</p>
                        <input type="time" onChange={(e) => setCorrectStartTime(e.target.value)} />
                        <p>-</p>
                        <input type="time" onChange={(e) => setCorrectEndTime(e.target.value)} />
                        <input type="text" placeholder='Reden correctie' onChange={(e) => setCorrectionReason(e.target.value) } />
                        <button className='button-simple' data-personaid={presence.name} onClick={saveCorrection}>Opslaan</button>
                      </div>
                      <div style={{ display: absent ? 'flex' : 'none' }}>
                        <p>Reden afwezigheid</p>
                        <select name="" id="" onChange={(e) => setAbsentReason(e.target.options[e.target.selectedIndex].value)}>
                          <option value="ziek">-- Selecteer reden afwezigheid --</option>
                          <option value="ziek">Ziek</option>
                          <option value="verlof">Verlof</option>
                          <option value="overig">Overig</option>
                        </select>
                        <button className='button-simple' data-personaid={presence.name} onClick={saveAbsent}>Opslaan</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
          {/* <div>
            <div className="title-edit-container home-section-title-container">
              <h3>Te controleren</h3>
            </div>
            <div>
              {showNotChecked()}
            </div>
          </div> */}
        </div>

        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <CircleOutlinedIcon className='icon' />
            <h2>Status deelnemers</h2>
          </div>
          <StatusData />
        </div>

        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container">
            <RouteOutlinedIcon className='icon' />
            <h2>Trajecten</h2>
          </div>
          <RouteData />
        </div>

        <div className="home-section-container">
          <div className="title-edit-container home-section-title-container dashboard-outflow-title-container">
            <ForkRightOutlinedIcon className='icon' />
            <h2>Uitstroom binnen
              <select name="" id="" onChange={outflowRangeHandler}>
                <option value="3">3</option>
                <option value="6">6</option>
                <option value="12">12</option>
              </select>
              maanden
            </h2>
          </div>
          <div>
            <table className='dashboard-outflow-table'>
              <thead>
                <tr>
                  <th style={{ backgroundColor: secundairyColor }}>Naam</th>
                  <th style={{ backgroundColor: secundairyColor }}>Einddatum</th>
                </tr>
              </thead>
              <tbody>
                {outflowPersonas && outflowPersonas.map(persona => (
                  <tr key={persona.id} onClick={() => { history.push(`/${client}/personabuilder/${persona.ID}`) }}>
                    <td>{persona.Name}</td>
                    <td>{convertDateFormat(persona.EndDate)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaDashboard;