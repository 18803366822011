import LeftSideBarAuthProfile from "./LeftSideBarAuthProfile"
import ChatScreen from "../AI/ChatScreen"
import Topbar from "../topbar/Topbar"

const LayoutSettings = ({children}) => {
  return (
    <div className="main">
        <LeftSideBarAuthProfile />
        <div id='page-container'>
            <Topbar />
            {children}
        </div>
        <ChatScreen />
    </div>
  )
}

export default LayoutSettings