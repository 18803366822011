import Fields from './Fields'
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'

const Section = ({field, index, setFieldIndex, fieldIndex, compagnyId, questionaire}) => {

    const sectionFields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'SectionID', field.ID, 'Position', 'asc')

  return (
    <div id='section-outer-container' style={{display: index !== fieldIndex ? 'none' : 'block' }}>
      <div id='section-container' style={{borderLeft: `5px solid ${field.Color}`, display: index !== fieldIndex ? 'none' : 'flex' }} >
          <h2>{field.Question}</h2>
          <p>{field.Description}</p>
          {sectionFields && sectionFields.map((field, index) => (
              <Fields field={field} compagnyId={compagnyId} questionaire={questionaire}/>
          ))}
          
      </div>
      <div className="questionnaire-button-container">
        <button onClick={() => setFieldIndex(fieldIndex - 1)}>Terug</button>
        <button onClick={() => setFieldIndex(index + 1)}>Volgende</button>
      </div>
    </div>
  )
}

export default Section