import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ActivityMeta from "./ActivityMeta";
import { useState, useContext } from 'react'
import { db } from "../../firebase/config";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useFirestore } from '../../firebase/useFirestore'
import Tooltip from "../../components/common/Tooltip";

const SelectActivity = ({output}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [editActivity, setEditActivity] = useState(false)

    // Firestore
    const activities = useFirestore('Activities')

     // Handle activity
     const activityHandler = (e) => {
        const activityID = e.target.options[e.target.selectedIndex].value
        const docid = e.target.dataset.docid

        db.collection('Outputs')
        .doc(docid)
        .update({
            ActivityID: activityID
        })
        .then(() => {
            setSaved('flex')
            setEditActivity(false)
        })

    }
  return (
    <div className="table-edit-container">
        <div id='edit-term-container' style={{display: editActivity ? 'flex' : 'none'}} >
            <select name="" id="" data-docid={output.docid} defaultValue={output.Activity} onChange={activityHandler}>
                <option value="">-- Selecteer activiteit --</option>
                {activities && activities.map((activity) => (
                    <option value={activity.ID}>{activity.Activity}</option>
                ))}
            </select>
        </div>
        <p style={{display: editActivity ? 'none' : 'block'}}><ActivityMeta output={output}/></p>
        <Tooltip content={'Activiteit aanpassen'} top='-60px'>
            <EditOutlinedIcon onClick={() => setEditActivity(!editActivity)}/>
        </Tooltip>
    </div>
  )
}

export default SelectActivity