import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import firebase from 'firebase/app';

const ParagraphBarChartResponsive = ({field, moment, startDate, endDate}) => {
    const [data, setData] = useState('')

    // Default start and end date if dates are undefined
    const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2021, 0, 1));
    const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

    // Get wordcount for graphdata
  const getwords = async (item) => {

    let words = 0
    
    await db.collection('AnalysisWords')
        .where('CompagnyID', '==', client)
        .where('FieldID', '==', field ? field : '123')
        .where('MomentID', '==', moment ? moment : '123')
        .where('CategoryID', '==', item ? item : '123')
        // .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
        // .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)
        .get()
        .then((querySnapshot) => {
            words = querySnapshot.size

        })

    return words
  }

// Get categories for graphdata
  const getCategories = async () => {

    const array = [];

    const querySnapshot = await db
        .collection('AnalysisCategories')
        .where('CompagnyID', '==', client ? client : '123')
        .where('FieldID', '==', field ? field : '123')
        .get();

    const promises = querySnapshot.docs.map(async (doc) => {
        const object = {
        name: doc.data().Categorie,
        color: doc.data().Color,
        score: await getwords(doc.data().ID),
        };

        return object;
    });

    array.push(await Promise.all(promises))

    return array;
  }

  useEffect(() => {
      getCategories().then((data) => {
          setData(data)
      })
  },[])

  const getPath = (x, y, width, height) => {
    return `M${x},${y}
    L${x + width},${y}
    L${x + width},${y + height}
    L${x},${y + height}
    Z`;
  };
  
  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <ResponsiveContainer width="90%" height={300}>
        <BarChart
        data={data && data[0]}
        margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="score" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
            {data ? data[0].map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
            ))
            : null}
        </Bar>
        </BarChart>
    </ResponsiveContainer>
  )
}

export default ParagraphBarChartResponsive