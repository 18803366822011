import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useFirestoreOrderBy } from "../../firebase/useFirestore";
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { db, timestamp } from "../../firebase/config.js"
import uuid from "react-uuid";
import { client } from "../../hooks/Client"
import { useContext, useState } from "react";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import Tooltip from "../../components/common/Tooltip";
import Settings from "../../hooks/Settings";

const PersonaSettings = () => {
    // State
    const [hoverIndex, setHoverIndex] = useState(null);
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Hooks
    const { secundairyColor } = Settings()

    // Firestore
    const routes = useFirestoreOrderBy('PersonaRoutes', 'Position', 'asc')

    const addRoute = () => {

        db.collection('PersonaRoutes')
        .add({
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Title: '',
            Position: routes.length + 1,
        })

    }

    const changeRouteTitle = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRoutes')
        .doc(docid)
        .update({
            Title: e.target.value
        })
    }

    const codeHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRoutes')
        .doc(docid)
        .update({
            Code: e.target.value
        })
    }

    const tariffHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRoutes')
        .doc(docid)
        .update({
            Tariff: parseInt(e.target.value)
        })
    }

    const unitHandler = (e) => {

        const docid = e.target.dataset.docid
        const value = e.target.options[e.target.selectedIndex].value

        db.collection('PersonaRoutes')
        .doc(docid)
        .update({
            Unit: value
        })
    }

    const deleteRoute = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRoutes')
        .doc(docid)
        .delete()
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <SettingsOutlinedIcon/>
                <h1>Instellingen</h1>
            </div>
            <div class="table-container dashboard-container">
                <h2>Trajecten</h2>
                <div className='list-top-row-container'>
                    <Tooltip content={'Nieuw traject toevoegen'} width={'30px'} top='35px'>
                        <img  src={plusButton} alt="" onClick={addRoute} />
                    </Tooltip>
                </div>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        <th style={{backgroundColor: secundairyColor}}>CODE</th>
                        <th style={{backgroundColor: secundairyColor}}>TARIEF</th>
                        <th style={{backgroundColor: secundairyColor}}>EENHEID</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {routes && routes.map((route, index) => (
                            <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={route}
                            input={
                                <Tooltip content={route.Title} width='80%' top='35px'>
                                    <input type="text" data-docid={route.docid} defaultValue={route.Title} onChange={changeRouteTitle} />
                                </Tooltip>
                            }
                            parentIndex={index}
                            collection={'PersonaRoutes'}
                            itemArray={routes}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={route.Title}
                            setPlaceholderIndex={setPlaceholderIndex}
                            > 
                                <td>
                                    <input type="text" data-docid={route.docid} defaultValue={route.Code} onChange={codeHandler} />
                                </td>
                                <td>
                                    <input type="number" data-docid={route.docid} defaultValue={route.Tariff} onChange={tariffHandler} />
                                </td>
                                <td>
                                    <select value={route.Unit} data-docid={route.docid} onChange={unitHandler}>
                                        <option value=""> -- Selecteer een eenheid --</option>
                                        <option value="hour">Uur</option>
                                        <option value="halfday">Dagdeel</option>
                                        <option value="day">Dag</option>
                                        <option value="week">Week</option>
                                        <option value="month">Maand</option>
                                        <option value="year">Jaar</option>
                                    </select>
                                </td>
                                <td>
                                    <Tooltip content={'Verwijder traject'} width='80%' top='-60px'>
                                        <img className='table-delete-icon' data-id={route.ID} data-docid={route.docid} data-deletename={route.Title} onClick={deleteRoute} src={deleteIcon} alt="" />
                                    </Tooltip>
                                </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
            </div>

            <div class="table-container dashboard-container">
                <h2>Rapportage standaard</h2>

            </div>

            <div class="table-container dashboard-container">
                <h2>Rapportage tags</h2>

            </div>
        </div>
    </div>
  )
}

export default PersonaSettings