import { useFirestoreID } from "../../../firebase/useFirestore"
import LiveReportDashboardMedia from "../LiveReportDashboardMedia";
import OutputData from "../../dashboard/OutputData";

const PrintLiveReportActivities = ({activity, startDate, endDate}) => {

    // Firestore
    const activities = useFirestoreID('Activities', activity)

  return (
    <div className="report-activities-container">
        {activities && activities.map(item => (
                <div className="print-report-activity-item-container" style={{borderBottom: activities.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="print-report-activity-item-description-container">
                    <h3>{item.Activity}</h3>
                    <div id='print-description-content-container'>
                        <p>{item.Description}</p>
                        <LiveReportDashboardMedia parent={item}/>
                    </div>
                </div>
                <div className="print-report-activity-item-graph-container">
                    <h3>Aantal</h3>
                    <OutputData activity={item.ID} startDate={startDate} endDate={endDate} graphType='line' />
                </div>
            </div>
        ))}
    </div> 
  )
}

export default PrintLiveReportActivities