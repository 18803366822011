import { useFirestoreID } from '../../firebase/useFirestore'

const QuestionaireMeta = ({id}) => {
    
    const questionnaires = useFirestoreID('Questionnaires', id)

    return(
        <div>
            {questionnaires && questionnaires.map(questionnaire => (
                <p>{questionnaire.Title}</p>
            ))}
        </div>
    )
}

export default QuestionaireMeta