import { useContext, useEffect, useState } from 'react';
import { Onboarding } from '../../StateManagment/Onboarding';
import { useHistory } from "react-router-dom";
import { client } from '../../hooks/Client';
    
const OnboardingModal = ({selector, children, title, text, margin}) => {
    // Context
    const { onboarding, setOnboarding, step, setStep } = useContext(Onboarding);

    // Hooks
    const history = useHistory()

    const target = document.querySelector(selector);

    useEffect(() => {
        if(target && onboarding){
            target.style.boxShadow = '1px 4px 26px rgba(99, 202, 220, 0.9)';
            target.style.borderRadius = '5px';

            // Dynamically create and manage overlay for blur effect
            const overlay = document.createElement('div');
            overlay.className = 'blur-overlay';
            }
    }
    , [target, onboarding])

    const closeOnboarding = () => {
        setOnboarding(false)
        target.style.boxShadow = 'none';
        target.style.border = 'none';

        history.push(`/${client}/home`)
    }

    const nextOnboardingStep = () => {
        setStep(step + 1)
        target.style.boxShadow = 'none';
        if(step + 1 === 3){
            history.push(`/${client}/organisationcontext`)
        }
        if(step + 1 === 5){
            history.push(`/${client}/home`)
        }
       
    }

    const previousOnboardingStep = () => {
        setStep(step - 1)
        target.style.boxShadow = 'none';
    }

    const ProgressBar = () => {
        return (
            <div id='onboarding-progress-bar-container'>
                <div id='onboarding-progress-bar'>
                    <div id='onboarding-progress-bar-fill' style={{width: `${step/5*100}%`}}></div>
                </div>
            </div>
        )
    }
   
  return (
    <>
        {onboarding && 
            <div id='onboarding-overlay-container' style={{margin: margin}}>
                <div id='onboarding-item-container'>
                    {children}
                    <div id='onboarding-item-text-container'>
                        <ProgressBar />
                        <h2>{title}</h2>
                        <p>{text}</p>
                        <div id='onboaring-modal-button-container'>
                            <button id='onboarding-modal-close-button' onClick={closeOnboarding}>Sluiten</button>
                            <button onClick={previousOnboardingStep}>Vorige</button>
                            {step === 5 
                                ? 
                                <button onClick={closeOnboarding}>Voltooien</button> 
                                :
                                <button onClick={nextOnboardingStep}>Volgende</button>
                            }      
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
    
  )
}

export default OnboardingModal