import Breadcrumb from "../../components/common/Breadcrumb";
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import deleteIcon from '../../images/icons/delete-icon.png'
import useSettings from "../../hooks/Settings";
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import Tooltip from "../../components/common/Tooltip";
import { useFirestoreOrderBy } from "../../firebase/useFirestore";
import { useState, useContext, useEffect } from "react";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import sendIcon from '../../images/icons/send-icon.png'
import { db, timestamp } from "../../firebase/config.js"
import { v4 as uuid } from 'uuid';
import DraggableTableItems from "../../components/common/DraggableTableItems";
import plusButton from '../../images/icons/plus-icon.png'
import DeleteModal from "../../components/common/DeleteModal";
import { ResearchSystemMessage, ResearchToolCallContent, ResearchSaveMessage } from "../../hooks/impactAI/Prompts"
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from "../../StateManagment/Auth";
import settingsIcon from "../../images/icons/settings-icon.png";
import robotImage from "../../images/icons/robot-icon.png"
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

const Research = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [researchID, setResearchID] = useState('')
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Hooks
    ScrollToTop()
    const history = useHistory()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const researches = useFirestoreOrderBy('Research', 'Position', 'asc')

     //ImpactAI
     const pageSystemMessage = ResearchSystemMessage()
     const pageToolCallContent = ResearchToolCallContent()
     const pageSaveMessage = ResearchSaveMessage()
     const pageStartMessage = `Welkom bij de onderzoeken, ${auth?.ForName}. Hier gaan we onderzoeken creëeren die we kunnen gebruiken om jullie impact te meten.
     Mag ik een voorstel doen?`
     const pageCollection = 'Research'
     const pageField = 'Title'
     const pageParentId = ''
     const pageType = 'research'


    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages([])
    }, [auth])

     // Delete modal 
     const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename
        const id = e.target.dataset.id

        setDeleteDocid(docid)
        setDeleteName(deleteName)
        setResearchID(id)

        setDeleteModal(!deleteModal)
    }

    const deleteResearch = (e) => {
        
        db.collection('Research')
        .doc(deleteDocid)
        .delete()
        .then(() => {
            // Delete measure moments
            db.collection('MeasureMoments')
            .where('ResearchID', '==', researchID)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    doc.ref.delete()
                })
            })
        })

    }

    const addResearch = (e) => {

        const id = uuid()

        db.collection('Research')
        .doc()
        .set({
            ID: id,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Title: 'Onderzoek ' + (researches.length + 1),
            QuestionnaireID: '',
            Position: researches.length + 1
        })
    }

    const changeResearchTitle = (e) => {
        const docid = e.target.dataset.docid
        const title = e.target.value

        db.collection('Research')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
         })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, ga je gang!')
    }

  return (
     <div className="main">
    <div className="main-container">
        <div className="page-header">
            <BiotechOutlinedIcon/>
            <h1>Onderzoeken</h1>
        </div>
        <div className="table-container dashboard-container">
            <div className='list-top-row-container'>
                <Tooltip content={'Nieuw onderzoek toevoegen'} width={'30px'} top='35px'>
                    <img  src={plusButton} alt="" onClick={addResearch} />
                </Tooltip>
                <Tooltip content={'Kan ik je helpen?'} width={'30px'} top='-10px' left='30px'>
                    <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                </Tooltip>
            </div>
            <table>
                <tr>
                    <th style={{backgroundColor: secundairyColor}}>ONDERZOEK</th>
                    {/* <th style={{backgroundColor: secundairyColor}}>VRAGENLIJST</th>
                    <th style={{backgroundColor: secundairyColor}}>MEETMOMENTEN</th> */}
                    <th style={{backgroundColor: secundairyColor}}>ONDERZOEK STARTEN</th>
                    <th style={{backgroundColor: secundairyColor}}>RESULTATEN</th>
                    {/* <th style={{backgroundColor: secundairyColor}}>INZICHTEN</th> */}
                    <th style={{backgroundColor: secundairyColor}}>INSTELLINGEN</th>
                    <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                </tr>
                {researches && researches.map((research, index) => (
                        <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={research}
                        input={
                            <Tooltip content={research.Title} width='80%' top='35px'>
                                <input type="text" data-docid={research.docid} defaultValue={research.Title} onChange={changeResearchTitle} />
                            </Tooltip>
                        }
                        parentIndex={index}
                        collection={'Research'}
                        itemArray={researches}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={research.Title}
                        setPlaceholderIndex={setPlaceholderIndex}
                        > 
                            {/* <td>
                                <Questionnaire research={research}/>
                            </td>
                            <td>
                                <MeasureMoments research={research}/>
                            </td> */}
                            <td>
                                <img src={sendIcon} alt="" className="table-delete-icon" onClick={() => history.push(`/${client}/sharequestionnaire/${research.QuestionnaireID}/${research.ID}`)}/>
                            </td>
                            <td>
                                <BarChartOutlinedIcon className="table-delete-icon" onClick={() => history.push(`/${client}/ResearchResults/${research.ID}`)}/>
                            </td> 
                            {/* <td>
                                <LightbulbOutlinedIcon className="table-delete-icon" onClick={() => history.push(`/${client}/ResearchAnalysis/${research.ID}`)}/>
                            </td>  */}
                             <td>
                                <Tooltip content={'Bekijk onderzoek'} width='80%' top='-60px'>
                                    <img className='table-delete-icon' src={settingsIcon} alt="eye icon" onClick={() => {history.push(`/${client}/researchbuilder/${research.ID}`)}} />
                                </Tooltip>
                            </td>  
                            <td>
                                <Tooltip content={'Verwijder onderzoek'} width='80%' top='-60px'>
                                    <img className='table-delete-icon' data-id={research.ID} data-docid={research.docid} data-deletename={research.Title} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                                </Tooltip>
                            </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
        </div>
        <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteResearch} deleteName={deleteName} />
        <Breadcrumb
        section={'Meten'}
        previousPage={'Meetplan'}
        previousPageUrl={'measurementplan'}
        nextPage={'Vragenlijsten'}
        nextPageUrl={'questionnaires'}
        counter={1}
        totalSteps={4}
        />
      </div>
    </div>
  )
}

export default Research