import firebase from 'firebase/app';
import { useState, useContext, useEffect, useRef } from "react"
import { Auth } from '../../StateManagment/Auth';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { db } from "../../firebase/config.js"
import { useFirestoreGeneralOrderBy, useFirestoreOrderBy } from "../../firebase/useFirestore";
import { client } from "../../hooks/Client";
import uuid from "react-uuid";
import { timestamp } from "../../firebase/config";
import robotImage from "../../images/icons/robot-icon.png"
import deleteImage from "../../images/icons/delete-icon.png"
import sendIcon from "../../images/icons/send-icon-gray.png"
import spinner from '../../images/spinner-ripple.svg'
import { useHistory } from 'react-router-dom';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import Tooltip from "../common/Tooltip";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import useSettings from "../../hooks/Settings";

const ChatScreen = () => {

    // Context
    const [auth] = useContext(Auth)
    const [saved, setSaved] = useContext(SavedIcon)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        parentId: [parentId, setParentId],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
        position: [position, setPosition],
        extraData: [extraData, setExtraData]
    } = useContext(ImpactAI);

    // State
    const [loading, setLoading] = useState(false)
    const [loadingType, setLoadingType] = useState('')
    const [saving, setSaving] = useState(false)
    const [assistentThread, setAssistentThread] = useState('')
    const [message, setMessage] = useState('')
    const [sidebarWidth, setSidebarWidth] = useState(250)

    // Refs
    const textareaRef = useRef(null);
    const chatContainerRef = useRef(null);

    // Helpers
    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
    const history = useHistory();
    const SecColor = useSettings().SecundairyColor

    // Firebase
    const messages = useFirestoreGeneralOrderBy('Messages', 'Type', type ? type : '', 'Position', 'asc')
    const questionnaires = useFirestoreOrderBy('Questionnaires', 'Position', 'asc')

    // Always scroll to the bottom of the chat when a new message is added
    const smoothScrollToBottom = () => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            const start = chatContainer.scrollTop;
            const end = chatContainer.scrollHeight;

            const change = end - start;
            const duration = 400; // Duration in milliseconds
            let startTime = null;

            function animation(currentTime) {
            if (!startTime) {
                startTime = currentTime;
            }
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1);

            chatContainer.scrollTop = start + change * progress;

            if (timeElapsed < duration) {
                requestAnimationFrame(animation);
            }
            }

            requestAnimationFrame(animation);
        }
    };

    useEffect(() => {
        smoothScrollToBottom();
      }, [messages]);
    
    // Send message by pressing enter
    useEffect(() => {
        // Attach the keypress event listener to the document
        document.addEventListener('keypress', enterKey);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('keypress', enterKey);
        };
      }, [message]);

    const enterKey = (e) => {
        if(e.key === 'Enter' && document.activeElement === textareaRef.current){
            sendMessageHandler()
        }
    }

    // Create an array of messages sctructurend for the OpenAI API
    const messagesArray = () => {

        const array = []

        // Set the system message
        array.push(systemMessage)

        // Set the start message
        array.push({"role": 'assistant', "content": startMessage})

        // Set the messages from the database
        messages && messages.map(message => {

            const object = {"role": message.Role, "content": message.Message}
           
            array.push(object)

        })

        // Push to state
        setAssistentThread(array)

    }

    useEffect(() => {
        messagesArray()
    }, [messages])

    const sendMessageHandler = async (e) => {

        // Display the loading icon
        setLoading(true)
        setLoadingType('thinking')

        // Set the message in the input field to an empty string
        setMessage('')

        // Save the message in the database
        saveNewMessage(message)

        console.log(assistentThread)
        // Get an instance of the function using the httpsCallable method
        const completionOnCall = firebase.functions().httpsCallable('completionOnCall') 

        // Call the function and pass the data
        const result = await completionOnCall({messages: assistentThread})

        // The result of the function is the answer
        const answer = result.data

        console.log(answer)

        const toolCalls = answer.tool_calls;
        const response = answer.content

        console.log(toolCalls)
        console.log(response)

       // Make sure toolCalls exists and is an array
        if (Array.isArray(toolCalls) && toolCalls.length) {
            // Use a for...of loop for async/await
            for (const toolCall of toolCalls) {
                if (toolCall.function.name === 'save') {
                    toolCallSave()
                } 
            }
        }

        if(response) {
            // Set the loading icon to display none
            setLoading(false)

            // Save the answer in the database
            saveResponse(response)     
        } 

    }  
    
    // Function to handle the save to database toolcall
    const toolCallSave = () => {
        console.log('save');

         // Set the loading icon to display none
         setLoading(true)
         setLoadingType('saving')

        // Get an instance of the JSON function using the httpsCallable method
        const completionOnCallJSON = firebase.functions().httpsCallable('completionOnCallJSON') 

        // Create a new message object with the toolCallContent as content
        const newMessage = { role: "user", content: toolCallContent };

        // Update the thread with the new message
        const updatedThread = [...assistentThread, newMessage];

        console.log(updatedThread);

        // Call the completion function with the updated thread
        completionOnCallJSON({ messages: updatedThread })
        .then((summary) => {
            console.log(summary);

            // Suggestion is always returned as JSON
            const suggestion = summary.data.content;

            // Parse the suggestion to an array
            const array = [JSON.parse(suggestion)]

            console.log(array);
            console.log(type)
            console.log(docid)

            // If a specific field needs to be updated
            if(type === 'goal' || type === 'problem' || type === 'context' || type.startsWith('liveReportBuilder')) {
                array && array.map(item => {
                    saveSuggestionDocid(item.item);
                })
            // If a new document needs to be created
            } else if (type === 'stakeholder') {
                array && array[0].array.map(item => {

                    const set = {
                        Organisation: item.item,
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        Position: array.length + 1,
                        Categorie: item.category
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'activity') {
                array && array[0].array.map(item => {

                    const set = {
                        Activity: item.item,
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        Position: array.length + 1,
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'effect') {
                array && array[0].array.map(item => {

                    const set = {
                        Effect: item.item,
                        Position: array.length + 1,
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp
                    }

                    saveSuggestion(set);
                })
                
            } else if (type === 'indicator') {
                array && array[0].array.map(item => {

                    const set = {
                       Compagny: client,
                        CompagnyID: client,
                        ID: uuid(),
                        Timestamp: timestamp,
                        QuestionnaireID: '',
                        Type: 'scale',
                        Question: item.item,
                        Explainer: '',
                        ReachStart: 0,
                        ReachStartLable: '',
                        ReachEnd: 5,
                        ReachEndLabel: '',
                        Multiple: [],
                        SectionID: '',
                        EffectId:  firebase.firestore.FieldValue.arrayUnion(parentId),
                        Indicator: 'true'
                    }

                    saveSuggestion(set);
                })
                
            } else if (type === 'output') {

                array && array[0].array.map(item => {

                    const set = {
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        ActivityID: item.activityID,
                        Title: item.item,
                        Position: array.length + 1,
                        Color: '#47acc3'
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'questionaire') {

                array && array[0].array.map(item => {

                    const set = {
                        ID: uuid(),
                        Timestamp: timestamp,
                        Compagny: client,
                        CompagnyID: client,
                        EvidenceBased: false,
                        Position: array.length + 1,
                        Title: item.item,
                    }

                    saveSuggestion(set);
                })
            } else if (type.startsWith('questionaireFields')) {

                array && array[0].array.map(item => {

                    const set = {
                        Compagny: client,
                        CompagnyID: client,
                        ID: uuid(),
                        Timestamp: timestamp,
                        QuestionnaireID: parentId,
                        Type: 'scale',
                        Question: item.item,
                        Explainer: '',
                        ReachStart: 1,
                        ReachStartLable: 'Helemaal niet',
                        ReachEnd: 5,
                        ReachEndLabel: 'Helemaal wel',
                        Key: uuid(),
                        Position: position,
                        Multiple: [],
                        SectionID: ''
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'research') {

                array && array[0].array.map(item => {

                    const questionnaireID = uuid()

                    const set = {
                        ID: uuid(),
                        Timestamp: timestamp,
                        Compagny: client,
                        CompagnyID: client,
                        QuestionnaireID: questionnaireID,
                        QuestionnaireTitle: '',
                        Position: array.length + 1,
                        Title: item.item,
                    }

                    const then = () => db.collection('Questionnaires')
                    .doc()
                    .set({
                        ID: questionnaireID,
                        Timestamp: timestamp,
                        Compagny: client,
                        CompagnyID: client,
                        EvidenceBased: false,
                        Position: questionnaires.length + 1,
                        Title: 'Vragenlijst' + ' ' + item.item,
                    })

                    saveSuggestion(set, then);
                })
            }else if (type === 'categorizeResponse') {

                array && array[0].array.map(item => {

                    const ID = uuid()

                    const set = {
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        FieldID: parentId,
                        ResearchID: '',
                        Categorie: item.item,
                        Color: '#47acc3',
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'researchBuilder') {

                array && array[0].array.map(item => {

                    const set = {
                        ResearchID: parentId,
                        Moment: new Date(),
                        Title: item.item,
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        QuestionnaireID: extraData,
                        Responses: 0,
                        Position: position
                    }

                    saveSuggestion(set);
                })
            }else if (type === 'liveReportTitle') {

                array && array[0].array.map(item => {

                    const set = {
                        Title: item.item,
                        Timestamp: timestamp,
                        Compagny: client,
                        CompagnyID: client,
                        Position: array.length + 1,
                        ID: uuid(),
                        Banner: '',
                        TargetgroupTitle: 'Onze doelgroep',
                        ActivityTitle: 'Onze activiteiten',
                        KPITitle: 'Onze impact',
                        Start: null,
                        End: null
                    }

                    saveSuggestion(set);
                })
            }
        })
        .catch((error) => {
            // It's good practice to also handle any potential errors
            console.error('Error when calling completionOnCall:', error);
            setLoading(false)
            saveResponse(`Er is iets mis gegaan: ${error}: ${timestamp.toDate().toLocaleDateString("nl-NL", options)}. Probeer het opnieuwd en mocht het probleem zich blijven voordoen neem dan contact op met info@decocs.nl`)
        });

    }

    // Handle the message input field
    const messageHandler = (e) => {

        // Set the message in the input field to the state
        setMessage(e.target.value)

        // Create an object with the role and the content of the message
        const object = {"role": "user", "content": e.target.value}

        // Push the object to the state
        setAssistentThread([...assistentThread, object])

    }

    // Save the new message in the database
    const saveNewMessage = async (message) => {

        await db.collection('Messages')
        .doc()
        .set({
            Message: message,
            Timestamp: timestamp,
            CompagnyID: client,
            User: auth?.ID,
            ID: uuid(),
            Type: type,
            Role: 'user',
            Position: messages.length + 1,
            ID: uuid()
        })
    }

    // Save the response in the database
    const saveResponse = (response) => {

        db.collection('Messages')
        .doc()
        .set({
            Message: response,
            Timestamp: timestamp,
            CompagnyID: client,
            User: auth?.ID,
            ID: uuid(),
            Type: type,
            Role: 'assistant',
            Position: messages.length + 2,
            ID: uuid()
        })
    }

    // Save the suggestion
    const saveSuggestion = ( set, then) => {

        db.collection(collection)
        .doc()
        .set(set)
        .then(() => {
            setSaving(false)
            setLoading(false)
            setSaved(true)
            saveResponse(saveMessage)
            setLoadingType('none')
            
        })
        .then(() => {
            console.log('suggestion saved')
        })
        .then(() => {
            then && then()
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
        });
    }

    // Save the suggestion with the docid
    const saveSuggestionDocid = (suggestion) => {

        console.log(suggestion)
        console.log(docid)
        console.log(collection)

        db.collection(collection)
        .doc(docid)
        .update({
            [field]: suggestion
        })
        .then(() => {
            setSaving(false)
            setSaved(true)
            saveResponse(saveMessage)
            setLoadingType('none')
            
        })
        .then(() => {
            console.log('Suggestion saved')
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
        });
    }

    // Set the style of the message
    const messageStyle = (role) => {

        if(role === 'user') {
            return 'message-container-user'
        } else {
            return 'message-container-assistant'
        }
    }

    // Set the image of the message
    const messageImage = (role) => {

        if(role === 'user') {
            return auth?.Photo
        } else {
            return robotImage
        }
    }

    // Delete a message
    const deleteMessage = (e) => {

        const docid = e.target.dataset.docid 

        db.collection('Messages')
        .doc(docid)
        .delete()

    }

    // Set the font weight of the message
    const messageFontWeigth = (position) => {

        if(position === 0){
            return 'bold'
        }
    }

    // Display the delete icon
    const displayDeleteIcon = (position) => {

        if(position === 0){
            return 'none'
        } else {
            return 'block'
        }
    }

    // Set example message to the input field
    const sendExampleMessage = (e) => {

        const message = e.target.dataset.message

        // Set the message to the input field and the state
        setMessage(message)

    }

    // Set the hightlighted text
    useEffect(() => {
        setMessage(hightlightText)
    }, [hightlighted])

    // Resize the sidebar
    // const setWidth = (e) => {
    //     const startX = e.clientX;
    //     const startWidth = sidebarWidth;

    //     const currentWidth = startWidth - (e.clientX - startX);

        
    //     if (currentWidth >= 100 && currentWidth <= 400) {
    //       setSidebarWidth(currentWidth);
    //     }
    //   };

    return (
        <div id='impactai-toggle-container'>
            <div id='leftsidebar-toggle-item-container' >
                <div style={{display: chatOpen ? 'block' : 'none'}} onClick={() => setChatOpen(false)}>
                    <Tooltip content='ImpactAI inklappen' onClick={() => setChatOpen(false)}>
                        <KeyboardDoubleArrowRightOutlinedIcon onClick={() => setChatOpen(false)}/>
                    </Tooltip>
                </div>
                <div style={{display: chatOpen ? 'none' : 'block'}} onClick={() => setChatOpen(true)}>
                    <Tooltip content='ImpactAI uitklappen' >
                        <KeyboardDoubleArrowLeftOutlinedIcon onClick={() => setChatOpen(true)} className='impactai-toggle'/>
                    </Tooltip>
                </div>
            </div>
            <div className="impactai-container" style={{width: chatOpen ? '350px' : '0px'}}>
                <div id='impactai-logo-container' style={{display: chatOpen ? 'flex' : 'none'}}>
                    <img src={robotImage} alt="" id='robot-image-chatscreen'/>
                    <h3>Chat met ImpactAI</h3>
                </div> 
                {/* <div id='chatscreen-helpdesk-container' style={{display: open ? 'flex' : 'none'}}>
                    <p>Kom je er niet uit? Contact de <a href={`/${client}/helpdesk`}>helpdesk</a></p>
                </div> */}
                

                <div className="leftsidebar-seperator"></div>

                <div id='chat-screen-container' style={{display: chatOpen ? 'flex' : 'none'}}>
                    <div id='chat-text-container' ref={chatContainerRef}>
                        <div className='message-container-assistant'>
                            <img src={robotImage} alt="" />
                            <p style={{fontWeight: 'bold'}}>{startMessage}</p>
                        </div>
                        {messages && messages.map(message => (
                            <div key={message.ID} className={messageStyle(message.Role)}>
                                <img src={messageImage(message.Role)} alt="" />
                                <p style={{fontWeight: messageFontWeigth(message.Position)}} dangerouslySetInnerHTML={{__html: message.Message}}></p>
                                <img id='message-delete-icon' data-docid={message.docid} src={deleteImage} alt="" onClick={deleteMessage} style={{display: displayDeleteIcon(message.Position)}} />
                            </div>
                        ))}
                        <div style={{display: loading ? 'flex' : 'none'}} className="message-container-assistant">
                            <img src={robotImage} alt="" />
                            <p style={{display: loadingType === 'thinking' ? 'block' : 'none'}}>Nadenken... &#129504;</p>
                            <div id='chatscreen-saving-container' style={{display: loadingType === 'saving' ? 'flex' : 'none'}}>
                                <img src={spinner} alt="" />
                                <p>Opslaan...</p>
                            </div>
                            <p style={{display: loadingType === 'redirect' ? 'block' : 'none'}}>Doorsturen...</p>
                        </div>
                   
                    </div>
                    <div id='chat-input-container' style={{boxShadow: hightlighted ? '0 0 8px green' : 'none'}}>
                        <textarea type="text" ref={textareaRef} placeholder="Stuur een bericht" value={message} onChange={messageHandler} />
                        <img src={sendIcon} alt="" onClick={sendMessageHandler}/>
                    </div>
                    <div id='example-messages-container' style={{display: exampleMessages.length > 1 ? 'flex' : 'none'}}>
                        <p id='example-question-title'>Voorbeeld vragen:</p>
                        <div id='example-messages-messages-container'>
                            {exampleMessages && exampleMessages.map(message => (
                                <div key={uuid()} className='example-message' data-message={message} onClick={sendExampleMessage}>
                                    <p>{message}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
      )

}

export default ChatScreen
