import Location from "../../hooks/Location"
import { useFirestoreID, useFirestoreGeneralTwo, useFirestoreQuestionnaireFields } from "../../firebase/useFirestore";
import { useState, useEffect, useContext } from "react";
import useSettings from "../../hooks/Settings";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { PersonaBuilderSystemMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from "../../StateManagment/Auth";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import Tooltip from "../../components/common/Tooltip";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client";
import CategorizeFieldNotice from "../../components/researchResults/CategorizeFieldNotice";
import FieldResponsesCountPersonas from "../../components/personas/FieldResponsesCountPersonas";

const ReserachResultsPersonas = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen],
      hightlighted: [hightlighted, setHightlighted],
      hightlightText: [hightlightText, setHightlightText],
  } = useContext(ImpactAI);

 //State
 const [questionnaireID, setQuestionnaireID] = useState('')
 const [researchTitle, setResearchTitle] = useState('')
 const [title, setTitle] = useState('')
 const [personaTitle, setPersonaTitle] = useState('')
const [resultType, setResultType] = useState('forms')
const [forms, setForms] = useState([])

 // Hooks
 const researchId = Location()[3]
 const personaId = Location()[4]
 const secundairyColor = useSettings().SecundairyColor
 const history = useHistory()
 const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

 // Firestore
 const research = useFirestoreID('Research', researchId ? researchId : '')
 const fields = useFirestoreQuestionnaireFields(questionnaireID ? questionnaireID : '')
 const personas = useFirestoreID('Personas', personaId ? personaId : '') 
 const results = useFirestoreGeneralTwo('QuestionnairesResponses', 'ResearchID', researchId ? researchId : '', 'Persona', personaId ? personaId : '')

 // ImpactAI
 const pageSystemMessage = PersonaBuilderSystemMessage()
 const pageStartMessage = `Hoi ${auth?.ForName}, welkom bij de onderzoeksanalyse van onderzoek ${title}. 
 Hier kun je de resultaten van het onderzoek inzien en de eventuele open vragen categoriseren.
 Kan ik je ergens mee helpen?`
 const pageType = 'personabuilder'
 const paegExampleMessages = ['Hoe kan ik een open vraag analyseren?']

 useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(paegExampleMessages)
 }, [auth, title])

 // Reset hightlights
 useEffect(() => {
     setHightlighted(false)
     setHightlightText('')
 },[])

 // Set research title
 useEffect(() => {
     research && research.forEach(item => {
         setQuestionnaireID(item.QuestionnaireID)
         setResearchTitle(item.Title)
     })
 },[research])

    // Set persona title
    useEffect(() => {
        personas && personas.forEach(item => {
            setPersonaTitle(item.Name)
        })
    },[personas])

// Sort forms from responses by date
const sortForms = () => {
    const groupedForms = {};
  
    results && results.forEach(item => {
        const timestamp = item.Timestamp.toDate().getTime();

        if (!groupedForms[timestamp]) {
            groupedForms[timestamp] = [];
        }
        groupedForms[timestamp].push(item.Timestamp);
    });
  
    // Convert object keys to an array and sort it in descending order
    const sortedTimestamps = Object.keys(groupedForms).sort((a, b) => b - a);

    // Create a new array where each element is an array of items, sorted by latest first
    const sortedGroupedForms = sortedTimestamps.map(timestamp => groupedForms[timestamp]);
   
    return sortedGroupedForms;
    
  };

  useEffect(() => {
    const sortedForms = sortForms(); // Sort forms by date
    setForms(sortedForms);
  }, [results]);

return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>{researchTitle}</h1>
                    <h2>{personaTitle}</h2>
                </div>
                <div className='table-container table-container-dashboard'>
                    <div className="select-activity-container select-activity-container-wizard">
                        <div className="select-activity-inner-container">
                            <div className='activity-select-item-container' onClick={() => setResultType('forms')} style={{backgroundColor: resultType === 'forms' ? secundairyColor : 'white'}}>
                                <p onClick={() => setResultType('forms')} style={{color:  resultType === 'forms' ? 'white' : '#616161'}}>Formulieren</p>
                            </div>
                            <div className='activity-select-item-container' onClick={() => setResultType('questions')} style={{backgroundColor: resultType === 'questions' ? secundairyColor : 'white'}}>
                                <p onClick={() => setResultType('questions')} style={{color:  resultType === 'questions' ? 'white' : '#616161'}}>Lijst met vragen</p>
                            </div>
                        </div>
                    </div>
                    {resultType === 'questions' &&
                        <table>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}>VRAAG</th>
                                <th style={{backgroundColor: secundairyColor}}>RESPONSES</th>
                                <th style={{backgroundColor: secundairyColor}}>VISUALISATIES</th>
                            </tr>
                            {fields && fields.map(item => (
                                <tr>
                                    <td>
                                        <p>{item.Position} {item.Question}</p>
                                    </td>
                                    <td>
                                        <div id='reserach-overview-responses-container' onClick={() => {history.push(`/${client}/researchresponsespersonas/${item.ID}/${researchId}/${personaId}`)}}>
                                            <Tooltip content='Bekijk de responses' top={'-60px'}>
                                                <ChatBubbleOutlineOutlinedIcon className='table-delete-icon'/>
                                            </Tooltip>
                                            <FieldResponsesCountPersonas fieldID={item.ID} researchID={researchId} personaID={personaId}/>
                                        </div>
                                    </td>
                                    <td>
                                        <Tooltip content='Bekijk de visualisaties' top={'-60px'}>
                                            <AssessmentOutlinedIcon className='table-delete-icon' onClick={() => {history.push(`/${client}/researchresultsvisualisationspersonas/${item.ID}/${researchId}/${personaId}`)}}/>
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    }
                    {resultType === 'forms' &&
                        <table>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                                <th style={{backgroundColor: secundairyColor}}>RESPONSES</th>
                            </tr>
                            {forms && forms.map(item => (
                                <tr>
                                    <td>
                                        <p>{item[0].toDate().toLocaleDateString("nl-NL", options)} </p>
                                    </td>
                                    <td>
                                        <div id='reserach-overview-responses-container' onClick={() => {history.push(`/${client}/researchresultspersonasforms/${item[0]}/${researchId}/${personaId}`)}}>
                                            <Tooltip content='Bekijk de responses' top={'-60px'}>
                                                <ChatBubbleOutlineOutlinedIcon className='table-delete-icon'/>
                                            </Tooltip>
                                            <p>({item.length})</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    }   
                </div>
                <Breadcrumb
                section={'Meten'}
                previousPage={'Onderzoeken'}
                previousPageUrl={'Research'}
                nextPage={''}
                nextPageUrl={''}
                counter={2}
                totalSteps={5}
                />
            </div>
        </div>
    )
}

export default ReserachResultsPersonas