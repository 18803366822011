import { useEffect, useState } from 'react'
import { useFirestoreAll, useFirestoreGeneral, useFirestore} from './firebase/useFirestore'
import { db, timestamp } from './firebase/config'
import { v4 as uuid } from 'uuid'
import { auth } from './firebase/config'
import firebase from 'firebase'

const UpdateDB = () => {

  const effects = () => {

    db.collection('KpiData')
    .get()
    .then((querySnapshot) => {
      console.log(querySnapshot.size)
      querySnapshot.forEach((data) => {
        data.data().Field &&
        db.collection('QuestionnaireFields')
        .where('ID', '==', data.data().Field)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.collection('QuestionnaireFields').doc(doc.id).update({
              EffectId: firebase.firestore.FieldValue.arrayUnion(data.data().KpiID)
          })
        })
      })
        .then(() =>{
          console.log('db updated')
        
        })
      })
    })

  }

  const updateIndicators = () => {
    db.collection('Outputs')
    .get()
    .then((querySnapshot) => {
      console.log(querySnapshot.size)
      querySnapshot.forEach((data) => {
        if(!Array.isArray(data.data().EffectId) && data.data().EffectId){
          db.collection('Outputs').doc(data.id).update({
              EffectId: firebase.firestore.FieldValue.arrayUnion(data.data().EffectId)
          })
        }
      })
    })
    .then(() =>{
      console.log('db updated')
    
    })
  }

  const updateIndicators2 = () => {
    db.collection('QuestionnaireFields')
    .where('CompagnyID', '==', '1b4ec-1b35-566d-04ea-747e46201d2')
    .get()
    .then((querySnapshot) => {
      console.log(querySnapshot.size)
      querySnapshot.forEach((data) => {
        if(Array.isArray(data.data().EffectId) && data.data().EffectId?.length > 0){
          data.data().EffectId.map((item) => {
          db.collection('OutputEffects')
          .where('ID', '==', item)
          .get()
          .then((snapshot) => {
          console.log(snapshot.size)
          if(snapshot.size === 0){
            db.collection('QuestionnaireFields').doc(data.id).update({
              EffectId: firebase.firestore.FieldValue.arrayRemove(item)
            })
            .then(() => {
              console.log('db updated')
            
            })
            }
          })
          })
        }
      })
    })
    
  }




  useEffect(() => {
    // effects()
    // updateIndicators2()
  }
  ,[])
    

 

  return (
    <div>
      Update
    </div>
  )
}

export default UpdateDB