import {useState, useEffect, useContext} from 'react'
import { Responses } from "../../StateManagment/Responses";
import { useFirestoreGeneralOrderBy } from "../../firebase/useFirestore";

const FieldType = ({field}) => {
    const [responses, setResponses] = useContext(Responses)

    const [range, setRange] = useState(0)

    const multipleOptions = useFirestoreGeneralOrderBy('MultipleQuestionOptions', 'Field', field.ID, 'Position', 'asc')
    const matrixRows = useFirestoreGeneralOrderBy('MatrixQuestionRows', 'Field', field.ID, 'Position', 'asc')
    const matrixColumns = useFirestoreGeneralOrderBy('MatrixQuestionColumns', 'Field', field.ID, 'Position', 'asc')

    const start = Number(field.ReachStart)
    const end = Number(field.ReachEnd)

    useEffect(() => {

    if(field.Type === 'scale'){
        const range = (start, end) => {
            return Array(end - start + 1).fill().map((_, idx) => start + idx)
        }

        const result = range(start, end)
          
        setRange(result)
    }

    }, [field])
    
    // Update the responses
    const updateResponses = (fieldID, input, optionType, itemid) => {

        // Find the previous state of the respons 
        const previousResponses = responses.filter(response => response.FieldID === fieldID)

        // If the response already excists, remove it
        if(previousResponses.length > 0){
            // Remove previous response
            const newResponses = responses.filter(response => response.FieldID !== fieldID)
            // Update the state without the previous response
            setResponses(newResponses)
            // Add new response
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid }])
        } else {
            // If the response doesn't excist, add it
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid}]);
        }
    }

    // Handle deselect for the multi matrix
    const handleDeselectMultiMatrix = (isSelected, fieldID, input, optionType, inputType, itemid) => {

        // If the input is selected, add it to the responses
        if(isSelected){
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid}])

        // If the input is deselected, remove it from the responses
        } else {
            
            const filteredResponses = responses.filter(item => !(item.Itemid === itemid && item.Input === input));

            setResponses(filteredResponses)
            
        }
    }

    // Handle deselect for the multiple choice with multiple answers
    const handleDeselectMultiMulti = (isSelected, fieldID, input, optionType, inputType, itemid) => {

        // If the input is selected, add it to the responses
        if(isSelected){
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: optionType, Itemid: itemid}])

        // If the input is deselected, remove it from the responses
        } else {

            responses.forEach((item, index) => {

                console.log(item.Itemid, itemid)

                if(item.Itemid === itemid){
                    console.log(item, index)
                    const filteredRespones = responses.splice(index, 1);
                    console.log(filteredRespones)
                }
            })

            const filteredResponses = responses.filter(item => item.Itemid !== itemid );

            setResponses(filteredResponses)
            
        }
    }

    // Update the responses from the other input at the multiple-multiple field
    const updateMultiMultiOtherInput = (fieldID, input, itemid) => {

        // Find the previous state of the respons
        const previousResponses = responses.filter(response => response.FieldID === fieldID && response.OptionType === 'other')

        // If the response already excists, remove it   
        if(previousResponses.length > 0){
            // Remove previous response
            const newResponses = responses.filter(response => response.item.Itemid !== itemid)
            // Update the state without the previous response
            setResponses(newResponses)
            // Add new response
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: 'other', itemid: itemid }])
        } else {
            // If the response doesn't excist, add it
            setResponses(prevResponses => [...prevResponses, { FieldID: fieldID, Input: input, OptionType: 'other' }]);
        }
    }


    // Handle the input
    const inputHandler = (e) => {
        const input = e.target.value
        const fieldID = e.target.dataset.id

        if(field.Type === 'paragraph'){

            updateResponses(fieldID, input)

        } else if(field.Type === 'scale'){

            // Convert the input to a number
            const numberInput = parseInt(input)

            updateResponses(fieldID, numberInput)

        } else if(field.Type === 'multiple-multiple'){ 
            const optionType = e.target.dataset.optiontype 
            const inputType = e.target.dataset.inputtype
            const itemid = e.target.dataset.itemid

            // If the input is an other input, update the responses
            if(inputType === 'otherInput'){
                updateResponses(fieldID, input, optionType, itemid)
            } else {

            // If the input is a checkbox input, handle select or deselect
            const isSelected = e.target.checked
            handleDeselectMultiMulti(isSelected, fieldID, input, optionType, inputType, itemid)
            }
        } else if(field.Type === 'multiple-one'){
            const optionType = e.target.dataset.optiontype
            const itemid = e.target.dataset.itemid

            updateResponses(fieldID, input, optionType, itemid)
        } else if(field.Type === 'matrix-multiple'){
            const isSelected = e.target.checked
            const itemid = e.target.dataset.itemid
            const optionType = e.target.dataset.optiontype
            const inputType = e.target.dataset.inputtype
            handleDeselectMultiMatrix(isSelected, fieldID, input, optionType, inputType, itemid)
        }

    }

    // Return the correct input field
    if(field.Type === 'paragraph'){
        return (
            <textarea 
            cols="30" 
            rows="10" 
            placeholder='Geef hier uw antwoord'
            data-id={field.ID} 
            onChange={inputHandler}>
            </textarea>
        )
        } else if(field.Type === 'scale'){
            return (
                <div id='scale-container'>
                    {field.ReachStartLable}
                    {range && range.map(btn => (
                        <div key={field.ID}id='question-type-label-container'>
                            <input 
                            type="radio"
                            name={field.ID} 
                            value={btn} 
                            data-id={field.ID}
                            onChange={inputHandler}/>
                            <label htmlFor={btn}>{btn}</label>
                        </div>
                    ))}
                    {field.ReachEndLabel}
                </div>
            )
        } else if(field.Type === 'multiple-multiple'){
            return(
                <div id='options-container'>
                    {multipleOptions && multipleOptions.map(item => (
                        <span key={item.ID}>
                           <input
                            className='question-checkbox-input'
                            type="checkbox"
                            id={item.ID}
                            name='options'
                            data-itemid={item.ID}
                            data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                            data-inputtype={'checkboxInput'}
                            data-id={field.ID}
                            value={item.Option}
                            onChange={inputHandler}
                            />
                            <label htmlFor={item.Option}>{item.Option === 'other' ? 'Anders...' : item.Option}</label>
                            <input 
                            type="text" 
                            data-id={field.ID} 
                            data-itemid={item.ID}
                            data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                            data-inputtype={'otherInput'}
                            style={{display: item.Option === 'other' ? 'block' : 'none'}} 
                            placeholder='Vul hier je antwoord in' 
                            onChange={inputHandler}/>
                        </span> 
                    ))}
                </div>
            )
        } else if(field.Type === 'multiple-one'){
            return(
                <div id='options-container'>
                    {multipleOptions && multipleOptions.map(item => (
                        <span  key={item.ID}>
                            <input 
                            className='question-checkbox-input' 
                            type="radio" 
                            id={item.ID} 
                            name='option'
                            data-itemid={item.ID}
                            data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                            data-id={field.ID} 
                            value={item.Option} 
                            data-type={item.Option}
                            onChange={inputHandler}/>
                            <label htmlFor={item.Option}>{item.Option === 'other' ? 'Anders, namelijk..' : item.Option}</label>
                            <input 
                            type="text" 
                            data-id={field.ID} 
                            data-itemid={item.ID}
                            data-optiontype={item.Option === 'other' ? 'other' : 'normal'}
                            style={{display: item.Option === 'other' ? 'block' : 'none'}} 
                            placeholder='Vul hier je antwoord in' 
                            onChange={inputHandler}/>
                        </span> 
                    ))}
                </div>
            )
    }else if(field.Type === 'matrix-multiple'){
        return(
            <div id='options-container'>
                <div className='matrix-column-title-container'>
                    {matrixColumns && matrixColumns.map(column => (
                        <div key={column.ID}>
                            <p>{column.Title}</p>
                        </div>
                    ))}
                </div>
                {matrixRows && matrixRows.map(row => (
                    <div id='matrix-row-container'>
                        <p>{row.Title}</p>
                        {matrixColumns && matrixColumns.map(column => (
                            <div  key={column.ID} id='matrix-column-container'>
                                <input 
                                className='question-checkbox-input' 
                                type="checkbox" 
                                id={column.ID} 
                                data-id={field.ID}
                                data-itemid={column.ID}
                                name={column.Title} 
                                value={`${column.Title}/${row.Title}`}
                                onChange={inputHandler}/>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )
    } else {
        return null
    }
}

export default FieldType