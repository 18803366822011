import React from 'react'
import { useFirestoreID } from '../../firebase/useFirestore'

const ActivityMeta = ({output}) => {

    const activities = useFirestoreID('Activities', output.ActivityID)

  return (
    <>
        {activities && activities.map(item => (
            <p key={item.ID}>{item.Activity}</p>
        ))}
    </>
  )
}

export default ActivityMeta