import { 
    useFirestore, 
    useFirestoreOrderBy
} from "../../firebase/useFirestore";
import ScrollToTop from "../../hooks/ScrollToTop";
import arrowUpIcon from '../../images/icons/arrow-up-icon.png'
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { TheoryofChangeSystemMessage } from "../../hooks/impactAI/Prompts"
import useSettings from "../../hooks/Settings";

const TheoryOfChange = () => {
    // Context
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        startMessage: [startMessage, setStartMessage],
        exampleMessages: [exampleMessages, setExampleMessages],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // Hooks
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const primairyColor = useSettings().PrimaryColor

    // Firestore
    const goals = useFirestore('Goals')
    const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc')
    const activities = useFirestore('Activities')
    const outputs = useFirestore('Outputs')

    //ImpactAI
    const pageSystemMessage = TheoryofChangeSystemMessage()
    const pageStartMessage = `Welkom bij de Theory of Change, ${auth?.ForName}. De Theory of Change is een visuele weergave van de impactstrategie.
    Hier zie je hoe de activiteiten, outputs en effecten leiden tot je impactdoel. De Impactstrategie is nu afgerond. Je kunt door naar de volgende stap: Meten.`
    const pageExampleMessages = []
    const pageType = 'TheoryOfChange'

    useEffect(() => {
       setType(pageType)
       setSystemMessage(pageSystemMessage)
       setStartMessage(pageStartMessage)
       setExampleMessages(pageExampleMessages)
   }, [auth])

   // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Function to add opacity to HEX colors
    const addOpacityToHexColor = (color, opacity) => {
        // Convert opacity to a two-digit hexadecimal number
        const alphaHex = Math.round(opacity * 255).toString(16).padStart(2, '0');
        return `${color}${alphaHex}`;
    };

    const effectTermText = (term) => {
        console.log(term)
        if (term === 'short') {
            return <p>Korte termijn</p>
        } else if (term === 'long') {
            return <p>Lange termijn</p>
        } else {
            return <></>
        }
    }

    const effectTermClass = (term) => {
        if(term === 'short') {
            return 'effect-term-short'
        } else if (term === 'long') {
            return 'effect-term-long'
        } else {
            return ''
        }
    }

    const termContainerBackgroundColor = (term) => {
        if(term === 'short') {
            return addOpacityToHexColor(primairyColor, 0.7) 
        } else if (term === 'long') {
            return addOpacityToHexColor(secundairyColor, 0.7)
        } else {
            return ''
        }
    }

    console.log(primairyColor, secundairyColor)


    return (
        <div className="main">
            <div className="main-container" >
                <div className="page-header" >
                    <ChangeCircleOutlinedIcon />
                    <h1>Theory of Change</h1>
                </div>
                <div className="toc-container">

                    {/* Goal */}
                    <div className="toc-sub-container">
                        <div className='activity-meta-title-container'>
                            <FlagOutlinedIcon className="menu-icon" />
                            <h3>Impactdoel</h3>
                        </div>
                        <div className="toc-items-container">
                            {goals && goals.map(goal => (
                                <div key={goal.ID} className='impact-dashboard-output-container' id='toc-goal-item-container'>
                                    <p>{goal.Title}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <img className="arrow-up-icon" src={arrowUpIcon} alt=""/>

                    {/* Effecten */}
                    <div className="toc-sub-container">
                        <div className='activity-meta-title-container'>
                            <PodcastsOutlinedIcon className="menu-icon" />
                            <h3>Effecten</h3>
                        </div>
                        <div className="toc-items-container">
                            {effects && effects.map(effect => (
                                <div key={effect.ID} className='impact-dashboard-output-container toc-item-container'>
                                    <p className='output-title'>{effect.Effect}</p>
                                    <div className={`${effectTermClass(effect.Term)}`} style={{backgroundColor: termContainerBackgroundColor(effect.Term)}}>
                                        {effectTermText(effect.Term)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                    </div>

                    <img className="arrow-up-icon" src={arrowUpIcon} alt="" />

                    {/* Outputs */}
                    <div className="toc-sub-container">
                        <div className='activity-meta-title-container'>
                            <OutputOutlinedIcon className="menu-icon" />
                            <h3>Outputs</h3>
                        </div>
                        <div className="toc-items-container">
                            {outputs && outputs.map(output => (
                                <div key={output.ID} className='impact-dashboard-output-container toc-item-container'>
                                    <p className='output-title'>{output.Title}</p>
                                </div>
                            ))} 
                        </div>
                    </div>

                    <img className="arrow-up-icon" src={arrowUpIcon} alt="" />

                    {/* Activities */}
                    <div className="toc-sub-container">
                        <div className='activity-meta-title-container'>
                            <MonitorHeartOutlinedIcon className="menu-icon" />
                            <h3>Activiteiten</h3>
                        </div>
                        <div className="toc-items-container">
                            {activities && activities.map(activity => (
                                <div className='impact-dashboard-output-container toc-item-container' key={activity.ID}>
                                    <p className='output-title'>{activity.Activity}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Breadcrumb
                section={'Impactstrategie'}
                previousPage={'Effecten'}
                previousPageUrl={'effects'}
                nextPage={'Meten'}
                nextPageUrl={'measurementplan'}
                counter={8}
                totalSteps={8}
                />
            </div>
        </div>
    )
}

export default TheoryOfChange
