import personasImage from '../../images/personas.png'
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { PersonasExplainerSystemMessage } from "../../hooks/impactAI/Prompts"
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"

const PersonasExplainer = () => {
 // Context
 const [auth] = useContext(Auth)
 const {
     type: [type, setType],
     systemMessage: [systemMessage, setSystemMessage],
     startMessage: [startMessage, setStartMessage],
     exampleMessages: [exampleMessages, setExampleMessages],
     chatOpen: [chatOpen, setChatOpen]
 } = useContext(ImpactAI);

 // Hooks
 const history = useHistory()

 //ImpactAI
 const pageSystemMessage = PersonasExplainerSystemMessage()
 const pageStartMessage = `Welkom bij Personas, ${auth?.ForName}. 
 Kan ik iets voor je verduidelijken?`
 const pageExampleMessages = ['Wat zijn personas?']
 const pageType = 'personasExplainer'

 useEffect(() => {
    setType(pageType)
    setSystemMessage(pageSystemMessage)
    setStartMessage(pageStartMessage)
    setExampleMessages(pageExampleMessages)
}, [auth])

 return (
   <div>
   <div className='section-explainer-header-image-container'>
     <img src={personasImage} alt="" />
   </div>
   <div className='section-explainer-text-container'>
     <h1>Deelnemers</h1>
     <div>
       <p>Welkom bij de deelnemers, {auth?.ForName}. Hier kun je deelnemers volgen en hun impact meten.</p>
     </div>
   </div>
   <Breadcrumb
   section={'Personas'}
   previousPage={'Home'}
   previousPageUrl={'documentation'}
   nextPage={'Personas overzicht'}
   nextPageUrl={'personas'}
   counter={0}
   totalSteps={1}
   />
 </div>
  )
}

export default PersonasExplainer