import { Auth } from '../../StateManagment/Auth';
import { useState, useContext, useEffect } from 'react';
import {useFirestoreNoCompagnyGeneralOrderBy } from "../../firebase/useFirestore"
import { useHistory } from "react-router";
import Location from '../../hooks/Location';
import { db } from '../../firebase/config';

const SwitchAccount = () => {
    // Context
    const [ authO ] = useContext(Auth)

    // State
    const [userCompagnies, setUserCompagnies] = useState([])

    // Hooks
    const history = useHistory()
    const id = Location()[1]
    const compagnies = authO?.Compagny ? authO.Compagny : []

    const alphabeticalCompagnyList = () => {

        // Reset the array
        setUserCompagnies([])

        // Get the community name for each compagny
        compagnies && compagnies.map(compagny => {
            db.collection('CompagnyMeta')
            .where('CompagnyID', '==', compagny)
            .get()

            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    const communityName = doc.data().CommunityName
                    const communityID = doc.data().CompagnyID

                    const object = {
                        communityName: communityName,
                        communityID: communityID
                    }

                    // Add the community name to the array
                    setUserCompagnies(prevCompagnies => [...prevCompagnies, object]);
                })
            })
        })
    }

    useEffect(() => {
        alphabeticalCompagnyList()
    }, [compagnies])

    // Order the array alphabetically
    const sortedArray = userCompagnies.sort((a, b) => {
        if (a.communityName < b.communityName) {
            return -1;
        }
        if (a.communityName > b.communityName) {
            return 1;
        }
        return 0;
    });

    // Handle the account switch
    const accountHandler = (e) => {
        const account = e.target.options[e.target.selectedIndex].value

        history.push(`/${account}/home`)

        window.location.reload()
    }

  return (
    <select onChange={accountHandler} defaultValue={id}>
        <option value="">-- Selecteer --</option>
        {sortedArray && sortedArray.map(compagny => (
            <option value={compagny.communityID}>{compagny.communityName}</option>
        ))}
    </select>
  )
}

export default SwitchAccount