import React from 'react'
import Tooltip from '../../components/common/Tooltip'
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import { useContext } from "react";
import useSettings from "../../hooks/Settings";
import settingsIcon from "../../images/icons/settings-icon.png";
import { useHistory } from "react-router-dom";
import { Auth } from '../../StateManagment/Auth'
import UserImageMeta from '../../components/user/UserImageMeta'
import UserNameMeta from '../../components/user/UserNameMeta'

const Reports = ({personaNavigation}) => {
   // Context
   const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)

   // Hooks
   const secundairyColor = useSettings().SecundairyColor
   const personaId = Location()[3]
   const history = useHistory()
   const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

   // Firestore
   const reports = useFirestoreGeneralOrderBy('PersonaReports', 'PersonaID', personaId ? personaId : '', 'Timestamp', 'desc')

   const addReport = () => {

    const reportId = uuid()

       db.collection('PersonaReports')
       .add({
           ID: reportId,
           PersonaID: personaId,
           Title: '',
           Description: '',
           Tags: [],
           CompagnyID: client,
           Compagny: client,
           Position: reports.length + 1,
           Timestamp: timestamp,
           User: auth.ID
       })   
         .then(() => {
              setSaved('flex')
              history.push(`/${client}/personareportdetail/${reportId}/${personaId}`)
         })

   }

   const deleteReport = (e) => {

       const docid = e.target.dataset.docid

       db.collection('PersonaReports')
       .doc(docid)
       .delete()
       .then(() => {
           setSaved('flex')
       })

   }

 return (
   <div style={{display: personaNavigation === 'report' ? 'block' : 'none'}}>
       <h2>Rapportages</h2>
       <div  className="persona-option-container">

           <div className='list-top-row-container'>
               <Tooltip content='Rapportage toevoegen' top='-60px'>
                   <img src={plusButton} onClick={addReport} alt="" />
               </Tooltip>
           </div>
           
           <div className='table-container'>

                <div>
                    {reports && reports.map(report => (
                        <div key={report.ID} id='persona-report-item-container'>
                            <div id='persona-report-meta-bar-container'>
                                <p><b>{report.Timestamp.toDate().toLocaleDateString("nl-NL", options)}</b></p>
                                <div id='report-user-meta-container'>
                                    <UserImageMeta userID={report.User} />
                                    <UserNameMeta userID={report.User} />
                                </div>
                            </div>
                            
                            <p dangerouslySetInnerHTML={{__html: report.Body}}></p>
                            <div id='persona-report-actions-container'>
                                <Tooltip content={'Rapportage aanpassen'} top='-40px' width={'30px'}>
                                    <img src={settingsIcon} alt="" className="table-delete-icon" onClick={() => history.push(`/${client}/personareportdetail/${report.ID}/${personaId}`)}/>
                                </Tooltip>
                                <Tooltip content={'Rapportage verwijderen'} top='-60px' width={'30px'}>
                                    <img className='table-delete-icon' data-docid={report.docid} onClick={deleteReport} src={deleteIcon} alt="" />
                                </Tooltip>
                            </div>
                        </div>
                    ))}
                    
                </div>
           </div>
       </div>
   </div>
 )
}

export default Reports