import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import firebase from "firebase";
import useSettings from "../../hooks/Settings";
import { client } from "../../hooks/Client";
import ButtonClicked from '../../hooks/ButtonClicked';
import basisImage from '../../images/packageImages/hero-3.jpg'
import professioneelImage from '../../images/packageImages/hero-4.jpg'
import compleetImage from '../../images/packageImages/hero-landing.webp'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Tooltip from '../../components/common/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Subscription = () => {

    const compagnyName = useSettings().compagnyName
    const subscription = useSettings().subscription
    const docid = useSettings().docid
    const stripeId = useSettings().stripeId
    const SecundairyColor = useSettings().SecundairyColor
    const trailDays = useSettings().trailDays
    const subscriptionType = useSettings().subscriptionType

    console.log(subscriptionType)

    const subscriptionTranslated = () => {
        if (subscription === 'trial') {
            return <div><h2>Proefabonnement <i>{subscriptionType}</i></h2></div>
        } else if (subscription === 'paid') {
            return <div><h2>Abonnement <i>{subscriptionType}</i></h2></div>
        }
    }

    const createCheckoutSession = async (e) => {

        ButtonClicked(e, 'Een moment geduld...')

        const createSession = firebase.functions().httpsCallable('createCheckoutSession')  

        const result = await createSession({client: client, docid: docid, type: subscriptionType})
       
        const data = result.data

        const url = data.url

        window.open(url);
    }

    const manageSubscription = async() => {

        const manageSubscription = firebase.functions().httpsCallable('manageSubscription')  

        const result = await manageSubscription({stripeId: stripeId, client: client,})

        window.open(result.data);

    }

    console.log(trailDays)


  return (
    <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <CardMembershipOutlinedIcon/>
                    <h1>Abonnement</h1>
                </div>
                <div className="profile-inner-container">
                    <div id='subscription-card-container'>
                        <div className="subscription-container" style={{width: subscriptionType === 'basis' ? '300px' : '250'}}>
                            <h2>Basis</h2>
                            <h3>€65 per maand</h3>
                            <img src={basisImage} alt="" />
                            <button style={{display: subscriptionType === 'basis' ? 'block' : 'none'}} onClick={manageSubscription}>Beheer</button>
                            <button style={{display: subscriptionType === 'basis' ? 'block' : 'none'}} onClick={manageSubscription}>Upgrade</button>
                            <div className="subsciption-card-features-outer-container">
                                <div className="subsciption-card-features-container">
                                    <div className="subscription-card-features-item-container">
                                        <CheckOutlinedIcon/>
                                        <p>2 gebruikers</p>
                                    </div>
                                    <Tooltip content='Extra gebruiker: €5 per gebruiker per maand' >
                                        <InfoOutlinedIcon className="subscription-card-information-icon"/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="subsciption-card-features-outer-container">
                                <div className="subsciption-card-features-container">
                                    <div className="subscription-card-features-item-container">
                                        <CheckOutlinedIcon/>
                                        <p>AI impact consultant</p>
                                    </div>
                                    <Tooltip content='Extra gebruiker: €5 per gebruiker per maand' >
                                        <InfoOutlinedIcon className="subscription-card-information-icon"/>
                                    </Tooltip>
                                </div>
                            </div>
                            
                        </div>
                        <div className="subscription-container" style={{border: subscriptionType === 'professioneel' ? '3px solid red' : 'none'}}>
                            <h2>Professioneel</h2>
                            <h3>€85 per maand</h3>
                            <img src={professioneelImage} alt="" />
                            <button style={{display: subscriptionType === 'profesioneel' ? 'block' : 'none'}} onClick={manageSubscription}>Beheer</button>
                            <button style={{display: subscriptionType === 'basis' ? 'block' : 'none'}} onClick={manageSubscription}>Upgrade</button>
                        </div>
                        <div className="subscription-container" style={{border: subscriptionType === 'compleet' ? '3px solid red' : 'none'}}>
                            <h2>Compleet</h2>
                            <h3>€150 per maand</h3>
                            <img src={compleetImage} alt="" />
                            <button style={{display: subscriptionType === 'compleet' ? 'block' : 'none'}} onClick={manageSubscription}>Beheer</button>
                            <button style={{display: subscriptionType === 'basis' ? 'block' : 'none'}} onClick={manageSubscription}>Upgrade</button>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
  )
}

export default Subscription