import React from 'react'
import ResearchData from '../Research/ResearchData';
import { useFirestoreGeneralOrderBy } from "../../firebase/useFirestore";
import HomeMultipleBarChart from "../Visualisations/HomeMultipleBarChart";
import { useState, useEffect } from "react";
import ArrowLeftIcon from "../../images/icons/arrow-left-icon.png";
import ArrowRightIcon from "../../images/icons/arrow-right-icon.png";
import Location from "../../hooks/Location"

const LiveReportResearchData = ({questionnaireId, researchId, startDate, endDate}) => {
   // State
   const [visible, setVisible] = useState(0);
   const [printPage, setPrintPage] = useState(false);

    // Hooks
    const page = Location()[2];

   // firestore
   const questionnaireFields =  useFirestoreGeneralOrderBy("QuestionnaireFields", "QuestionnaireID", questionnaireId ? questionnaireId : "123", "Position", "asc");

    // Set page type
    useEffect(() => {
        if(page === 'printlivereport'){
            setPrintPage(true);
        }
    }, [page])

    // Navigation
   const nextIndicator = () => {
       if(visible < questionnaireFields.length -1){
           setVisible(visible + 1);
       } else {
           setVisible(0);
       }
   }

   const previousIndicator = () => {
       if(visible > 0){
           setVisible(visible - 1);
       } else {
           setVisible(questionnaireFields.length -1);
       }
   }

 return (
   <>
       <div id='live-report-effect-indicator-navbar' style={{display: printPage ? 'none' : 'flex'}}>
           <div className='live-report-effect-indicator-navbar-button-container' onClick={previousIndicator}>
               <img src={ArrowLeftIcon} alt="Vorige"/>
               <p className="live-report-effect-indicator-navbar-button">Vorige</p>
           </div>
           
           <div>
               <p>{visible +1} van de {questionnaireFields.length}</p>
           </div>
           <div className='live-report-effect-indicator-navbar-button-container' onClick={nextIndicator}>
               <p className="live-report-effect-indicator-navbar-button" >Volgende</p>
               <img src={ArrowRightIcon} alt="Vorige"/>
           </div>
           
       </div>
       {/* Check if page type if print page or not */}
       {printPage 

        ? 

        <>
            {questionnaireFields.length > 0 ? questionnaireFields.map((item, index) => (
                <div key={item.ID} className='home-kpi-container'>
                    <ResearchData 
                    fieldType={item.Type} 
                    fieldId={item.ID} 
                    researchId={researchId} 
                    startDate={startDate?.toDate()} 
                    endDate={endDate?.toDate()}/>
                </div>
            ))
            :
                <HomeMultipleBarChart field={[{ID: '123'}]} moment={'123'}/>
            }
        </>

        :

        <>
            {questionnaireFields.length > 0 ? questionnaireFields.map((item, index) => (
                <div key={item.ID} className='home-kpi-container' style={{display: index === visible ? 'block' : "none"}}>
                    <ResearchData 
                    fieldType={item.Type} 
                    fieldId={item.ID} 
                    researchId={researchId} 
                    startDate={startDate?.toDate()} 
                    endDate={endDate?.toDate()}/>
                </div>
            ))
            :
                <HomeMultipleBarChart field={[{ID: '123'}]} moment={'123'}/>
            }
        </>

        }
       
   </>
 )
}

export default LiveReportResearchData