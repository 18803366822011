import { BarChart, Bar, Rectangle, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import spinner from '../../images/spinner-ripple.svg'

const OutputMultiLineGraph = ({data, isLoading, outputs, graphType}) => {

  return (
    <>
      {isLoading ? 
        <div id="graph-loading-container">
            <img src={spinner} alt="spinner" className="spinner"/>
        </div>
      :
        <ResponsiveContainer width='100%' height={400}>
          {graphType === 'bar' ?
           <BarChart
           width={500}
           height={300}
           data={data && data}
           margin={{
             top: 5,
             right: 30,
             left: 20,
             bottom: 5,
           }}
         >
           <CartesianGrid strokeDasharray="3 3" />
           <XAxis dataKey="name" />
           <YAxis />
           <Tooltip />
           {/* <Legend /> */}
           {outputs && outputs.map((output, index) => (
            <Bar dataKey={output.Title} fill={output.Color} activeBar={<Rectangle fill={output.Color} stroke={output.Color} />} />
           ))}
         </BarChart>
          :
          <LineChart
          width={500}
          height={300}
          data={data && data}
          margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
          }}
          >
              
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip/>
              {/* <Legend onClick={showOutputDetails} wrapperStyle={{cursor: 'pointer'}} iconType='line'/> */}
              {outputs && outputs.map((output, index) => (
                  <Line key={`line-${index}`} type="monotone" dataKey={output.Title} stroke={output.Color} strokeWidth='3'/>
              ))}
              <CartesianGrid strokeDasharray="3 3" />
          </LineChart>
        }
        </ResponsiveContainer>
    }
    </>
  )
}

export default OutputMultiLineGraph