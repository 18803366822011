import { useState } from "react";
import useSettings from "../../hooks/Settings";
import { client } from "../../hooks/Client";
import { useHistory } from "react-router-dom";
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';

const SegmentProgressBar = ({ steps }) => {

    // State
    const [stepTitle, setStepTitle] = useState('Organisatie omschrijving');

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const primaryColor = useSettings().PrimaryColor
    const history = useHistory()

    const stepIcon = (index) => {

        switch (index) {
            case 0:
                return <ContentPasteOutlinedIcon/>
            case 1:
                return <ExtensionOutlinedIcon/>
            case 2:
                return <PersonOutlineOutlinedIcon/>
            case 3:
                return <FlagOutlinedIcon/>
            case 4:
                return <MonitorHeartOutlinedIcon/>
            case 5:
                return <OutputOutlinedIcon/>
            case 6:
                return <PodcastsOutlinedIcon/>
            case 7:
                return <ChangeCircleOutlinedIcon/>
            default:
                return <ContentPasteOutlinedIcon/>
        }

    }
    
    return (
        <div id='section-explainer-progressbar-container'>  
            <h2 id='section-explainer-progressbar-title'>De 8 stappen</h2>
            <div className="progress-bar">
                {steps.map((step, index) => (
                    <div 
                        key={index} 
                        className="step" 
                        onMouseEnter={() => setStepTitle(step.name)}
                    >
                        <div 
                        className="circle" 
                        style={{color: step.completed ? '#00d800' : '#f77f7f', boxShadow: stepTitle === step.name ? `0px 8px 6px rgba(33, 109, 27, 0.5)` : `none`}}
                        onClick={() => history.push(`/${client}/${step.url}`)}
                        >
                            <p style={{color: step.completed ? '#00d800' : '#f77f7f'}}>{index + 1}</p>
                        </div>
                    </div>
                ))}
            </div>

            {steps.map((step, index) => (
                <div className="info" style={{display: stepTitle === step.name ? 'block' : 'none'}} >
                    <div className="activity-meta-title-container">
                        {stepIcon(index)}
                        <h3>{step.name}</h3>
                    </div>
                    <p>{step.info}</p>
                    <div id='progress-bar-impactstrategy-info-modal-button-container' >
                        <button style={{backgroundColor: secundairyColor, border: `1px solid ${secundairyColor}`}} onClick={() => history.push(`/${client}/${step.url}`)}>
                            {step.completed ? 'Bekijk' : 'Start'}
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
  };
  
  export default SegmentProgressBar;