import React from 'react'
import { useFirestoreID } from '../../firebase/useFirestore'
import LiveReportMedia from './LiveReportMedia'
import { db } from '../../firebase/config'
import deleteIcon from '../../images/icons/delete-icon.png'
import firebase from 'firebase'
import Tooltip from "../common/Tooltip";

const ReportBuilderTargetgroups = ({report, docid}) => {

    const targetgroups = useFirestoreID('Stakeholders', report && report)

    const targetgroupDescriptionHandler = (e) => {
        const docid = e.target.dataset.docid

        db.collection('Stakeholders')
        .doc(docid)
        .update({
            Description: e.target.value
        })
    }

    const deleteTargetgroup = (e) => {
        const id = e.target.dataset.id

        db.collection('LiveReports')
        .doc(docid)
        .update({
            Targetgroups: firebase.firestore.FieldValue.arrayRemove(id)
        })
    }

  return (
    <>
        {targetgroups && targetgroups.map(item => (
            <div className="report-targetgroup-item-container" style={{borderBottom: targetgroups.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="report-activity-item-description-container">
                    <h3>{item.Organisation}</h3>
                    <textarea cols="30" data-docid={item.docid} defaultValue={item.Description} placeholder="Voeg een omschrijving toe van de doelgroep" rows="10" onChange={targetgroupDescriptionHandler}></textarea>
                    <p><b>Voeg foto's of video's toe</b></p>
                    <LiveReportMedia parent={item}/>
                </div>
                <Tooltip content="Activiteit verwijderen" width='4%' top='-60px'>
                    <img className="delete-icon-report-builder" src={deleteIcon} alt="" data-id={item.ID} onClick={deleteTargetgroup}/>
                </Tooltip>
            </div>
        ))}
    </>
  )
}

export default ReportBuilderTargetgroups