import { db } from "./config.js"
import { useState, useEffect} from 'react';
import { client } from '../hooks/Client';

const useFirestoreAll = (collection) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection])  

    return docs
};

const useFirestoreAllOrderBy = (collection, field, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .orderBy(field, order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, order])  

    return docs
};

const useFirestoreAllID = (collection, id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where('ID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, id])  

    return docs
};

const useFirestoreNoCompagnyOrderBy = (collection, field, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .orderBy(field, order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, order])  

    return docs
};

const useFirestoreNoCompagnyGeneralOrderBy = (collection, field, id, fieldTwo, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where(field, "==", id)
        .orderBy(fieldTwo, order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id, fieldTwo, order])  

    return docs
};

const useFirestoreNoCompagny = (collection, field, id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where(field, "==", id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id])  

    return docs
};

const useFirestoreGeneral = (collection, field, id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "==", id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id])  

    return docs
};

const useFirestoreGeneralTwo = (collection, field, id, field2, id2) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "==", id)
        .where(field2, "==", id2)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id, field2, id2])  

    return docs
};

const useFirestoreGeneralThree = (collection, field, id, field2, id2, field3, id3) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "==", id)
        .where(field2, "==", id2)
        .where(field3, "==", id3)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id, field2, id2, field3, id3])  

    return docs
};

const useFirestoreGeneralOrderBy = (collection, field, id, field2, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "==", id)
        .orderBy(field2, order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id, field2, order])  

    return docs
};

const useFirestoreGeneralTwoOrderBy = (collection, field, id, field2, id2, field3, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "==", id)
        .where(field2, "==", id2)
        .orderBy(field3, order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id, field2, id2, field3, order])  

    return docs
};

const useFirestoreGeneralThreeOrderBy = (collection, field, id, field2, id2, field3, id3, field4, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "==", id)
        .where(field2, "==", id2)
        .where(field3, "==", id3)
        .orderBy(field4, order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id, field2, id2, field3, id3, field4, order])  

    return docs
};

const useFirestoreArrayContainsOrderBy = (collection, field, id, field2, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "array-contains", id)
        .orderBy(field2, order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id, field2, order])  

    return docs
};

const useFirestoreOrderBy = (collection, field, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .orderBy(field, order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection])  

    return docs
};

const useFirestoreGeneralArrayContains = (collection, field, id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "array-contains", id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id])  

    return docs
};

const useFirestore = (collection) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection])  

    return docs
};

const useFirestoreID = (collection, id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where("ID", "==", id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, id])  

    return docs
};

const useFirestoreTimestamp = (collection, order) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .orderBy("Timestamp", order)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection])  

    return docs
};

const useFirestoreIsNot = (collection, field, id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(collection)
        .where("CompagnyID", "==", client)
        .where(field, "!=", id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [collection, field, id])  

    return docs
};

const useFirestoreUser = (userID) => {

    const [docs, setDocs] = useState("")
    
    const docArray = []
    useEffect(() => {
        db.collection("Users")
        .where("Compagny", "array-contains", client)
        .where("ID", "==", userID)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        setDocs(docArray)

    }, [userID])  

    return docs
};

const useFirestoreUserNotApproved = (userID) => {

    const [docs, setDocs] = useState("")
    
    const docArray = []
    useEffect(() => {
        db.collection("Users")
        .where("ID", "==", userID)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        setDocs(docArray)

    }, [userID])  

    return docs
};

const useFirestoreUsers = () => {

    const [docs, setDocs] = useState("")
    
    const docArray = []
    useEffect(() => {
        db.collection("Users")
        .where("Compagny", "array-contains", client)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        setDocs(docArray)

    }, [])  

    return docs
};

const useFirestoreUsersApproved = () => {

    const [docs, setDocs] = useState("")
    
    const docArray = []
    useEffect(() => {
        db.collection("Users")
        .where("Compagny", "array-contains", client)
        .where('Approved', '==', true)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        setDocs(docArray)

    }, [])  

    return docs
};

const useFirestoreNotApproved = () => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection("Users")
        .where("Compagny", "array-contains", client)
        .where("Approved", "==", false)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [])  

    return docs
};

const useFirestoreActivities = (goal) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection("Activities")
        .where("GoalID", "==", goal)
        .where('CompagnyID', '==', client)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [goal])  

    return docs
};

const useFirestoreAdmins = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('Admins')
        .where('UserID', '==', id)
        .where('CompagnyID', '==', client)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreQuestionnaires = () => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('Questionnaires')
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [])  

    return docs
};

const useFirestoreQuestionnaireFields = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnaireFields')
        .where('CompagnyID', '==', client)
        .where('QuestionnaireID', '==', id)
        .orderBy("Position", "asc")
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreQuestionnaireAnalysis = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnaireAnalysis')
        .where('CompagnyID', '==', client)
        .where('QuestionnaireID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreQuestionnairesResponses = (id, moment) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('FieldID', '==', id)
        .where('MomentID', '==', moment)
        .orderBy("Timestamp", "asc")
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id, moment])  

    return docs
};

const useFirestoreQuestionnairesFieldsDetail = (id, moment) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnairesFields')
        .where('Compagny', '==', client)
        .where('FieldID', '==', id)
        .where('MomentID', '==', moment)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id, moment])  

    return docs
};

const useFirestoreQuestionnairesResponsesMoments = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('MomentID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};


const useFirestoreQuestionnairesResponsesResearch = (id, field) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('ResearchID', '==', id)
        .where('FieldID', '==', field)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id, field])  

    return docs
};

const useFirestoreOutputQuestionnaireFields = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnaireFields')
        .where('CompagnyID', '==', client)
        .where('OutputID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreOutputs = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('Outputs')
        .where('CompagnyID', '==', client)
        .where('ActivityID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreOutputsOrderBy = (id, item, direction) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('Outputs')
        .where('CompagnyID', '==', client)
        .where('ActivityID', '==', id)
        .orderBy(item, direction)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id, item, direction])  

    return docs
};

const useFirestoreResearch = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection("Research")
        .where('CompagnyID', '==', client)
        .where('OutputID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreMeasureMoments = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection("MeasureMoments")
        .where('CompagnyID', '==', client)
        .where('ResearchID', '==', id)
        .orderBy("Position", "asc")
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreStakeholders = (categorie) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection("Stakeholders")
        .where('CompagnyID', '==', client)
        .where('Categorie', '==', categorie)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [categorie])  

    return docs
};

const useFirestoreProblemAnalyses = (channel, id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection(channel)
        .where('CompagnyID', '==', client)
        .where('CentralProblemID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [channel, id])  

    return docs
};

const useFirestoreCompagny = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('CompagnyMeta')
        .where('CompagnyID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreOpenSourceQuestionnnaires = () => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('OpenSourceQuestionnaires')
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [])  

    return docs
};

const useFirestoreOpenSourceQuestionnnairesID = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('OpenSourceQuestionnaires')
        .where('ID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreTargetgroup = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('Stakeholders')
        .where('CompagnyID', '==', client)
        .where('Categorie', '==', 'Doelgroep')
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreAnalysisCategories = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('AnalysisCategories')
        .where('CompagnyID', '==', client)
        .where('FieldID', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreAnalysisWords = (field, moment, category) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('AnalysisWords')
        .where('CompagnyID', '==', client)
        .where('FieldID', '==', field)
        .where('MomentID', '==', moment)
        .where('CategoryID', '==', category)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [field, moment, category])  

    return docs
};

const useFirestoreAnalysisTotalWords = (category) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('AnalysisWords')
        .where('CompagnyID', '==', client)
        .where('CategoryID', '==', category)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [category])  

    return docs
};

const useFirestoreAnalysisMultipleChoise = (input, field) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('FieldID', '==', field)
        .where('Input', '==', input)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [input, field])  

    return docs
};

const useFirestoreAnalysisMultipleChoiseMoment = (moment, field, input) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('MomentID', '==', moment)
        .where('FieldID', '==', field)
        .where('Input', '==', input)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [moment, field, input])  

    return docs
};

const useFirestorePersonaSteps = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('PersonaSteps')
        .where('CompagnyID', '==', client)
        .where('PersonaID', '==', id)
        .orderBy("Position", "asc")
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestorePersonas = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('Personas')
        .where('CompagnyID', '==', client)
        .where('Output', '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreImpactManagers = (name) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('ImpactManagers')
        .where('Hostname', '==', name)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [name])  

    return docs
};

const useFirestoreKPI = () => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('OutputEffects')
        .where('CompagnyID', '==', client)
        .where('KPI', '==', 'true')
        .orderBy('Position', 'asc')
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [])  

    return docs
};


const useFirestoreLiveReportKPI = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('OutputEffects')
        .where('CompagnyID', '==', client)
        .where('KPI', '==', 'true')
        .where("ID", '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreQuestionnaireFieldName = (id) => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnaireFields')
        .where('Compagny', '==', client)
        .where("ID", '==', id)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [id])  

    return docs
};

const useFirestoreQuestionnaireFieldUpdate = () => {

    const [docs, setDocs] = useState("")

    useEffect(() => {
        const unsub = db.collection('QuestionnaireFields')
        .where('Compagny', '==', client)
        .onSnapshot(querySnapshot => {
            let docArray = []
            querySnapshot.forEach(doc => {
                docArray.push({...doc.data(), docid: doc.id})
            })
            setDocs(docArray)
        })
        
        return () => unsub();

    }, [])  

    return docs
};



export { 
    useFirestoreAll,
    useFirestoreAllOrderBy,
    useFirestoreAllID,
    useFirestoreNoCompagnyOrderBy,
    useFirestoreNoCompagnyGeneralOrderBy,
    useFirestoreNoCompagny,
    useFirestoreGeneral,
    useFirestoreGeneralTwo,
    useFirestoreGeneralThree,
    useFirestoreGeneralOrderBy,
    useFirestoreGeneralTwoOrderBy,
    useFirestoreGeneralThreeOrderBy,
    useFirestoreArrayContainsOrderBy,
    useFirestoreOrderBy,
    useFirestoreGeneralArrayContains,
    useFirestore, 
    useFirestoreID, 
    useFirestoreTimestamp, 
    useFirestoreIsNot,
    useFirestoreUser,
    useFirestoreUserNotApproved, 
    useFirestoreUsers, 
    useFirestoreUsersApproved,
    useFirestoreNotApproved,
    useFirestoreActivities,
    useFirestoreAdmins,
    useFirestoreQuestionnaires,
    useFirestoreQuestionnaireFields,
    useFirestoreQuestionnairesResponses,
    useFirestoreQuestionnairesFieldsDetail,
    useFirestoreQuestionnairesResponsesMoments,
    useFirestoreQuestionnairesResponsesResearch,
    useFirestoreQuestionnaireAnalysis,
    useFirestoreOutputQuestionnaireFields,
    useFirestoreOutputs,
    useFirestoreOutputsOrderBy,
    useFirestoreMeasureMoments,
    useFirestoreResearch,
    useFirestoreStakeholders,
    useFirestoreProblemAnalyses,
    useFirestoreCompagny,
    useFirestoreOpenSourceQuestionnnaires,
    useFirestoreOpenSourceQuestionnnairesID,
    useFirestoreTargetgroup,
    useFirestoreAnalysisCategories,
    useFirestoreAnalysisWords,
    useFirestoreAnalysisTotalWords,
    useFirestoreAnalysisMultipleChoise,
    useFirestoreAnalysisMultipleChoiseMoment,
    useFirestorePersonaSteps,
    useFirestorePersonas,
    useFirestoreImpactManagers,
    useFirestoreKPI,
    useFirestoreLiveReportKPI,
    useFirestoreQuestionnaireFieldName,
    useFirestoreQuestionnaireFieldUpdate
}