import React from 'react'
import Tooltip from '../../components/common/Tooltip'
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { useFirestore, useFirestoreGeneral } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import firebase from 'firebase'
import { useContext } from "react";
import useSettings from "../../hooks/Settings";

const Data = ({personaNavigation, item, personaDocid}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const route = Location()[3]

    // Firestore
    const routes = useFirestore('PersonaRoutes')
    const risks = useFirestoreGeneral('PersonaRisks', 'PersonaID', route)
    const networks = useFirestoreGeneral('PersonaNetwork', 'PersonaID', route)
    const data = useFirestoreGeneral('PersonaData', 'PersonaID', route)

    // Functions
    const nawOptions = [
        'Voornaam',
        'Achternaam',
        'Straatnaam',
        'Huisnummer',
        'Postcode',
        'Woonplaats',
        'Telefoonnummer',
        'Email',
        'BSN'
    ]

    const deleteEffect = (e) => {

        const item = e.target.dataset.item

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            Effects: firebase.firestore.FieldValue.arrayRemove(item)
        })

    }

    const startDateHandler = (e) => {

        const value = e.target.value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            StartDate: value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const endDateHandler = (e) => {

        const value = e.target.value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            EndDate: value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const addData = () => {

        db.collection('PersonaData')
        .add({
            ID: uuid(),
            PersonaID: route,
            Title: '',
            Value: '',
            CompagnyID: client,
            Compagny: client,
            Position: data.length + 1,
            Timestamp: timestamp
        })

    }

    const addNetwork = () => {

        db.collection('PersonaNetwork')
        .add({
            ID: uuid(),
            PersonaID: route,
            Name: '',
            PhoneNumber: '',
            CompagnyID: client,
            Compagny: client,
            Position: networks.length + 1,
            Timestamp: timestamp
        })

    }

    const addRisk = () => {

        db.collection('PersonaRisks')
        .add({
            ID: uuid(),
            PersonaID: route,
            Risk: '',
            Description: '',
            CompagnyID: client,
            Compagny: client,
            Position: risks.length + 1,
            Timestamp: timestamp
        })

    }

    const titleHandler = (e) => {

        const docid = e.target.dataset.docid
        const title = e.target.options[e.target.selectedIndex].value

        db.collection('PersonaData')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const valueHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaData')
        .doc(docid)
        .update({
            Value: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const routeHandler = (e) => {

        const value = e.target.options[e.target.selectedIndex].value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            RouteID: value
        })

    }

    const networkNameHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
           Title: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const networkRelationHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
            Relation: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const networkPhoneHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
            PhoneNumber: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const riskTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .update({
            Risk: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const riskValueHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .update({
            Description: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteNetwork = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteRisk = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

  return (
    <div style={{display: personaNavigation === 'data' ? 'block' : 'none'}}>
        <h2>Gegevens</h2>
        <div>
            <div class="table-container dashboard-container">
                <h3>NAW</h3>
                <div className='list-top-row-container'>
                    <Tooltip content='NAW-gegeven toevoegen' top='-60px'>
                        <img src={plusButton} onClick={addData} alt="" />
                    </Tooltip>
                </div>
                <div className='table-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>TITLE</th>
                            <th style={{backgroundColor: secundairyColor}}>GEGEVEN</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                        {data && data.map(item => (
                            <tr key={item.ID}>
                                <td>
                                    <select name="" id="" defaultValue={item.Title} data-docid={item.docid} onChange={titleHandler}>
                                        <option value="">-- Selecteer --</option>
                                        {nawOptions && nawOptions.map(option => (
                                            <option value={option}>{option}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Value} onChange={valueHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' data-docid={item.docid} onClick={deleteEffect} src={deleteIcon} alt="" />
                                </td>
                            </tr>
                    ))}
                    </table>
                </div>
            </div>

            <div class="table-container dashboard-container">
                <h3>Traject</h3>
                <p>Trajectnaam</p>
                <select name="" id="" onChange={routeHandler} value={item.RouteID}>
                    <option value="">-- Selecteer een traject --</option>
                    {routes && routes.map(route => (
                        <option key={route.ID} value={route.ID}>{route.Title}</option>
                    ))}
                </select>
                <p>Startdatum</p>
                <input type="date" defaultValue={item.StartDate} onChange={startDateHandler} />
                <p>Einddatum</p>
                <input type="date" defaultValue={item.EndDate} onChange={endDateHandler}/>
            </div>

            <div class="table-container dashboard-container">
                <h3>Netwerk</h3>
                <div className='list-top-row-container'>
                    <Tooltip content='Netwerk toevoegen' top='-60px'>
                        <img src={plusButton} onClick={addNetwork} alt="" />
                    </Tooltip>
                </div>
                <div className='table-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                            <th style={{backgroundColor: secundairyColor}}>RELATIE</th>
                            <th style={{backgroundColor: secundairyColor}}>TELEFOONNUMMER</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                        {networks && networks.map(item => (
                            <tr key={item.ID}>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Title} onChange={networkNameHandler}/>
                                </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Relation} onChange={networkRelationHandler}/>
                                </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.PhoneNumber} onChange={networkPhoneHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' data-docid={item.docid} onClick={deleteNetwork} src={deleteIcon} alt="" />
                                </td>
                            </tr>
                    ))}
                    </table>
                </div>
            </div>

            <div class="table-container dashboard-container">
                <h3>Risico's</h3>
                <div className='list-top-row-container'>
                    <Tooltip content='Risico toevoegen' top='-60px'>
                        <img src={plusButton} onClick={addRisk} alt="" />
                    </Tooltip>
                </div>
                <div className='table-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>RISICO</th>
                            <th style={{backgroundColor: secundairyColor}}>OMSCHRIJVING</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                        {risks && risks.map(item => (
                            <tr key={item.ID}>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Risk} onChange={riskTitleHandler}/>
                                </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Description} onChange={riskValueHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' data-docid={item.docid} onClick={deleteRisk} src={deleteIcon} alt="" />
                                </td>
                            </tr>
                    ))}
                    </table>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Data