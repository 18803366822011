import { useFirestoreGeneral } from '../../firebase/useFirestore'
import deleteIcon from '../../images/icons/delete-icon.png'
import { db } from '../../firebase/config'
import { useState } from 'react'
import uuid from 'react-uuid'
import { timestamp } from '../../firebase/config'
import ButtonClicked from '../../hooks/ButtonClicked'
import saveFile from '../../hooks/SaveFile'
import { client } from '../../hooks/Client'

const LiveReportMedia = ({parent, compagny}) => {
    const [file, setFile] = useState('')
    const [fileMeta, setFileMeta] = useState('')

    const media = useFirestoreGeneral('LiveReportMedia', 'Parent', parent.ID)

    const mediaHandler = (e) => {
        saveFile(e, setFile, setFileMeta)
    }

    const saveMedia = (e) => {
        const id = e.target.dataset.id 

        setFile('')

        ButtonClicked(e, 'Uploading...')

        db.collection('LiveReportMedia')
        .doc()
        .set({
            Media: file,
            Parent: id,
            Type: fileMeta.type,
            ID: uuid(),
            Timestamp: timestamp,
            CompagnyID: client
        })
    }

    const deleteMedia = (e) => {
        const docid = e.target.dataset.docid

        db.collection('LiveReportMedia')
        .doc(docid)
        .delete()
    }

    const displayMedia = (type, item) => {
        const filetype = type.split('/')[0]
        
        if(filetype === 'image') {
            return <img src={item.Media} alt="" />
        } else if(filetype === 'video') {
            return <video controls><source src={item.Media}/></video>
        } else if(filetype === 'audio') {
            return <audio controls><source src={item.Media}/></audio>
        }
    }

  return (
    <div>
        <div className='report-media-container' style={{display: media.length === 0 ? 'none' : 'flex'}}>
            <p><b>Gallerij</b></p>
            {media && media.map(item => (
                <div className="report-media-item-container">
                    {displayMedia(item.Type, item)}
                    <div className="report-media-item-options" >
                        <img src={deleteIcon} data-docid={item.docid} alt="" onClick={deleteMedia}/>
                    </div>
                </div>
            ))}
        </div>
        <div className='report-media-file-preview-container' style={{display: file === "" ? 'none' : 'block'}}>
            <p><b>Voorbeeld</b></p>
            {file && <img src={file} alt="" />}
            <button className="button-simple" data-id={parent.ID} onClick={saveMedia} style={{display: file !== "" ? 'block' : 'none'}}>Uploaden</button>
        </div>
        <input type="file" onChange={mediaHandler}/>
    </div>
  )
}

export default LiveReportMedia