import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"
import { useFirestore, useFirestoreOrderBy } from "../../firebase/useFirestore"
import ScrollToTop from "../../hooks/ScrollToTop";
import Breadcrumb from "../../components/common/Breadcrumb";
import useSettings from "../../hooks/Settings";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Tooltip from "../../components/common/Tooltip";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { QuestionairesSystemMessage,QuestionairesToolCallContent, QuestionairesSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { useContext, useState, useEffect } from "react";
import { db, timestamp } from "../../firebase/config.js"
import { v4 as uuid } from 'uuid';
import settingsIcon from "../../images/icons/settings-icon.png";
import deleteIcon from '../../images/icons/delete-icon.png'
import plusButton from '../../images/icons/plus-icon.png'
import DraggableTableItems from "../../components/common/DraggableTableItems";
import FieldCount from "../../components/Questionnaire/FieldCount";
import robotImage from "../../images/icons/robot-icon.png"
import DeleteModal from "../../components/common/DeleteModal";
import copyIcon from '../../images/icons/copy-icon.png'

const Questionnaires = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [name, setName] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')

     // Hooks
    ScrollToTop()
    const history = useHistory()
    const secundairyColor = useSettings().SecundairyColor
    const TertairyColor = useSettings().TertairyColor
    const id = uuid()

    // Firestore
    const questionnaires = useFirestoreOrderBy('Questionnaires', 'Position', 'asc')
    const compagny = useFirestore('CompagnyMeta')

    //ImpactAI
     const pageSystemMessage = QuestionairesSystemMessage()
     const pageToolCallContent = QuestionairesToolCallContent()
     const pageSaveMessage = QuestionairesSaveMessage()
     const pageStartMessage = `Welkom bij de vragenlijsten, ${auth?.ForName}. Hier gaan we vragenlijsten creëeren die je kan gebruiken om jullie impact te meten.
     Zal ik een voorstel doen?`
     const pageCollection = 'Questionnaires'
     const pageField = 'Title'
     const pageParentId = ''
     const pageType = 'questionaire'


    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages([])
    }, [auth])

    // Delete modal 
    const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename

        setDeleteDocid(docid)
        setDeleteName(deleteName)

        setDeleteModal(!deleteModal)
    }

    useEffect(() => {
        compagny && compagny.forEach(comp => {
            setName(comp.CommunityName)
        })
    })

    const deleteQuestionnaire = (e) => {

        db.collection('Questionnaires')
        .doc(deleteDocid)
        .delete()
        .then(() => {
            setSaved('flex')
            setDeleteModal(false)
        })
    }

    const addQuestionnaire = () => {

        console.log(questionnaires.length + 1)

        db.collection('Questionnaires')
        .doc()
        .set({
            ID: id,
            Timestamp: timestamp,
            Compagny: client,
            CompagnyID: client,
            EvidenceBased: false,
            Position: questionnaires.length + 1,
        })
    }

    const titleHandler = (e) => {
        const docid = e.target.dataset.docid 
        const title = e.target.value

        db.collection('Questionnaires')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
         })

    }

    const duplicateQuestionaire = (e) => {

        const title = e.target.dataset.title
        const id = e.target.dataset.id

        const newQuestionnaireId = uuid()

        db.collection('Questionnaires')
        .doc()
        .set({
            ID: newQuestionnaireId,
            Timestamp: timestamp,
            Compagny: client,
            CompagnyID: client,
            EvidenceBased: false,
            Position: questionnaires.length + 1,
            Title: title + ' (kopie)'
        })
        .then(() => {

            const fieldID = uuid()

            db.collection('QuestionnaireFields')
            .where('QuestionnaireID', '==', id)
            .get()

            // Duplicate questionnaire fields
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                    const field = doc.data()
                    const originalFieldID = doc.data().ID
                    
                    db.collection('QuestionnaireFields')
                    .doc()
                    .set({
                        Compagny: client,
                        CompagnyID: client,
                        ID: fieldID,
                        Timestamp: timestamp,
                        QuestionnaireID: newQuestionnaireId,
                        Type: field.Type, 
                        Question: field.Question,
                        Explainer: field.Explainer,
                        ReachStart: field.ReachStart,
                        ReachStartLable: field.ReachStartLable,
                        ReachEnd: field.ReachEnd,
                        ReachEndLabel: field.ReachEndLabel,
                        Key: uuid(),
                        Position: field.Position,
                        Multiple: field.Multiple,
                        SectionID: field.SectionID,
                        Color: TertairyColor,
                        Title: field.Title ? field.Title : '',
                    })
                    .then(() => {

                        console.log(originalFieldID)
                        // Duplicate questionnaire field options
                        db.collection('MultipleQuestionOptions')
                        .where('Field', '==', originalFieldID)
                        .get()

                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {

                                const option = doc.data()

                                db.collection('MultipleQuestionOptions')
                                .doc()
                                .set({
                                    Option: option.Option,
                                    Question: field.Question,
                                    Field: fieldID,
                                    Compagny: client,
                                    CompagnyID: client,
                                    Timestamp: timestamp,
                                    Position: option.Position ? option.Position : 0,
                                    ID: uuid(),
                                    Color: TertairyColor,
                                })
                            })
                        })
                    })
                    .then(() => {
                        // Duplicate matrix rows 
                        db.collection('MatrixQuestionRows')
                        .where('FieldID', '==', field.ID)
                        .get()

                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {

                                const row = doc.data()

                                db.collection('MatrixQuestionRows')
                                .doc()
                                .set({
                                    Title: row.Title,
                                    Question: field.Question,
                                    Field: fieldID,
                                    Compagny: client,
                                    CompagnyID: client,
                                    Timestamp: timestamp,
                                    Position: row.Position,
                                    ID: uuid(),
                                    Color: TertairyColor,
                                })
                            })
                        })
                    })
                    .then(() => {
                        // Duplicate matrix columns
                        db.collection('MatrixQuestionColumns')
                        .where('FieldID', '==', field.ID)
                        .get()

                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {

                                const column = doc.data()

                                db.collection('MatrixQuestionColumns')
                                .doc()
                                .set({
                                    Title: column.Title,
                                    Question: field.Question,
                                    Field: fieldID,
                                    Compagny: client,
                                    CompagnyID: client,
                                    Timestamp: timestamp,
                                    Position: column.Position,
                                    ID: uuid(),
                                    Color: TertairyColor
                                })
                            })
                        })
                    })
                })
            })
        })
    }

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

     // Hightlight impactAI
     const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, ga je gang!')
    }

  return (
    <div className="main">
    <div className="main-container">
        <div className='page-header'>
            <ListAltOutlinedIcon/>
            <h1>Vragenlijsten</h1>
        </div>
        <div className="table-container dashboard-container">
            <div className='list-top-row-container'>
                <Tooltip content={'Vragenlijst toevoegen'} width={'30px'} top='35px'>
                    <img src={plusButton} alt="" onClick={addQuestionnaire}/>
                </Tooltip>
                {/* <Tooltip content={'Vragenlijst uploaden'} width={'30px'} top='-30px' left='30px'>
                    <img src={uploadIcon} alt="" onClick={() => history.push(`/${client}/uploadquestionnaire`)}/>
                </Tooltip> */}
                 <Tooltip content={'Kan ik je helpen?'} width={'30px'} top='-10px' left='30px'>
                    <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                </Tooltip>
            </div>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        <th style={{backgroundColor: secundairyColor}}>VRAGEN</th>
                        <th style={{backgroundColor: secundairyColor}}>INSTELLINGEN</th>
                        <th style={{backgroundColor: secundairyColor}}>KOPIEREN</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                </thead>
                {questionnaires && questionnaires.map((questionnaire, index) => (
                    <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={questionnaire}
                        input={
                            <Tooltip content={questionnaire.Title} width='100%' top='-60px'>
                                <input type="text" placeholder="Schrijf hier de titel" data-docid={questionnaire.docid} defaultValue={questionnaire.Title} onChange={titleHandler}/>
                            </Tooltip>
                        }
                        parentIndex={index}
                        collection={'Questionnaires'}
                        itemArray={questionnaires}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={questionnaire.Title}
                        setPlaceholderIndex={setPlaceholderIndex}
                        >
                            <td onClick={() => history.push(`/${client}/questionairebuilder/${questionnaire.ID}`)}>
                                <FieldCount id={questionnaire.ID}/>
                            </td>
                            <td>
                                <img className='table-delete-icon' onClick={() => history.push(`/${client}/questionairebuilder/${questionnaire.ID}`)} src={settingsIcon} alt="" />
                            </td>
                            <td>
                                <img 
                                className='table-delete-icon' 
                                onClick={duplicateQuestionaire} 
                                src={copyIcon} 
                                alt="" 
                                data-title={questionnaire.Title}
                                data-id={questionnaire.ID}
                                />
                            </td>
                            <td>
                                <img className='table-delete-icon' data-docid={questionnaire.docid} data-deletename={questionnaire.Title} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                            </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
        </div>
        <Breadcrumb
        section={'Meten'}
        previousPage={'Onderzoeken'}
        previousPageUrl={'research'}
        nextPage={'KPI\'s'}
        nextPageUrl={'kpis'}
        counter={2}
        totalSteps={4}
        />
    </div>
    <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteQuestionnaire} deleteName={deleteName} />
</div>
  )
}

export default Questionnaires
